import React, { JSX, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { API_ROUTES } from '../../../../const';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getUserStockSymbol } from '../../../../store/slices/authSlice';
import {
  getFilterWithoutBotsAndRobots,
  getFiltersDataRangeLoadingState,
  getFiltersDatasource,
  getFiltersDateRangeFrom,
  getFiltersDateRangeTo,
  setDatasourceFilters,
  setLoadingState,
  updateRequestedListPage,
} from '../../../../store/slices/filtersSlice';
import { Datasource, IDataSourceResponse } from '../../../../types';
import { fetchWithConfig, getDateSourceIcon } from '../../../../utils';

const useDataSource = () => {
  const filterFrom = useAppSelector(getFiltersDateRangeFrom);
  const filterTo = useAppSelector(getFiltersDateRangeTo);
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filteredDatasource = useAppSelector(getFiltersDatasource);
  const globalLoading = useAppSelector(getFiltersDataRangeLoadingState);
  const withoutBotsAndRobots = useAppSelector(getFilterWithoutBotsAndRobots);

  const onClick = useCallback(
    (dataSource: Datasource) => {
      if (globalLoading) {
        return;
      }
      dispatch(updateRequestedListPage({ count: 1, storeKeyName: 'postFilters' }));
      dispatch(updateRequestedListPage({ count: 1, storeKeyName: 'usersFilters' }));
      dispatch(setDatasourceFilters(dataSource));
    },
    [dispatch, globalLoading]
  );

  const { data, error, isLoading } = useSWR<IDataSourceResponse[]>(
    stockSymbol
      ? [
          `${API_ROUTES.DATA_SOURCE}/${stockSymbol}`,
          { from: filterFrom, to: filterTo, withBotsAndRobots: !withoutBotsAndRobots },
        ]
      : null,
    (options: [string, Record<string, any>]): Promise<IDataSourceResponse[]> => {
      return fetchWithConfig({
        url: options[0],
        params: options[1],
      });
    }
  );

  useEffect(() => {
    dispatch(setLoadingState({ key: 'dateRange', value: isLoading }));
  }, [dispatch, isLoading]);

  const dataSourceConfig: {
    Icon: JSX.Element;
    title: string;
    counter?: number;
    counters?: Record<string, number>;
    dataSource: Datasource;
    onClick: () => void;
    isActive: boolean;
    disabled?: boolean;
  }[] = useMemo(() => {
    return data?.length
      ? data?.map(item => {
          const Icon = getDateSourceIcon(item.dataSource);
          return {
            Icon: <Icon />,
            title: t(`dataSource.${item.dataSource}`),
            counter: item.count,
            counters: item.counters,
            dataSource: item.dataSource,
            onClick: () => {
              onClick(item.dataSource);
            },
            disabled: globalLoading,
            isActive: filteredDatasource.some(itemFiltered => itemFiltered === item.dataSource),
          };
        })
      : Array.from(Array(5)).map((item, index) => {
          const Icon = getDateSourceIcon(index + 1);
          return {
            Icon: <Icon />,
            title: t(`dataSource.${index + 1}`),
            counter: 10,
            dataSource: index + 1,
            onClick: () => {},
            disabled: globalLoading,
            isActive: false,
          };
        });
  }, [data, filteredDatasource, globalLoading, onClick, t]);
  return {
    dataSourceConfig,
    isLoading,
  };
};

export default useDataSource;
