import * as React from 'react';
import { SVGProps } from 'react';

export const PriceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="10"
    height="18"
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.3326 6.08366H9.99927C9.99927 3.71949 7.70343 2.64116 5.8326 2.39283V0.666992H4.16593V2.39283C2.2951 2.64116 -0.000732422 3.71949 -0.000732422 6.08366C-0.000732422 8.33866 2.22093 9.51116 4.16593 9.77533V13.917C2.95927 13.7078 1.66593 13.0637 1.66593 11.917H-0.000732422C-0.000732422 14.0745 2.0201 15.3495 4.16593 15.6137V17.3337H5.8326V15.6087C7.70343 15.3603 9.99927 14.2812 9.99927 11.917C9.99927 9.55282 7.70343 8.47449 5.8326 8.22616V4.08366C6.94093 4.28283 8.3326 4.86783 8.3326 6.08366ZM1.66593 6.08366C1.66593 4.86783 3.0576 4.28283 4.16593 4.08366V8.08283C3.02343 7.87199 1.66593 7.24783 1.66593 6.08366ZM8.3326 11.917C8.3326 13.1328 6.94093 13.7178 5.8326 13.917V9.91699C6.94093 10.1162 8.3326 10.7012 8.3326 11.917Z"
      fill="#475467"
    />
  </svg>
);
