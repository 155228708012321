import { AppButton, ButtonsType, Popup } from 'components';
import { API_ROUTES } from 'const';
import { ITopPostResponse } from 'modules/Conversation/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchWithConfig } from 'utils';

import styles from './styles.module.scss';

interface IDeleteConversationPopupProps {
  onClose: () => void;
  postId: ITopPostResponse['_id'];
  onChangedSentimentOrDeleted: () => void;
}

const DeleteConversationPopup = (props: IDeleteConversationPopupProps) => {
  const { onClose, postId, onChangedSentimentOrDeleted } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    setLoading(true);

    await fetchWithConfig<any>({
      url: `${API_ROUTES.DELETE_CONVERSATION}/${postId}`,
      method: 'DELETE',
    });

    setLoading(false);
    onClose();
    onChangedSentimentOrDeleted();
  };

  return (
    <Popup title={t('posts.delete')} onClose={onClose} size="small">
      <p>Are you sure you want to delete this post?</p>
      <div className={styles.popupFooter}>
        <AppButton
          onClick={onSubmit}
          isLoading={loading}
          btnType={ButtonsType.simpleSave}
          label="Delete"
        />
      </div>
    </Popup>
  );
};

export default DeleteConversationPopup;
