import { Delete as DeleteIcon, Unarchive as UnArchiveIcon } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { ContextMenu } from 'components';
import { API_ROUTES } from 'const';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ToastifyService from 'services/ToastifyService';
import { IReport } from 'types';
import { fetchWithConfig } from 'utils';

import styles from './styles.module.scss';

interface IReportArchivedActionsMenuProps {
  report: IReport;
  refresh: () => void;
}

const ReportArchivedActionsMenu: React.FC<IReportArchivedActionsMenuProps> = ({
  report,
  refresh,
}) => {
  const { _id, name } = report;
  const { t } = useTranslation();

  const onDelete = async () => {
    try {
      const res = await fetchWithConfig({ url: `${API_ROUTES.REPORTS}/${_id}`, method: 'DELETE' });
      ToastifyService.setToast(res.message as string, 'success');
      refresh();
    } catch (error: any) {
      ToastifyService.setToast(error, 'error');
    }
  };

  const onRestore = async () => {
    try {
      const res = await fetchWithConfig({
        url: `${API_ROUTES.REPORTS}/unarchive/${_id}`,
        method: 'POST',
      });
      ToastifyService.setToast(res.message as string, 'success');
      refresh();
    } catch (error: any) {
      ToastifyService.setToast(error, 'error');
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <ContextMenu>
          <MenuItem onClick={onRestore}>
            <ListItemIcon>
              <UnArchiveIcon />
            </ListItemIcon>
            <ListItemText>{t('pages.reports.actions.restore')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={onDelete}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>{t('pages.reports.actions.delete')}</ListItemText>
          </MenuItem>
        </ContextMenu>
      </div>
    </>
  );
};

export default ReportArchivedActionsMenu;
