import axios from 'axios';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUserStockSymbol } from 'store/slices/authSlice';
import useSWR from 'swr';

export interface TickerSentiment {
  ticker: string;
  relevance_score: string;
  ticker_sentiment_score: number;
  ticker_sentiment_label: string;
}

export interface NewsItem {
  id?: string;
  title: string;
  url: string;
  time_published: string;
  authors: string[];
  summary: string;
  source: string;
  ticker_sentiment: TickerSentiment[];
}

interface AlphaVantageResponse {
  items: string;
  sentiment_score_definition: string;
  relevance_score_definition: string;
  feed: NewsItem[];
}

export interface ProcessedNewsItem {
  id: string;
  title: string;
  article_url: string;
  published_utc: string;
  author: string;
  description: string;
  publisher: {
    name: string;
    homepage_url: string;
  };
  insights: Array<{
    ticker: string;
    sentiment: 'Positive' | 'Negative' | 'Neutral';
    sentiment_score: number;
  }>;
}

export interface NewsMetrics {
  sentiment: number;
  source: string;
  timestamp: string;
  count: number;
}

const processNewsItem = (item: NewsItem, selectedStock: string): ProcessedNewsItem => {
  const tickerSentiment = item.ticker_sentiment.find(ts => ts.ticker === selectedStock);

  // Parse the time_published format from Alpha Vantage (e.g., "20240424T090000")
  const published = DateTime.fromFormat(item.time_published, "yyyyMMdd'T'HHmmss");
  const publishedUtc = published.isValid ? published.toUTC().toISO() : DateTime.now().toISO();

  // Normalize sentiment score based on the label
  const normalizeScore = (score: number, label: string): number => {
    switch (label) {
      case 'Bullish':
        return 0.35;
      case 'Somewhat-Bullish':
        return 0.25;
      case 'Neutral':
        return 0;
      case 'Somewhat-Bearish':
        return -0.25;
      case 'Bearish':
        return -0.35;
      default:
        return 0;
    }
  };

  return {
    id: item.url || `${item.time_published}-${item.title}`,
    title: item.title,
    article_url: item.url,
    published_utc: publishedUtc,
    author: item.authors.join(', '),
    description: item.summary,
    publisher: {
      name: item.source,
      homepage_url: '',
    },
    insights: tickerSentiment
      ? [
          {
            ticker: tickerSentiment.ticker,
            sentiment: tickerSentiment.ticker_sentiment_label as
              | 'Positive'
              | 'Negative'
              | 'Neutral',
            sentiment_score: normalizeScore(
              Number(tickerSentiment.ticker_sentiment_score),
              tickerSentiment.ticker_sentiment_label
            ),
          },
        ]
      : [],
  };
};

export const useStockNews = () => {
  const selectedStock = useSelector(getUserStockSymbol);
  const apiKey = '3UQ7PF0XS7PQC4D7'; // YOLO

  const { data, error, isLoading } = useSWR<AlphaVantageResponse>(
    selectedStock
      ? `https://www.alphavantage.co/query?function=NEWS_SENTIMENT&tickers=${selectedStock}&limit=1000&sort=LATEST&apikey=${apiKey}`
      : null,
    async (url: string) => {
      try {
        const response = await axios.get(url);
        console.log('Alpha Vantage News API Response:', response.data);
        return response.data;
      } catch (err) {
        console.error('Alpha Vantage News API Error:', err);
        throw err;
      }
    }
  );

  const processedData = useMemo(() => {
    if (!data?.feed || !selectedStock) return [];

    const processed = data.feed
      .filter(item => item.ticker_sentiment.some(ts => ts.ticker === selectedStock))
      .map(item => {
        const tickerSentiment = item.ticker_sentiment.find(ts => ts.ticker === selectedStock);
        console.log('Raw ticker sentiment:', {
          ticker: tickerSentiment?.ticker,
          score: tickerSentiment?.ticker_sentiment_score,
          label: tickerSentiment?.ticker_sentiment_label,
          date: item.time_published,
        });
        return processNewsItem(item, selectedStock);
      });

    return processed;
  }, [data?.feed, selectedStock]);

  // Group and average sentiments by day
  const newsMetrics: NewsMetrics[] = useMemo(() => {
    if (!processedData.length || !selectedStock) return [];

    // Create a map to group sentiments by day
    const dailyMetrics = processedData.reduce(
      (acc: { [key: string]: { scores: number[]; count: number } }, news) => {
        // Get the sentiment score for the selected stock
        const stockInsight = news.insights.find(insight => insight.ticker === selectedStock);
        if (!stockInsight) return acc;

        // Get the date part only for grouping (YYYY-MM-DD)
        const date = DateTime.fromISO(news.published_utc).toFormat('yyyy-MM-dd');

        if (!acc[date]) {
          acc[date] = {
            scores: [],
            count: 0,
          };
        }

        // Store individual scores for debugging
        acc[date].scores.push(stockInsight.sentiment_score);
        acc[date].count += 1;

        return acc;
      },
      {}
    );

    // Convert the grouped data to array format and calculate averages
    const metrics = Object.entries(dailyMetrics)
      .map(([date, metrics]) => {
        const sum = metrics.scores.reduce((a, b) => a + b, 0);
        const avgSentiment = sum / metrics.count;

        console.log('Daily sentiment calculation:', {
          date,
          individualScores: metrics.scores,
          count: metrics.count,
          sum,
          average: avgSentiment,
        });

        return {
          timestamp: date,
          sentiment: avgSentiment,
          source: '',
          count: metrics.count,
        };
      })
      .sort(
        (a, b) =>
          DateTime.fromISO(a.timestamp).toMillis() - DateTime.fromISO(b.timestamp).toMillis()
      );

    return metrics;
  }, [processedData, selectedStock]);

  // Calculate source distribution separately
  const sourceDistribution = useMemo(() => {
    if (!processedData.length) return [];

    const sourceCounts = processedData.reduce((acc: { [key: string]: number }, news) => {
      const source = news.publisher.name;
      acc[source] = (acc[source] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(sourceCounts).map(([source, count]) => ({
      source,
      count,
    }));
  }, [processedData]);

  return {
    newsData: processedData,
    newsMetrics,
    sourceDistribution,
    error,
    isLoading,
  };
};
