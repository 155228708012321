import React from 'react';

import styles from './styles.module.scss';

interface IBulletListItemProps {
  text: string;
}

export const BulletListItem: React.FC<IBulletListItemProps> = ({ text }) => {
  return (
    <div className={styles.bulletText}>
      <svg
        className={styles.bulletIcon}
        width="8"
        height="10"
        viewBox="0 0 8 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 4.13399C8.16667 4.51889 8.16667 5.48114 7.5 5.86604L1.5 9.33014C0.833333 9.71504 -3.3649e-08 9.23392 0 8.46412L3.02842e-07 1.53592C3.36491e-07 0.766115 0.833334 0.28499 1.5 0.66989L7.5 4.13399Z"
          fill="#53B1FD"
        />
      </svg>
      {text}
    </div>
  );
};

export default BulletListItem;
