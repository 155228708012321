import { Skeleton } from '@mui/material';
import React, { useCallback } from 'react';
import { ExecutiveSummaryDaysFilter } from 'types';

import { SectionCard, Title, TitleType } from '../../../components';
import DefaultContent from '../../../components/SentimentalsBlocks/DefaultContent';
import SentimentalContent from '../../../components/SentimentalsBlocks/SentimentalContent';
import { SummaryAnalyze, SummarySentimentAnalyze, SummaryType } from '../types';
import styles from './styles.module.scss';

type CardProps = {
  data: SummaryAnalyze | SummarySentimentAnalyze | null;
  title: string;
  type: SummaryType;
  Icon: React.FC;
  loading: boolean;
  cardKey?: string;
  filterDay?: ExecutiveSummaryDaysFilter;
  ExtraComponent?: React.ReactNode;
  infoIcon?: React.ReactNode;
};

const Card: React.FC<CardProps> = ({
  Icon,
  loading,
  data,
  type,
  title,
  cardKey,
  filterDay,
  ExtraComponent,
  infoIcon,
}) => {
  const Content = useCallback(() => {
    return type === SummaryType.simple ? (
      <DefaultContent
        data={data as SummaryAnalyze}
        loading={loading}
        cardKey={cardKey}
        filterDay={filterDay}
      />
    ) : (
      <SentimentalContent
        data={data as SummarySentimentAnalyze}
        loading={loading}
        filterDay={filterDay}
      />
    );
  }, [data, loading, type]);
  return (
    <div className={styles.wrapper}>
      <SectionCard nativeClassName={styles.card}>
        <>
          <div className={styles.titleWrapper}>
            <div className={styles.titleIcon}>
              <Icon />
            </div>
            <Title type={TitleType.h3}>{loading ? <Skeleton width="60%" /> : <>{title}</>}</Title>
            {infoIcon && infoIcon}
          </div>
          <Content />
          {ExtraComponent && ExtraComponent}
        </>
      </SectionCard>
    </div>
  );
};

export default Card;
