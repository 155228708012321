export const enTranslation = {
  auth: {
    signIn: 'Sign in',
    login: 'Log in',
    confirmBtn: 'Confirm',
    loginSubtitle: 'Welcome back! login with your credentials',
    emailPlaceholder: 'name@example.com',
    pathPlaceholder: 'Password',
    emailLabel: 'Email',
    passLabel: 'Password',
    forgotPass: 'Forgot password',
    forgotPassLabel: 'Enter your email for reset password',
    forgotPassConfirm: 'Enter a new password',
    forgotPassCreate: 'Reset password',
    forgotPassRequest: 'Request reset link',
    remember: 'Remember me',
    backToSignIn: 'Back to Sign In',
    confirmPass: 'Confirm password',
    savePassword: 'Save password',
  },
  agencySummary: {
    dataTypes: {
      priceUp: 'Stock Price Up',
      nopriceUp: 'None of your stocks price changed',
      priceDown: 'Stock Price Down',
      nopriceDown: 'None of your stocks price changed',
      mentions: 'Mentions',
      nomentions: 'None of your stocks mentions changed',
      alerts: 'Alerts',
      noalerts: 'No new alerts',
      positive: 'Positive Sentiment',
      nopositive: 'None of your stocks sentiment changed',
      negative: 'Negative Sentiment',
      nonegative: 'None of your stocks sentiment changed',
    },
  },
  mainNavigation: {
    home: 'Dashboard',
    agencyOverview: 'FinINT Matrix',
    ceoMatrix: 'CEO Matrix',
    newsRoom: 'News Room',
    competitive: 'Competitive Analysis',
    responder: 'Responder',
    recommendedActions: 'Recommended Actions',
    reports: 'Reports',
  },
  errors: {
    notValidEmail: 'Email address must be a valid address',
    requiredField: 'This field is required',
    passwordIsnNotMatchField: 'Passwords do not match',
    newPassError: 'Password is not same',
    unkown: 'Something went wrong',
    noData: 'No data',
    noActiveStock: 'Select alert to see insights',
  },
  userMenu: {
    resellerSettings: 'Settings',
    viewProfile: 'View profile',
    logout: 'Log out',
    dashboard: 'Admin',
  },
  topics: {
    empty: 'Not enough conversations to generate topics',
    chatterHeading: 'Trending Chatter Topics',
    mainSubtitle: 'Showing last 24h',
    positiveTopics: 'Key Positive Topics',
    negativeTopics: 'Key Negative Topics',
    positiveTopicsEmpty: 'We can not produce a summary right now due to low chatter',
    negativeTopicsEmpty: 'We can not produce a summary right now due to low chatter',
  },
  executiveSummary: {
    mainTitle: 'Executive Summary',
    subtitleLastDay: 'Showing last 24h',
    subtitleLastWeek: 'Showing current week',
    vsLastDay: 'vs yesterday',
    vsLastWeek: 'vs last week',
    positive: 'Positive',
    negative: 'Negative',
    analyzeLastDay: 'Sentiment change in the past 24h',
    analyzeLastWeek: 'Sentiment change in the past week',
    mentions: 'Total Mentions',
    sentiment: 'Sentiment',
    bots: 'Bot Users',
    alerts: 'New Alerts',
    botsInfoTooltipText:
      'The percentage of users identified as bots among all users discussing the stock',
  },
  alerts: {
    all: 'All',
    message: 'Message',
    inbox: 'Inbox',
    archived: 'Archived',
    viewSource: 'View Source',
    pageTitle: 'Alerts',
    actions: {
      archive: 'Archive',
      unarchive: 'Unarchive',
      delete: 'Delete',
      archiveAll: 'Archive all',
      unarchiveAll: 'Unarchive all',
      deleteAll: 'Delete all',
    },
    labels: {
      unread: 'Unread',
    },
    types: {
      1: 'Volume Increase',
      6: 'Influencer',
      12: 'Hater Identified',
      14: 'Analyst Insights',
      15: 'Negative Increase',
    },
  },
  charts: {
    chartToMarket: 'Chat To Market Trend',
    chartToMarketCreateSummary: 'Do you want to generate a chatter report for',
    sentimentGraphs: 'Sentiment Graphs',
    sentimnetGraphInfoTooltipText:
      'This graph shows the number of positive and negative posts each day along with the stock price',
    labels: {
      all: 'All',
      volume: 'Volume',
      price: 'Stock Price',
      chatter: 'Chatter',
      post: 'Posts',
      price2: 'Price',
      index: 'Index',
      positive: 'Positive',
      negative: 'Negative',
      summary: 'Summary',
      withBotsMentions: 'Total Mentions (With Bots)',
      noBotsMentions: 'Total Mentions (Without Bots)',
    },
    withoutBotsAndRobots: 'Without Bots',
    withBotsAndRobots: 'With Bots',
    errors: {
      dataRangeError: 'You need to select dates',
    },
  },
  dataSource: {
    1: 'X (Twitter)',
    2: 'Stocktwits',
    3: 'Yahoo Finance',
    4: 'Reddit',
    6: 'YouTube',
  },
  buttons: {
    apply: 'Apply',
    cancel: 'Cancel',
    loading: 'Loading more...',
    delete: 'Delete',
    update: 'Update',
    viewPost: 'View post',
    viewVideo: 'View video',
    moveToResponder: 'Move to Responder',
    save: 'Save',
    send: 'Send',
    readMore: 'Read more',
    showLess: 'Show less',
  },
  posts: {
    pageTitle: 'Top Posts',
    positive: 'Positive',
    negative: 'Negative',
    neutral: 'Neutral',
    followers: 'Followers',
    changeSentiment: 'Change Sentiment',
    delete: 'Delete',
  },
  users: {
    pageTitle: 'Top Users',
    followers: 'By Followers',
    yfFollowers: 'Yahoo Finance accounts do not have followers',
    redditFollowers: 'Reddit accounts do not have followers',
    impression: 'By Virality Potential',
    suspectBot: 'Suspected as bot',
    topUsersInfoTooltipText: 'Click on a user to view their published posts',
  },
  range: {
    day: 'in last 24 hours',
    twoDays: 'in last 48 hours',
    threeDays: 'in last 72 hours',
    week: 'in last week',
    month: 'in last month',
    range: 'in period from {{from}} to {{to}}',
  },
  pages: {
    pageTitle: 'Settings',
    details: 'My Details',
    password: 'Password',
    team: 'Team',
    withOutStock: {
      welcomeText: 'Welcome to Koios',
      title: 'Sorry, your dashboard is in preparation.',
      subTitle: 'We will notify you by mail when everything is ready',
    },
    homepage: {
      uiOrder: {
        title: 'Change your UI order',
        uiKeys: {
          summary: 'Executive Summary',
          alerts: 'Alerts',
          topics: 'Trending Chatter Topics',
          charts: 'Charts',
          market: 'Chat To Market Trend',
          sentiment: 'Sentiment Graphs',
          top: 'Top Users & Posts',
        },
      },
    },
    forgotPass: {
      label: 'A link to reset your password will be sent to your email address.',
    },
    profile: {
      profile: 'Profile',
      plans: 'Plans',
      password: 'Password',
      profileSubtitle: 'Manage your team members and their account permissions here.',
      team: 'Team',
    },
    tabs: {
      profile: 'Profile',
      password: 'Password',
      passwordSubtitle: 'Change your password',
      oldPasswordSubtitle: 'Old password',
      newPasswordSubtitle: 'New password',
      labels: {
        passLabel: 'Password',
        newPassLabel: 'New Password',
        userEmail: 'User email',
        timeZone: 'Time Zone',
        userEmailPopover: 'This email address is used for authorization',
        website: 'Website',
        availableStock: 'Available stocks:',
      },
    },
    reseller: {
      stockKits: {
        title: 'Stocks',
        create: 'Add Stock',
      },
    },
    admin: {
      availableStock: 'Available stock:',
      availableTeamPages: 'Available Pages',
      homeTitle: 'Home',
      dashboartTitle: 'Dashboard',
      stocks: 'Stocks',
      stockEdit: 'Edit stock',
      stockCreate: 'Create stock',
      stockKitCreate: 'Create Stock Kit',
      stockKitEdit: 'Stock Kit edit',
      stockKits: 'Stock kits',
      teams: 'Teams',
      teamEdit: 'Edit Team',
      teamCreate: 'Create Team',
      teamDetails: 'Team details',
      teamRole: 'Chose team role',
      teamSelect: 'Select team',
      users: 'Users',
      usersEdit: 'Edit Users',
      usersInTeam: 'Users in team:',
      usersRole: 'Users role: ',
      usersEmail: 'Users email: ',
      dateCreate: 'Date create: ',
      dateUpdate: 'Date update: ',
      stockPlaceholder: 'Select stock',
      stockKitsPlaceholder: 'Select stock kits',
      validateStock: 'Select stock',
      usersPlaceholder: 'Select users',
      ownerPlaceholder: 'Select owner',
      usersCreate: 'Create users',
      stockSymbol: 'Symbol: ',
      insights: 'Insights',
      stockKitsLimit: 'Stock kits limit',
      usersBlackList: {
        title: 'Users Black List',
        searchPlaceholder: 'Select users',
        isBot: 'Is Bot',
        isRobot: 'Is Robot',
      },
      userMailingList: {
        title: 'Mailing list',
        alerts: 'Alerts',
      },
      analystAlert: {
        title: 'Analyst Report',
        createTitle: 'Create Analyst Report',
        searchStockPlaceholder: 'Search and select stock',
      },
    },
    competitiveAnalysis: {
      configurePopup: {
        title: 'You can compare with your competitors',
        stockCompareTitle: 'Before we begin, select the Stocks you want to compare with',
        competitorsHint: 'You can select up to 3 competitors symbols to compare to {{stock}}',
        competitorsHintEmpty: 'You can select up to 3 competitors symbols to compare to',
        contactAdminTitle:
          'To utilize the competitors comparison tool please contact you account manager',
        warningMessage:
          'Please note that once you select stocks, you will not be able to change them. Please choose carefully',
        searchPlaceholder: 'Select competitive stocks',
        stocksError: 'You need select minimum 1 stock',
      },
      sentimentsTitle: 'Sentiment Bar',
      mentionsTitle: 'Total Mentions',
      botsTitle: 'Bots Percentage',
      topicsTitle: 'Key Topics',
      yourStock: 'Your stock',
    },
    responder: {
      title: 'Responder',
      tabs: {
        all: 'All',
        starred: 'Starred',
        archived: 'Archived',
        replied: 'Responded',
      },
      actions: {
        viewPost: 'View post',
        reply: 'Respond',
        replyStatus: {
          Waiting: 'Waiting',
          InProgress: 'In Progress',
          Success: 'Responded',
          Failed: 'Failed',
        },
        replied: 'Responded',
        replyProgress: 'In progress',
      },
      answer: 'Your answer',
      replyTitle: 'Respond to post',
      replyPlaceholder: 'Write your answer',
    },
    reports: {
      title: 'Reports',
      create: 'Create Report',
      reportTypes: {
        periodic: 'Periodic Report',
        comparison: 'Time Comparison Report',
        'bot-activity': 'Bot Activity Report',
        manual: 'Manual Report',
      },
      tabs: {
        reports: 'Reports',
        scheduled: 'Scheduled Reports',
        archived: 'Archived Reports',
      },
      actions: {
        download: 'Download',
        send: 'Send',
        restore: 'Restore',
        archive: 'Archive',
        delete: 'Delete',
      },
      modal: {
        title: 'Create Report for {{stock}}',
        titleConfig: 'Create Config for {{stock}}',
        editTitle: 'Edit Report',
        editConfig: 'Edit Config',
        sendReport: 'Send Report',
        create: 'Create',
        update: 'Update',
        cancel: 'Cancel',
        next: 'Next',
        back: 'Back',
        send: 'Send',
        steps: {
          info: 'Report Info',
          mailing: 'Mailing list',
        },
        addMailList: '+ Add email list',
        schedule: {
          daily: 'Daily',
          weekly: 'Weekly',
          monthly: 'Monthly',
          days: {
            1: 'Monday',
            2: 'Tuesday',
            3: 'Wednesday',
            4: 'Thursday',
            5: 'Friday',
            6: 'Saturday',
            7: 'Sunday',
          },
        },
        fields: {
          teamSelect: 'Select Team',
          uploadPdf: 'Upload Report',
          type: 'Report Type',
          reportName: 'Report Name',
          mailingListInput: 'Enter email',
          stock: 'Stock Name',
          timeFrame: 'Time Frame',
          firstPeriod: 'Latest Period',
          secondPeriod: 'Earlier Period',
          scheduled: 'Scheduled Report',
          scheduleType: 'Schedule type',
          hour: 'Hour',
          minPriceChangePercentage: 'Min Price Change %',
          minPriceChangeTooltip:
            'The minimum day-over-day percentage change in stock price required for event summary',
          maxEvents: 'Max Events',
          maxEventsTooltip:
            'Maximum number of day-over-day event summaries for the selected timeframe',
          minutes: 'Minutes',
          weekDay: 'Day of week',
          monthDay: 'Day of month',
        },
      },
    },
    recommendedActions: {
      title: 'Recommended Actions',
      itemTypes: {
        badAccount: 'Bad account',
        collectDocumentation: 'Collect documentation',
        makePr: 'Make a PR',
      },
      makePr: {
        showLast: 'Show last PR',
      },
      badAccount: {
        bottomLabel: 'Negative Campaign Identified',
        reportButton: 'Report',
        viewPostButton: 'View post',
      },
      collectDocumentation: {
        bottomLabel: 'You can ask for a deeper investigation',
        contact: 'Contact Us',
        viewButton: 'View',
      },
    },
  },
  labels: {
    switchStock: 'Select stock to preview',
    searchTimeZone: 'Search Timezone...',
    searchStock: 'Search stock...',
  },
  plans: {
    perMonthPeriod: 'per month',
    currentPlanBtnText: 'Your current plan',
    contactUs: 'Contact us',
    downgrade: 'Downgrade your plan',
    subscribe: 'Subscribe',
  },
};
