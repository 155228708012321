import classNames from 'classnames';
import { PrPoints, SectionCard } from 'components';
import { API_ROUTES } from 'const';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSelectedTimeZone, getUserStockSymbol } from 'store/slices/authSlice';
import {
  getFilterDateRangeType,
  getFilterWithoutBotsAndRobots,
  getFiltersDatasource,
  getFiltersDateRangeFrom,
  getFiltersDateRangeTo,
  setLoadingState,
} from 'store/slices/filtersSlice';
import useSWR from 'swr';
import { customArraySort, fetchWithConfig, isSameDateIgnoringTime } from 'utils';

import ChartSpinner from '../ChartSpinner';
import MixedCharts from '../CommonCharts/MIxedCharts';
import Heading from '../Heading';
import CreateSummaryPopup from '../components/Summary/CreateSummaryPopup';
import SummaryItem from '../components/Summary/SummaryItem';
import useSummaries from '../hooks/useSummaries';
import { ChartSeriesName, IChatMarketData, ILinearChartSeriesItem, IPrPoint } from '../types';
import {
  getFormattedSeries,
  getMaxAndMinFromSeries,
  getXaxisLabels,
  getXaxisTooltipLabel,
  preloaderLinearChartData,
  renderChatMarketTooltip,
} from '../utils';
import Legends from './Legends';
import styles from './styles.module.scss';

const ChartToMarket = () => {
  const filterFrom = useAppSelector(getFiltersDateRangeFrom);
  const filterTo = useAppSelector(getFiltersDateRangeTo);
  const dataSource = useAppSelector(getFiltersDatasource);
  const filtersRangeType = useAppSelector(getFilterDateRangeType);
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const selectedTimeZone = useAppSelector(getSelectedTimeZone);
  const withoutBotsAndRobots = useAppSelector(getFilterWithoutBotsAndRobots);
  const dispatch = useAppDispatch();
  const datesRange = { fromDate: filterFrom, toDate: filterTo };
  const [selectedLegend, setSelectedLegend] = useState<string>('');
  const [prPoints, setPrPoints] = useState<IPrPoint[]>([]);
  const { data, isLoading } = useSWR<any>(
    stockSymbol
      ? [
          `${API_ROUTES.CHART_TO_MARKET}/${stockSymbol}`,
          { from: filterFrom, to: filterTo, dataSource, withBotsAndRobots: !withoutBotsAndRobots },
        ]
      : null,
    (options: [string, Record<string, any>]) => {
      return fetchWithConfig({
        url: options[0],
        params: options[1],
      });
    }
  );

  const renderData: IChatMarketData = useMemo(() => {
    return isLoading
      ? preloaderLinearChartData
      : data || { series: [], dates: [], prLinks: [], interval: '', summaries: [] };
  }, [data, isLoading]);

  const interval = renderData?.interval;
  const categories = renderData?.dates?.map(item => item) || [];
  const prData = renderData?.prLinks || [];

  const {
    onMarkerClick,
    onCloseSummaryPopup,
    summaries,
    summariesLoading,
    onGenerateSummary,
    summaryPrevData,
    summaryActiveData,
    summaryPopupData,
    isGeneratingSummary,
    clearSummaryData,
  } = useSummaries({
    queryFilters: { from: filterFrom, to: filterTo, stockSymbol, dataSource, filtersRangeType },
    interval: interval,
    categoryDates: renderData.dates,
    seriesClickConfig: {
      1: 'Chatter',
    },
  });

  const chatterLoading = isLoading || summariesLoading;
  const { show: showSummaryPopup, data: summaryCreateData } = summaryPopupData;

  const summariesMarkers = summaries
    .map(summary => {
      const dateIndex = categories.findIndex(date => {
        return isSameDateIgnoringTime(summary.dayDate, date);
      });
      if (dateIndex < 0) {
        return null;
      }

      return {
        seriesIndex: 1,
        dataPointIndex: dateIndex,
        fillColor: '#2b3b5b',
        strokeColor: '#fff',
        size: 7,
        shape: 'circle',
      };
    })
    .filter(Boolean);

  useEffect(() => {
    dispatch(setLoadingState({ key: 'dateRange', value: chatterLoading }));
  }, [dispatch, chatterLoading]);

  const { t } = useTranslation();
  const { min: minPrice, max: maxPrice } = getMaxAndMinFromSeries(renderData.series, 'Price');
  const { max: maxChatter } = getMaxAndMinFromSeries(renderData.series, 'Chatter');

  const sortedArray = customArraySort(renderData?.series, ChartSeriesName.price);
  const newSeries = getFormattedSeries(sortedArray);
  const newDataLabels = renderData?.dates?.map((item: any) => {
    return item;
  });

  const options = useMemo(() => {
    return {
      stroke: {
        width: 2,
        curve: ['smooth', 'smooth', 'smooth'],
        colors: ['#1570EF', '#FF7526', '#98A2B300'],
      },
      colors: ['#1570EF', '#FF7526', '#344054'],
      fill: {
        opacity: newSeries.map(({ name }: ILinearChartSeriesItem) => {
          if (!selectedLegend) {
            return 1;
          }

          if (selectedLegend === name) {
            return 1;
          }

          if (selectedLegend) {
            return 0.2;
          }

          return 1;
        }),
        colors: ['#1570EF', '#FF7526', '#344054'],
      },
      markers: {
        size: 0.000001,
        discrete: !selectedLegend || selectedLegend === 'Chatter' ? summariesMarkers : [],
        hover: {
          size: 10,
        },
      },
      chart: {
        id: 'chat-market-trend',
        animations: {
          enabled: false,
        },
        events: {
          dataPointSelection: onMarkerClick,
          updated() {
            const linesSeriesWrapper: SVGAElement | null = document.querySelector(
              '.chat-market-wrapper .apexcharts-inner.apexcharts-graphical'
            );

            if (!prData || !linesSeriesWrapper) {
              return;
            }

            const linesSeries = [...linesSeriesWrapper.querySelectorAll('.apexcharts-xaxis-tick')];
            const pointWidth = 24;
            const prOverlay = document.querySelector('.chat-market-wrapper .pr-overlay')!;
            const groupedPrPoints = prData.reduce(
              (acc, pr) => {
                const { dayDate } = pr;

                const dateIndex = categories?.findIndex(dateVal =>
                  isSameDateIgnoringTime(dateVal, dayDate)
                );
                if (dateIndex < 0) {
                  return acc;
                }

                const htmlItem = linesSeries[dateIndex];
                if (!htmlItem) {
                  return acc;
                }

                const graphicalWrapper: SVGAElement | null = document.querySelector(
                  '.chat-market-wrapper .apexcharts-inner.apexcharts-graphical'
                );
                if (prOverlay && graphicalWrapper) {
                  prOverlay.setAttribute('style', `left: ${graphicalWrapper?.getCTM()?.e}px`);
                }

                const firstLine = linesSeriesWrapper.querySelector('.apexcharts-xcrosshairs');
                const heightLine = firstLine?.getAttribute('height') || 0;
                const xLinePosition = parseFloat(htmlItem.getAttribute('x1') || '0');

                const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
                line.setAttribute('x', xLinePosition.toString());
                line.setAttribute('x1', xLinePosition.toString());
                line.setAttribute('x2', xLinePosition.toString());
                line.setAttribute('y1', '0');
                line.setAttribute('y', '0');
                line.setAttribute('y2', heightLine.toString());
                line.setAttribute('width', '1');
                line.setAttribute('height', heightLine.toString());
                line.setAttribute('stroke', '#b6b6b6');
                line.setAttribute('stroke-opacity', '0.4');
                line.setAttribute('stroke-dasharray', '3');
                line.setAttribute('class', 'apexcharts-xcrosshairs apexchart-summary-crosshair');
                line.setAttribute('fill', '#b1b9c4');

                document
                  .querySelector('.chat-market-wrapper .apexcharts-inner.apexcharts-graphical')
                  ?.appendChild(line);

                const x = xLinePosition - pointWidth / 2;

                if (!acc[dayDate]) {
                  acc[dayDate] = {
                    ...pr,
                    x,
                    additionalData: [],
                  };
                } else {
                  acc[dayDate].additionalData.push(pr);
                }

                return acc;
              },
              {} as Record<string, any>
            );

            const newPrPoints = Object.values(groupedPrPoints);

            if (JSON.stringify(prPoints) !== JSON.stringify(newPrPoints)) {
              setPrPoints(newPrPoints as IPrPoint[]);
            }
          },
        },
      },
      xaxis: {
        labels: {
          categories,
          type: 'datetime',
          formatter: function (value: any) {
            return getXaxisLabels(value, datesRange, selectedTimeZone);
          },
          rotate: 45,
          rotateAlways: true,
          offsetY: 30,
          offsetX: 10,
          style: {
            colors: renderData?.dates?.map((item: any) => {
              if (summaryActiveData?.dayDate === item) {
                return '#fff';
              }
              return '#98A2B3';
            }),
          },
        },
        tooltip: {
          formatter: function (value: any, data: any) {
            return getXaxisTooltipLabel(value, data, selectedTimeZone, datesRange);
          },
        },
      },
      yaxis: [
        {
          tooltip: {
            enabled: true,
          },
          title: {
            text: t('charts.labels.price'),
            style: {
              color: '#1570EF',
            },
          },
          max: maxPrice,
          min: minPrice,
          labels: {
            formatter: function (value: any) {
              return value?.toFixed(2);
            },
          },
        },
        {
          tooltip: {
            enabled: true,
          },
          opposite: true,
          title: {
            text: t('charts.labels.chatter'),
            style: {
              color: '#FF7526',
            },
          },
          max: maxChatter,
          min: 0,
          labels: {
            formatter: function (value: any) {
              return value?.toFixed(0);
            },
          },
        },
      ],
      legend: {
        show: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
          return renderChatMarketTooltip(
            { series, seriesIndex, dataPointIndex, w, interval },
            styles,
            selectedTimeZone
          );
        },
      },
    };
  }, [categories, filtersRangeType, maxPrice, minPrice, selectedTimeZone, selectedLegend, t]);

  return (
    <div className={styles.componentWrapper}>
      <SectionCard>
        <>
          <div className={styles.heading}>
            <div className={styles.headingText}>
              <Heading title={t('charts.chartToMarket')} />
              {interval === '1d' && (
                <div className={styles.clickSectionMessage}>
                  Click on a section of the graph to generate a report detailing what users were
                  discussing during that time
                </div>
              )}
            </div>
            <Legends activeLegend={selectedLegend} onChangeLegend={setSelectedLegend} />
          </div>
          <div className={styles.wrapper}>
            <div
              className={classNames('chat-market-wrapper', styles.chartWrapper, {
                [styles.chatterWithClick]: interval === '1d',
              })}
            >
              <MixedCharts
                series={newSeries}
                labels={newDataLabels}
                /*@ts-ignore*/
                options={options}
                height={460}
              />
              <PrPoints points={prPoints} customClassName="pr-overlay" />
            </div>
            {(summaryActiveData || isGeneratingSummary) && (
              <SummaryItem
                isLoading={isGeneratingSummary}
                data={summaryActiveData}
                prevData={summaryPrevData}
                onClose={clearSummaryData}
              />
            )}
          </div>
          {chatterLoading && <ChartSpinner />}
        </>
      </SectionCard>
      {showSummaryPopup && (
        <CreateSummaryPopup
          onClose={onCloseSummaryPopup}
          data={summaryCreateData!}
          onSubmit={onGenerateSummary}
        />
      )}
    </div>
  );
};

export default React.memo(ChartToMarket);
