import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import tzData from 'tzdata';

const useTimeZonesOptions = () => {
  const [zones, setZones] = useState<{ _id: string; name: string }[]>([]);

  useEffect(() => {
    const luxonValidTimezones = Object.entries(tzData.zones)
      .filter(([zoneName, v]) => Array.isArray(v))
      .map(([zoneName, v]) => zoneName)
      .filter(tz => DateTime.local().setZone(tz).isValid)
      .map(zone => ({ _id: zone, name: zone }));

    const sortedList = [...luxonValidTimezones].sort((a, b) => (a.name > b.name ? 1 : -1));

    setZones(sortedList);
  }, []);

  return zones;
};

export default useTimeZonesOptions;
