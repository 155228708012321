import { Fab, useScrollTrigger, Zoom } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React from 'react';

import styles from './styles.module.scss';

export const BackToTop: React.FC = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Zoom in={trigger}>
      <Fab
        onClick={handleClick}
        className={styles.backToTop}
        size="medium"
        aria-label="scroll back to top"
        sx={{
          bgcolor: '#1470ef',
          '&:hover': {
            bgcolor: '#1470ef',
            filter: 'brightness(0.9)',
          },
        }}
      >
        <KeyboardArrowUpIcon sx={{ color: '#ffffff' }} />
      </Fab>
    </Zoom>
  );
};

export default BackToTop;
