import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Checkbox } from '@mui/material';
import { AppButton, ButtonsType, Popup } from 'components';
import { API_ROUTES } from 'const';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useRef, useState } from 'react';
import DraggableList from 'react-draggable-list';
import { useTranslation } from 'react-i18next';
import { getUiConfig, setUiConfig } from 'store/slices/authSlice';
import { IUiConfig, TUiConfigItem } from 'types';
import { fetchWithConfig, updateOrderByIndexes } from 'utils';

import styles from './styles.module.scss';

const ChartsItem = ({ item, dragHandleProps, commonProps }: any) => {
  const { onMouseDown, onTouchStart } = dragHandleProps;
  const { onUpdateItem } = commonProps;
  const { t } = useTranslation();

  return (
    <div className={styles.orderItem}>
      {t(`pages.homepage.uiOrder.uiKeys.${item.uiKey}`)}
      <DragHandleIcon
        className={styles.dragButton}
        onTouchStart={e => {
          e.preventDefault();
          onTouchStart(e);
        }}
        onMouseDown={e => {
          onMouseDown(e);
        }}
      ></DragHandleIcon>
      <Checkbox
        size="small"
        checked={item.enabled}
        onChange={(_, checked) => onUpdateItem('charts', item.uiKey, { enabled: checked })}
      />
    </div>
  );
};

const GeneralItem = ({ item, dragHandleProps, commonProps = {} }: any) => {
  const { chartsDragConfig, onUpdateItem } = commonProps;
  const { onMouseDown, onTouchStart } = dragHandleProps;
  const withInnerDrag = item.uiKey === 'charts';

  const chartsContainerRef = useRef();
  const { t } = useTranslation();

  return (
    <div className={styles.orderItem}>
      {t(`pages.homepage.uiOrder.uiKeys.${item.uiKey}`)}
      <DragHandleIcon
        className={styles.dragButton}
        onTouchStart={e => {
          e.preventDefault();
          onTouchStart(e);
        }}
        onMouseDown={e => {
          onMouseDown(e);
        }}
      ></DragHandleIcon>
      <Checkbox
        size="small"
        checked={item.enabled}
        onChange={(_, checked) => {
          onUpdateItem('general', item.uiKey, { enabled: checked });
          if (withInnerDrag) {
            onUpdateItem(
              'charts',
              // @ts-ignore
              chartsDragConfig.chartsList.map(({ uiKey }) => uiKey),
              { enabled: checked }
            );
          }
        }}
      />
      {withInnerDrag && (
        <div className={styles.innerList}>
          <DraggableList
            itemKey="uiKey"
            //@ts-ignore
            template={ChartsItem}
            list={chartsDragConfig.chartsList}
            onMoveEnd={newList => chartsDragConfig.onChartsListChange(newList)}
            container={() => chartsContainerRef.current}
            commonProps={{ onUpdateItem }}
          />
        </div>
      )}
    </div>
  );
};

export const UiOrderPopup: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const uiConfig = useAppSelector(getUiConfig);
  const dispatch = useAppDispatch();

  const {
    config: {
      homepage: { general, charts },
    },
  } = uiConfig!;

  const [generalList, setGeneralList] = useState(general);
  const [chartsList, setChartsList] = useState(charts);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const generalContainerRef = useRef();

  const onUpdateItem = (
    listKey: 'general' | 'charts',
    uiKey: TUiConfigItem['uiKey'] | TUiConfigItem['uiKey'][],
    newData: Partial<TUiConfigItem>
  ) => {
    const array = listKey === 'general' ? generalList : chartsList;
    const updateFn = listKey === 'general' ? setGeneralList : setChartsList;

    updateFn(
      array.map(item => {
        if (typeof uiKey === 'string' && item.uiKey !== uiKey) {
          return item;
        }

        if (Array.isArray(uiKey) && !uiKey.includes(item.uiKey)) {
          return item;
        }

        return {
          ...item,
          ...newData,
        };
      })
    );
  };

  const onGeneralListChange = (newData: any) => {
    setGeneralList(updateOrderByIndexes(newData));
  };

  const onChartsListChange = (newData: any) => {
    setChartsList(updateOrderByIndexes(newData));
  };

  const onCloseHandler = () => {
    if (loading) {
      return;
    }

    onClose();
  };

  const onSave = async () => {
    setLoading(true);

    const newUiConfig = {
      ...uiConfig,
      config: {
        ...uiConfig?.config,
        homepage: {
          general: generalList,
          charts: chartsList,
        },
      },
    };

    await fetchWithConfig({ method: 'PATCH', url: API_ROUTES.UI_CONFIG, data: newUiConfig });

    dispatch(setUiConfig(newUiConfig as unknown as IUiConfig));

    setLoading(false);

    onClose();
  };

  return (
    <Popup title={t('pages.homepage.uiOrder.title')} onClose={onCloseHandler}>
      <>
        {/* @ts-ignore */}
        <div ref={generalContainerRef}>
          <DraggableList
            itemKey="uiKey"
            //@ts-ignore
            template={GeneralItem}
            list={generalList}
            onMoveEnd={newList => onGeneralListChange(newList)}
            container={() => generalContainerRef.current}
            commonProps={{ chartsDragConfig: { chartsList, onChartsListChange }, onUpdateItem }}
          />
        </div>
        <div className={styles.submitWrapper}>
          <AppButton
            onClick={onSave}
            isLoading={loading}
            btnType={ButtonsType.simpleSave}
            label={t('buttons.save')}
          />
        </div>
      </>
    </Popup>
  );
};
