import { DateTime } from 'luxon';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { getSelectedTimeZone } from 'store/slices/authSlice';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { setDateRangeFilter } from '../../store/slices/filtersSlice';
import { theme } from '../../theme';
import { FiltersDateRangeType } from '../../types';
import { formatDate, formatDateTime } from '../../utils';
import AppButton, { ButtonsType } from '../AppButton';
import styles from './styles.module.scss';

export const RangePicker: React.FC<{
  isOpen: boolean;
  onBtnClose: () => void;
  initialRange: Date[];
  minDate: Date;
  maxDate?: Date;
  onApply?: (fromDate: string, toDate: string) => void;
}> = ({ isOpen, onBtnClose, initialRange, minDate, onApply, maxDate }) => {
  const dispatch = useAppDispatch();
  const [dateRange, setDateRange] = useState(initialRange);
  const [hasError, setError] = useState(false);
  const [startDate, endDate] = dateRange;
  const { t } = useTranslation();
  const maxDateValue = maxDate || DateTime.now().toJSDate();
  const selectedTimeZone = useAppSelector(getSelectedTimeZone);

  const from = formatDateTime(dateRange[0]);
  const to = formatDateTime(dateRange[1]);
  const onApplyClick = () => {
    const [rangeFrom, rangeTo] = dateRange;

    if (!rangeFrom || !rangeTo) {
      setError(true);
      return;
    }

    setError(false);
    onBtnClose();

    const fromDate = formatDate(
      DateTime.fromJSDate(rangeFrom).startOf('day').toJSDate(),
      selectedTimeZone
    );
    const toDate = formatDate(
      DateTime.fromJSDate(rangeTo).endOf('day').toJSDate(),
      selectedTimeZone
    );

    if (typeof onApply !== 'function') {
      dispatch(
        setDateRangeFilter({
          type: FiltersDateRangeType.range,
          from: fromDate,
          to: toDate,
        })
      );
      return;
    }

    onApply(fromDate, toDate);
  };

  const onCancelClick = () => {
    setError(false);
    onBtnClose();
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.flexWrapper}>
        <DatePicker
          minDate={minDate}
          maxDate={maxDateValue}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update: any) => {
            if (hasError) {
              setError(false);
            }
            setDateRange(update);
          }}
          onBlur={onCancelClick}
          isClearable={true}
          open={isOpen}
          monthsShown={2}
          inline
        >
          <div className={styles.row}>
            <div className={styles.dateValue}>
              {hasError ? (
                <div className={styles.error}>{t('charts.errors.dataRangeError')}</div>
              ) : (
                <>
                  <div className={styles.dateValueInput}>{from}</div>-
                  <div className={styles.dateValueInput}>{to}</div>
                </>
              )}
            </div>
            <div className={styles.btnWrapper}>
              <AppButton
                isLoading={false}
                btnType={ButtonsType.simpleSave}
                label={t('buttons.cancel')}
                disabled={false}
                onClick={onCancelClick}
                customStyles={{ background: theme.palette.grey[700] }}
              />
              <AppButton
                isLoading={false}
                btnType={ButtonsType.simpleSave}
                label={t('buttons.apply')}
                disabled={false}
                onClick={onApplyClick}
              />
            </div>
          </div>
        </DatePicker>
      </div>
    </div>
  );
};

export default RangePicker;
