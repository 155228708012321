import {
  AppButton,
  BlurredSpinner,
  ButtonsType,
  Layout,
  SectionCard,
  Title,
  TitleType,
} from 'components';
import { APP_ROUTES } from 'const';
import { useAppSelector } from 'hooks';
import StockFilterHeader from 'modules/StockFilterHeader';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getUser } from 'store/slices/authSlice';

import CreateReportPopup from './CreateReportPopup';
import ReportsList from './List';
import styles from './styles.module.scss';

const RecommendedActionsPage = () => {
  const user = useAppSelector(getUser);
  const { t } = useTranslation();
  const { _id, team } = user;
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const editSchedulerId = searchParams.get('id');
  const navigate = useNavigate();

  useEffect(() => {
    setOpenCreatePopup(!!editSchedulerId);
  }, [editSchedulerId]);

  const closeSchedulerPopup = () => {
    setOpenCreatePopup(false);
    navigate(APP_ROUTES.REPORTS, { replace: true });
  };

  const toggleCreatePopup = useCallback((value: boolean) => {
    return () => {
      setOpenCreatePopup(value);
    };
  }, []);

  if (!_id) {
    return <BlurredSpinner />;
  }

  return (
    <>
      <Layout>
        <div className={styles.wrapper}>
          <StockFilterHeader />
          <div className={styles.headingWrapper}>
            <Title type={TitleType.h2}>{t('pages.reports.title')}</Title>
            <AppButton
              onClick={toggleCreatePopup(true)}
              btnType={ButtonsType.borderedFilled}
              label={t('pages.reports.create')}
            />
          </div>
          <SectionCard noBorder>
            <>
              <ReportsList teamId={team ? team._id : undefined} />
              {openCreatePopup && (
                <CreateReportPopup onClose={closeSchedulerPopup} configId={editSchedulerId} />
              )}
            </>
          </SectionCard>
        </div>
      </Layout>
    </>
  );
};

export default RecommendedActionsPage;
