class InputFieldsValidator {
  private emailPattern = /^(?:\w+[-.])*\w+@(?:\w+[-.])*\w+\.\w+$/;
  private passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  private errorLocaleKeys = {
    email: 'Email address must be a valid address',
    password:
      'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character. Acceptable special chars: @$!%*#?&',
    required: 'Field is required',
    motMatch: 'Passwords do not match',
  };

  public get passwordPatternValue() {
    return this.passwordPattern;
  }

  public matchEmail(value: string): boolean | string {
    const testedString = value.trim();
    return this.emailPattern.test(testedString) || this.errorLocaleKeys.email;
  }

  public matchPassword(value: string): boolean | string {
    const testedString = value.trim();
    return this.passwordPattern.test(testedString) || this.errorLocaleKeys.password;
  }

  public matchValue(currentValue: string, matchedValue: string): boolean | string {
    if (!currentValue?.trim() || !matchedValue?.trim()) {
      return this.errorLocaleKeys.required;
    }
    return currentValue.trim() === matchedValue.trim() || this.errorLocaleKeys.motMatch;
  }
  public requiredRule(value: string | number) {
    const testedValue = typeof value === 'string' ? value.trim() : value.toString();
    return testedValue.length > 0 || this.errorLocaleKeys.required;
  }
}

export default new InputFieldsValidator();
