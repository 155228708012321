import { Datasource, Sentiment } from '../../types';
import { ITopPostResponse, ITopUserResponse } from './types';

export const emptyImageConfig: Record<string, string> = {
  [Datasource.Reddit]: '/images/reddit-default-avatar.png',
};

export const preloaderTopPostData: ITopPostResponse[] = [
  {
    _id: '1',
    text: "The $NVDA pump is just $AMC for people who look down on the AMC pumpers. It's like a grown-up version of meme-stock mania. (Yes, NVDA is a real company that makes great products... but it's still grossly overvalued) RT @Swordfishv44183 $NVDA is",
    date: '2023-09-01T09:49:59.000Z',
    stock: 'AMC',
    ai: Sentiment.positive,
    likes: 10,
    dataSource: Datasource.Twitter,
    user: {
      displayName: 'Lykeion liekeyon',
      image: '/images/AvatarDefault.png',
      followers: 6975,
    },
    link: '',
  },
  {
    _id: '2',
    text: "The $NVDA pump is just $AMC for people who look down on the AMC pumpers. It's like a grown-up version of meme-stock mania. (Yes, NVDA is a real company that makes great products... but it's still grossly overvalued) RT @Swordfishv44183 $NVDA is",
    date: '2023-09-01T09:48:48.000Z',
    stock: 'AMC',
    ai: Sentiment.positive,
    likes: 10,
    dataSource: Datasource.Twitter,
    user: {
      displayName: ' xfaffy ',
      image: '/images/AvatarDefault.png',
      followers: 1432,
    },
    link: '',
  },
  {
    _id: '3',
    text: 'Guinness record @guinnessrecord RT @StockSenseFrank 48! Still On $AMC $APE #APE #AMC https://t.co/rOOg4OahCl',
    date: '2023-09-01T09:48:22.000Z',
    stock: 'AMC',
    ai: Sentiment.positive,
    likes: 0,
    dataSource: Datasource.Twitter,
    user: {
      displayName: 'Ibanez Attention',
      image: '/images/AvatarDefault.png',
      followers: 892,
    },
    link: '',
  },
];

export const preloaderTopUsersData: ITopUserResponse[] = [
  {
    id: '1',
    displayName: 'Lykeion liekeyon',
    image: '/images/AvatarDefault.png',
    followers: 6975,
    postsCount: 100,
    dataSource: Datasource.Twitter,
    userName: '@test',
  },
  {
    id: '2',
    displayName: 'Lykeion liekeyon',
    image: '/images/AvatarDefault.png',
    followers: 6975,
    postsCount: 100,
    dataSource: Datasource.Twitter,
    userName: '@test',
  },
  {
    id: '3',
    displayName: 'Lykeion liekeyon',
    image: '/images/AvatarDefault.png',
    followers: 6975,
    postsCount: 100,
    dataSource: Datasource.Twitter,
    userName: '@test',
  },
  {
    id: '4',
    displayName: 'Lykeion liekeyon',
    image: '/images/AvatarDefault.png',
    followers: 6975,
    postsCount: 100,
    dataSource: Datasource.Twitter,
    userName: '@test',
  },
];
