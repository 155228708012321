import * as React from 'react';
import { SVGProps } from 'react';

export const VolumeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M18.333 18.334H1.66634" stroke="#475467" strokeWidth="1.25" strokeLinecap="round" />
    <path
      d="M17.4997 18.3337V12.0837C17.4997 11.7521 17.368 11.4342 17.1335 11.1998C16.8991 10.9654 16.5812 10.8337 16.2497 10.8337H13.7497C13.4181 10.8337 13.1002 10.9654 12.8658 11.1998C12.6314 11.4342 12.4997 11.7521 12.4997 12.0837V18.3337V4.16699C12.4997 2.98866 12.4997 2.39949 12.133 2.03366C11.768 1.66699 11.1788 1.66699 9.99966 1.66699C8.82133 1.66699 8.23216 1.66699 7.86633 2.03366C7.49966 2.39866 7.49966 2.98783 7.49966 4.16699V18.3337V7.91699C7.49966 7.58547 7.36797 7.26753 7.13355 7.03311C6.89913 6.79869 6.58118 6.66699 6.24966 6.66699H3.74966C3.41814 6.66699 3.1002 6.79869 2.86578 7.03311C2.63136 7.26753 2.49966 7.58547 2.49966 7.91699V18.3337"
      stroke="#475467"
      strokeWidth="1.25"
    />
  </svg>
);
