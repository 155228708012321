import {
  Box,
  Chip,
  CircularProgress,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  StepIconProps,
  styled,
} from '@mui/material';
import { SectionCard, SectionCardHeading } from 'components';
import { useStockNews } from 'pages/NewsRoom/useStockNews';
import { DateTime } from 'luxon';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserStockSymbol } from 'store/slices/authSlice';
import { OpenInNew } from '@mui/icons-material';

export interface StockNewsRef {
  scrollToNews: (timestamp: string) => void;
}

// Create a styled wrapper for the content
const AnimatedContent = styled(Box)<{ isflashing?: number }>`
  padding: 16px;
  border-radius: 8px;
  transition: background-color 0.3s ease;

  @keyframes flashBackground {
    0% {
      background-color: transparent;
    }
    30% {
      background-color: rgba(20, 112, 239, 0.3);
    }
    100% {
      background-color: transparent;
    }
  }

  ${({ isflashing }) =>
    isflashing &&
    `
    animation: flashBackground 2s ease;
  `}
`;

// Custom StepIcon component
const CustomStepIcon = (props: StepIconProps) => {
  return (
    <Box
      sx={{
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: '#1470ef',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    />
  );
};

const StockNewsTimeline = forwardRef<StockNewsRef>((_, ref) => {
  const selectedStock = useSelector(getUserStockSymbol);
  const { newsData: data, error, isLoading } = useStockNews();
  const newsRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [flashingId, setFlashingId] = useState<string | null>(null);

  useImperativeHandle(ref, () => ({
    scrollToNews: (timestamp: string) => {
      const newsCard = newsRefs.current[timestamp];
      if (newsCard) {
        newsCard.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setTimeout(() => {
          setFlashingId(timestamp);
          setTimeout(() => {
            setFlashingId(null);
          }, 2000);
        }, 1000);
      }
    },
  }));

  const getSentimentColor = (score: number): string => {
    if (score >= 0.35) return '#22c55e'; // Bullish - bright green
    if (score >= 0.15) return '#86efac'; // Somewhat-Bullish - light green
    if (score > -0.15) return '#94a3b8'; // Neutral - gray
    if (score > -0.35) return '#fca5a5'; // Somewhat-Bearish - light red
    return '#ef4444'; // Bearish - bright red
  };

  const getSentimentLabel = (score: number): string => {
    if (score >= 0.35) return 'Bullish';
    if (score >= 0.15) return 'Somewhat-Bullish';
    if (score > -0.15) return 'Neutral';
    if (score > -0.35) return 'Somewhat-Bearish';
    return 'Bearish';
  };

  const getStockSentiment = (news: any) => {
    const stockInsight = news.insights?.find((insight: any) => insight.ticker === selectedStock);
    const score = stockInsight?.sentiment_score || 0;
    return {
      sentiment: getSentimentLabel(score),
      score: score,
    };
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      );
    }

    if (error) {
      return (
        <Box p={2}>
          <Typography color="error">Error loading news</Typography>
        </Box>
      );
    }

    return (
      <Stepper
        orientation="vertical"
        nonLinear
        connector={null}
        sx={{
          '.MuiStepLabel-root': {
            padding: '24px 0',
          },
          '.MuiStepContent-root': {
            marginLeft: '4px',
            paddingLeft: '20px',
          },
          '.MuiStepLabel-label': {
            color: '#f6f7f8',
          },
        }}
      >
        {data?.map((news, index) => {
          const { sentiment, score } = getStockSentiment(news);
          return (
            <Step
              key={news.id}
              active
              expanded
              ref={el => (newsRefs.current[news.published_utc] = el)}
            >
              <AnimatedContent isflashing={flashingId === news.published_utc ? 1 : 0}>
                <StepLabel StepIconComponent={CustomStepIcon}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    width="100%"
                  >
                    <Typography variant="h6" component="h2" color="textPrimary" sx={{ flex: 1 }}>
                      {news.title}
                    </Typography>
                    <Chip
                      label={sentiment}
                      size="small"
                      sx={{
                        ml: 2,
                        borderRadius: '8px',
                        backgroundColor: getSentimentColor(score),
                        color: score >= 0.15 ? '#1e293b' : '#fff',
                        fontWeight: 'bold',
                        '& .MuiChip-label': {
                          color: '#1e293b',
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Box>
                </StepLabel>
                <StepContent>
                  <Box sx={{ mb: 2 }}>
                    <Typography color="textSecondary" gutterBottom>
                      {news.publisher.name} • {DateTime.fromISO(news.published_utc).toRelative()}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      display="block"
                      gutterBottom
                    >
                      Published:{' '}
                      {DateTime.fromISO(news.published_utc).toFormat('LLL dd, yyyy HH:mm')}
                    </Typography>
                    <Typography variant="body2" paragraph sx={{ color: '#f6f7f8' }}>
                      {news.description}
                    </Typography>
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        href={news.article_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        size="small"
                        endIcon={<OpenInNew />}
                        sx={{
                          borderRadius: '8px',
                          color: '#fff',
                        }}
                      >
                        Read more
                      </Button>
                    </Box>
                  </Box>
                </StepContent>
              </AnimatedContent>
            </Step>
          );
        })}
      </Stepper>
    );
  };

  return (
    <SectionCard>
      <SectionCardHeading title="Latest News" subtitle={`Recent news about ${selectedStock}`} />
      {renderContent()}
    </SectionCard>
  );
});

StockNewsTimeline.displayName = 'StockNewsTimeline';

export default StockNewsTimeline;
