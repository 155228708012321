import classNames from 'classnames';
import { BulletListItem, ScrollWrapper } from 'components';
import ParsedTextWithLink from 'components/ParsedTextWithLink';
import { BulbIcon } from 'components/SvgComponents';
import { AlertTypes, IAlertsDetailsResponse } from 'modules/Alerts/types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import InfluencerContent from './InfluencerContent';
import styles from './styles.module.scss';

const AlertDetailsContent = ({ details }: { details: IAlertsDetailsResponse }) => {
  const { insights, type, user, conversation, dataSource } = details;
  const { t } = useTranslation();

  if (type === AlertTypes.INFLUENCER) {
    return <InfluencerContent details={details} />;
  }

  return (
    <div className={styles.container}>
      {!!insights && typeof insights === 'object' && !!Object.keys(insights).length && (
        <>
          {insights?.header && (
            <>
              <div className={styles.containerTitle}>
                <BulbIcon />
                {t('pages.admin.insights')}
              </div>
              <div className={styles.content}>
                <ParsedTextWithLink text={insights.header} />
              </div>
            </>
          )}
        </>
      )}
      {!!insights?.body &&
        Array.isArray(insights?.body) &&
        insights.body.map((item, index) => {
          return <BulletListItem text={item} key={index} />;
        })}
      {!!insights?.body && typeof insights?.body === 'string' && (
        <div className={styles.content}>{insights?.body}</div>
      )}
    </div>
  );
};

interface IAlertContentProps {
  details: IAlertsDetailsResponse;
}

export const AlertContent = (props: IAlertContentProps) => {
  const { details } = props;
  const { recommendation, subTitle, type } = details;
  const { t } = useTranslation();

  return (
    <ScrollWrapper size={438}>
      <div className={classNames(styles.wrapper, { [styles.center]: !recommendation })}>
        {!!subTitle && type !== AlertTypes.INFLUENCER && (
          <div className={styles.title}>
            <ParsedTextWithLink text={subTitle} linkText={t('alerts.viewSource')} />
          </div>
        )}
        <AlertDetailsContent details={details} />
      </div>
    </ScrollWrapper>
  );
};

export default AlertContent;
