import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { InfiniteScroll, ScrollWrapper } from '../../../../components';
import { API_ROUTES } from '../../../../const';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getActiveAlertType } from '../../../../store/slices/alertsSlice';
import { getUserStockSymbol } from '../../../../store/slices/authSlice';
import {
  getAlertsFiltersTabsState,
  updateRequestedListPage,
} from '../../../../store/slices/filtersSlice';
import { IAlertsListItemResponse } from '../../types';
import { alertsListPreloaderData } from '../../utils';
import AlertItem from './AlertItem';

const AlertsList = () => {
  const dispatch = useAppDispatch();
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const activeAlertType = useAppSelector(getActiveAlertType);
  const { value: activeStorageType, page } = useAppSelector(getAlertsFiltersTabsState);
  const listRef = useRef<HTMLDivElement>(null);

  const queryOptions = useMemo(() => {
    return {
      ...(activeAlertType !== 'all' && { type: activeAlertType }),
      storage: activeStorageType,
    };
  }, [activeAlertType, activeStorageType]);

  const updatePage = (page?: number) => {
    dispatch(updateRequestedListPage({ storeKeyName: 'alertsFilters', count: page }));
  };

  useEffect(() => {
    if (!listRef.current) {
      return;
    }

    listRef.current.scrollTo(0, 0);
  }, [stockSymbol, activeStorageType]);

  const Wrapper = useCallback(({ children }: any) => <div>{children}</div>, []);
  return (
    <ScrollWrapper size={438}>
      <InfiniteScroll<IAlertsListItemResponse>
        page={page}
        queryOptions={queryOptions}
        updatePage={updatePage}
        ListComponent={Wrapper}
        ItemComponent={AlertItem}
        requestUrl={`${API_ROUTES.ALERTS}/${stockSymbol}`}
        preloaderData={alertsListPreloaderData}
        requestTrigger={stockSymbol}
      />
    </ScrollWrapper>
  );
};

export default AlertsList;
