import { createAction, createSlice } from '@reduxjs/toolkit';

import { IAdminStore } from '../../types';
import { RootState } from '../index';

const initialState: IAdminStore = {
  team: {
    list: {
      page: 1,
    },
    details: {
      id: '',
    },
  },
};

export const getTeamsListPage = (state: RootState) => state.admin.team.list.page;
export const updateListPage = createAction<{ key: keyof typeof initialState }>(
  'adminSlice/updateTeamsPageList'
);
export const resetListPage = createAction<{ key: keyof typeof initialState }>(
  'resetListPage/updateTeamsPageList'
);

const adminSlice = createSlice({
  name: 'alertsSlice',
  reducers: {},
  initialState,
  extraReducers: builder => {
    builder
      .addCase(updateListPage, (store, { payload }) => {
        store[payload.key].list.page++;
      })
      .addCase(resetListPage, (store, { payload }) => {
        store[payload.key].list.page = 1;
      });
  },
});

export const adminReducers = adminSlice.reducer;
