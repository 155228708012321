import classNames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

export enum TitleType {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
}
export const Title: React.FC<{
  type: TitleType;
  children: React.ReactNode;
  nativeStyles?: string;
}> = ({ type, children, nativeStyles = [] }) => {
  const Tag = type;

  const titleTypeStyles: { [key in TitleType]: string } = {
    [TitleType.h1]: styles.h1Title,
    [TitleType.h2]: styles.h2Title,
    [TitleType.h3]: styles.h3Title,
    [TitleType.h4]: styles.h4Title,
    [TitleType.h5]: styles.h5Title,
  };
  return (
    <Tag
      className={classNames(styles.title, titleTypeStyles[type], !!nativeStyles && nativeStyles)}
    >
      {children}
    </Tag>
  );
};
