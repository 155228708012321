import Typography from '@mui/material/Typography';
import { Layout, SectionCard, Title, TitleType } from 'components';
import { APP_ROUTES } from 'const';
import { useAppSelector } from 'hooks';
import { DateFilter } from 'modules';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUser } from 'store/slices/authSlice';
import {
  DatePickerFilterConfig,
  ExecutiveSummaryDaysFilter,
  FiltersDateRangeType,
  ITeamRole,
} from 'types';

import StocksTable from './StocksTable';
import SummaryCards from './SummaryCards';
import styles from './styles.module.scss';

const AgencyOverviewPage = () => {
  const user = useAppSelector(getUser);
  const navigate = useNavigate();
  const isSimpleUser = user.role === ITeamRole.user;
  const [selectedTab, setSelectedTab] = useState<ExecutiveSummaryDaysFilter>('daily');

  const filterConfig: DatePickerFilterConfig[] = useMemo(
    () => [
      {
        label: '24h',
        type: FiltersDateRangeType.h24,
        isActive: selectedTab === 'daily',
        onClick: () => {
          setSelectedTab('daily');
        },
      },
      {
        label: '1 week',
        type: FiltersDateRangeType.w1,
        isActive: selectedTab === 'weekly',
        onClick: () => {
          setSelectedTab('weekly');
        },
      },
    ],
    [selectedTab]
  );

  useEffect(() => {
    if (isSimpleUser) {
      navigate(APP_ROUTES.HOME);
    }
  }, [navigate, isSimpleUser]);

  if (isSimpleUser) {
    return null;
  }

  return (
    <Layout>
      <div className={styles.wrapper}>
        <div className={styles.stickyFilters}>
          <div>
            <div className={styles.title}>
              <Title type={TitleType.h2}>Data Summary</Title>
            </div>
            <Typography variant="body2">
              Showing last {selectedTab === 'daily' ? '24h' : 'week'}
            </Typography>
          </div>
          <div className={styles.finIntFiltersWrapper}>
            <DateFilter filterConfig={filterConfig} forceLoading={false} />
          </div>
        </div>
        <SummaryCards filterDay={selectedTab} />
        <SectionCard>
          <>
            <div className={styles.titleWrapper}>
              <Title type={TitleType.h2}>Your FinINT Matrix</Title>
            </div>
            <StocksTable filterDay={selectedTab} />
          </>
        </SectionCard>
      </div>
    </Layout>
  );
};

export default AgencyOverviewPage;
