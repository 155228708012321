import * as React from 'react';
import { SVGProps } from 'react';

export const IndexIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1896_1632)">
      <path
        d="M0.642822 9.10263H3.45854C3.57709 9.10126 3.69316 9.0684 3.79483 9.00739C3.89649 8.94639 3.98011 8.85945 4.03711 8.75548L6.35139 4.12691C6.40843 4.01167 6.49936 3.91664 6.61198 3.85459C6.7246 3.79254 6.85351 3.76643 6.98139 3.77977C7.10877 3.78838 7.23035 3.83628 7.32936 3.91687C7.42838 3.99747 7.49996 4.10679 7.53425 4.22977L10.4014 13.7698C10.439 13.8992 10.5164 14.0135 10.6225 14.0965C10.7286 14.1796 10.8582 14.2272 10.9928 14.2326C11.119 14.2284 11.2412 14.1872 11.3441 14.114C11.4469 14.0409 11.526 13.939 11.5714 13.8212L13.3328 9.51405C13.3813 9.39323 13.4647 9.28958 13.5723 9.21631C13.6799 9.14304 13.8069 9.10347 13.9371 9.10263H17.3571"
        stroke="#475467"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1896_1632">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
