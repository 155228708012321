import { AppButton, BlurredSpinner, ButtonsType, CompanyInfo, CustomSelect } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import useOptionStocks from 'hooks/useOptionStocks';
import { UiOrderPopup } from 'modules/UiOrderPopup';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { resetAlertModule } from 'store/slices/alertsSlice';
import { getUser, getUserStockSymbol, toggleAvailableStock } from 'store/slices/authSlice';
import { resetInfinityScrollFilters } from 'store/slices/filtersSlice';
import { IStock, ITeamRole } from 'types';

import styles from './styles.module.scss';

interface IPageHeaderProps {
  withChangeUIOrder?: boolean;
}

const PageHeader: React.FC<IPageHeaderProps> = ({ withChangeUIOrder = false }) => {
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const dispatch = useAppDispatch();
  const [isOpenUiConfig, setIsOpenUiConfig] = useState(false);
  const { role, team } = useAppSelector(getUser);
  const { stocksOptionLoading, stocksOptionData, stocksOptionError } = useOptionStocks();
  const {
    register,
    formState: { errors },
    control,
  } = useForm();
  const { t } = useTranslation();

  const stockKitsOptions = useMemo(() => {
    if (!team || stocksOptionLoading) {
      return [];
    }

    return team.stockKits.map(({ stock }) => {
      const stockInfo = stocksOptionData.find(({ symbol }) => symbol === stock);
      return {
        symbol: stock,
        name: stockInfo?.name || stock,
      };
    });
  }, [stocksOptionData, stocksOptionLoading, team]);

  const onChange = (value: string | string[]) => {
    const formattedValue = Array.isArray(value) ? value[0] : value;
    dispatch(resetInfinityScrollFilters());
    dispatch(resetAlertModule());
    dispatch(toggleAvailableStock(formattedValue));
  };
  const stockOptions = role === ITeamRole.admin ? stocksOptionData : stockKitsOptions;

  const SelectStockComponent = (
    <CustomSelect
      data={stockOptions!}
      placeholder={t('labels.switchStock')}
      loading={stocksOptionLoading}
      name={'stocks'}
      isMultiple={false}
      defaultValue={stockSymbol}
      control={control}
      register={register}
      withSearch
      searchPlaceholder={t('labels.searchStock')}
      onFieldChange={onChange}
    />
  );

  return (
    <div className={styles.wrapper}>
      <CompanyInfo />
      <div className={styles.labelRight}>
        {role === ITeamRole.admin && (
          <form>
            {stocksOptionLoading ? (
              <BlurredSpinner size={24} fillContent={true} />
            ) : (
              <div className={styles.selectStockWrapper}>{SelectStockComponent}</div>
            )}
          </form>
        )}
        {role === ITeamRole.reseller && (
          <form className={styles.selectStockWrapper}>{SelectStockComponent}</form>
        )}
        {withChangeUIOrder && (
          <div className={styles.uiButtonConfig}>
            <AppButton
              btnType={ButtonsType.borderedTransparent}
              label={t('pages.homepage.uiOrder.title')}
              onClick={() => setIsOpenUiConfig(true)}
            />
          </div>
        )}
      </div>
      {isOpenUiConfig && <UiOrderPopup onClose={() => setIsOpenUiConfig(false)} />}
    </div>
  );
};

export default PageHeader;
