import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CrudActions,
  CrudTable,
  CustomSelect,
  EllipsisWithTooltip,
  Title,
  TitleType,
} from '../../../../components';
import Link, { LinkType } from '../../../../components/Link';
import { API_ROUTES, APP_ROUTES } from '../../../../const';
import { ITableColumns, ITeam, ITeamRole } from '../../../../types';
import DashboardLayout from '../../DashboardLayout';
import ContentBlock from 'pages/Admin/ContentBlock';
import { useForm } from 'react-hook-form';
import useRoleOptions from 'hooks/useRoleOptions';
import styles from './styles.module.scss';
import InputField, { InputFieldType } from 'components/InputField';
import useDelayedWatch from 'hooks/useDelayedWatch';

const rows: ITableColumns<ITeam>[] = [
  {
    title: 'ID',
    dataIndex: '_id',
    key: '_id',
    render: (item, value) => <EllipsisWithTooltip text={value} width={60} />,
    width: 100,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sortable: true,
  },
  {
    title: 'Date created',
    dataIndex: 'dateCreated',
    key: 'dateCreated',
    sortable: true,
    render: (item, value) => {
      return <span>{DateTime.fromJSDate(new Date(value)).toFormat('LLL dd, yyyy')}</span>;
    },
  },
  {
    title: 'Last updated',
    dataIndex: 'dateUpdated',
    key: 'dateUpdated',
    sortable: true,
    render: (item, value) => {
      return <span>{DateTime.fromJSDate(new Date(value)).toFormat('LLL dd, yyyy')}</span>;
    },
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
  },
  {
    title: 'Users in team',
    dataIndex: 'usersCount',
    key: 'usersCount',
    align: 'center',
    sortable: true,
  },
  {
    title: '',
    dataIndex: '',
    key: '_id',
    render: (item, value, refresh = () => {}) => {
      return (
        <CrudActions
          toDetails={`${APP_ROUTES.TEAMS}/${value}`}
          toEdit={`${APP_ROUTES.TEAMS_EDIT}/${value}`}
          onRemoveUrl={`${API_ROUTES.TEAMS}/${value}`}
          dialogTitle={
            <div>
              <Title type={TitleType.h3}>
                <>Confirm delete team.</>
              </Title>
              <Title type={TitleType.h4}>
                <>Team ID: {value}</>
              </Title>
              <Title type={TitleType.h5}>
                <> Team name: {item.name}</>
              </Title>
            </div>
          }
          refresh={refresh}
        />
      );
    },
  },
];

interface FormValues {
  role: ITeamRole | 'all';
  name: string;
}

export const TeamsList = () => {
  const { t } = useTranslation();
  const { register, control, watch } = useForm<FormValues>({
    defaultValues: { role: 'all', name: '' },
  });

  const delayedValues = useDelayedWatch(watch, ['name'], 1000);
  const queryOptions = useMemo(() => {
    const options: { name?: string; role?: string } = {};

    (Object.keys(delayedValues) as Array<'name'>).forEach(key => {
      if (delayedValues[key]) {
        options[key] = delayedValues[key];
      }
    });

    return options;
  }, [delayedValues]);

  const role = watch('role');
  const roleOptions = useRoleOptions();

  if (role && role !== 'all') {
    queryOptions.role = role;
  } else {
    delete queryOptions.role;
  }

  return (
    <DashboardLayout
      title={t('pages.admin.teams')}
      createLink={<Link to={APP_ROUTES.TEAMS_CREATE} label={'Create'} linkType={LinkType.simple} />}
    >
      <>
        <ContentBlock>
          <div className={styles.filters}>
            <InputField
              control={control}
              name="name"
              defaultValue=""
              errors={undefined}
              label="Team Name"
              placeholder="Enter a team name"
              register={register}
              type={InputFieldType.text}
              validate={{}}
            />
            <CustomSelect
              name="role"
              register={register}
              control={control}
              data={roleOptions}
              placeholder="Role"
            />
          </div>
        </ContentBlock>
        <CrudTable<ITeam>
          columns={rows}
          requestUrl={API_ROUTES.TEAMS}
          queryOptions={queryOptions}
        />
      </>
    </DashboardLayout>
  );
};
