import { useAppDispatch, useAppSelector } from 'hooks';
import { CompanyResponse } from 'modules/ExecutiveSummary/types';
import { getStockData, setStockData } from 'store/slices/stocksSlice';
import useSWR from 'swr';

import { API_ROUTES } from '../const';
import { fetchWithConfig } from '../utils';

const useCompanyInfo = (stockSymbol: string) => {
  const companyInfo = useAppSelector(getStockData);
  const needRequest = companyInfo.symbol !== stockSymbol && stockSymbol;
  const dispatch = useAppDispatch();

  const { data, error, isLoading } = useSWR<CompanyResponse>(
    needRequest ? `${API_ROUTES.STOCKS}/${stockSymbol}` : null,
    async (url: string): Promise<CompanyResponse> => {
      const response: CompanyResponse = await fetchWithConfig<CompanyResponse>({
        url,
      });
      dispatch(setStockData(response));
      return response;
    }
  );

  return {
    companyInfo: companyInfo,
    companyLoading: isLoading,
  };
};

export default useCompanyInfo;
