import { Spinner } from 'components/Spinner';
import React from 'react';

import styles from './styles.module.scss';

interface ISuggestionsListProps {
  suggestions: { name: string; value: string }[];
  loading?: boolean;
  onClick: (value: string) => void;
  isShow?: boolean;
}

export const SuggestionsList: React.FC<ISuggestionsListProps> = ({
  suggestions,
  loading,
  onClick,
  isShow,
}) => {
  if (typeof isShow === 'boolean' && !isShow) {
    return null;
  }

  if (!loading && !suggestions.length) {
    return null;
  }

  if (loading) {
    return (
      <div className={styles.wrapper}>
        <Spinner staticPosition />
      </div>
    );
  }

  const onItemClick = (value: string) => {
    return () => {
      onClick(value);
    };
  };

  return (
    <div className={styles.wrapper}>
      {suggestions.map(suggestion => {
        const { name, value } = suggestion;

        return (
          <div
            role="button"
            tabIndex={-1}
            className={styles.suggestionItem}
            key={value}
            onClick={onItemClick(value)}
          >
            {name}
          </div>
        );
      })}
    </div>
  );
};
