import { ApexOptions } from 'apexcharts';
import React from 'react';
import Chart from 'react-apexcharts';

import { IChartSeries } from './interfaces';

type MixedChartsProps = {
  series: IChartSeries[];
  labels: string[];
  height: number;
  options?: ApexOptions;
};

const MixedCharts: React.FC<MixedChartsProps> = ({ series, labels, options, height }) => {
  //@ts-ignore
  const { chart = {}, ...otherOptions } = options;

  const data = {
    series,
    enabled: false,
    options: {
      labels,
      theme: {
        mode: 'dark',
      },
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        height: height,
        type: 'line',
        stacked: false,
        background: 'transparent',
        ...chart,
      },
      fill: { opacity: 1, colors: ['#98A2B340'] },
      plotOptions: {
        bar: {
          columnWidth: '100%',
        },
      },
      markers: {
        size: 0,
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y: any) {
            if (typeof y === 'number') {
              return y.toFixed(0) + ' ';
            }
            return y;
          },
        },
        style: {
          fontSize: '12px',
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
      },
      grid: {
        show: true,
        yaxis: {
          lines: {
            show: true,
            width: 5,
          },
        },
      },
      ...otherOptions,
    },
  };

  return (
    <Chart
      /* @ts-ignore */
      options={data.options}
      series={data.series}
      height={height}
    />
  );
};

export default MixedCharts;
