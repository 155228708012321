import * as React from 'react';
import { SVGProps } from 'react';

export const AmazonLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox={'0 0 32 32'} width={32} height={32} fill="none" {...props}>
    <rect width={32} height={32} fill="#494D5A" rx={3.657} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M11.576 13.902c-.403.577-.605 1.272-.605 2.084 0 1.024.294 1.816.881 2.375.588.56 1.338.84 2.252.84.594 0 1.092-.06 1.495-.177.641-.177 1.294-.624 1.958-1.343.048.06.13.174.25.345.118.17.204.285.258.344.053.059.142.153.267.283.124.13.27.265.436.406.202.082.374.07.516-.035l1.602-1.378c.095-.07.143-.153.143-.247a.526.526 0 0 0-.107-.283c-.19-.247-.327-.427-.41-.538-.083-.112-.169-.295-.258-.548a2.515 2.515 0 0 1-.133-.839v-4.274c0-.047-.006-.197-.018-.45-.012-.253-.03-.418-.054-.495a7.508 7.508 0 0 1-.106-.415c-.048-.2-.101-.353-.16-.459a3.405 3.405 0 0 0-.24-.362 3.36 3.36 0 0 0-.33-.38c-.76-.694-1.816-1.042-3.169-1.042H15.6c-1.056.06-1.978.342-2.768.848-.789.506-1.284 1.284-1.486 2.331a.513.513 0 0 0-.018.124c0 .165.101.27.303.318l2.047.247c.19-.035.309-.17.356-.406.083-.377.267-.671.552-.883a1.957 1.957 0 0 1 1.014-.371h.143c.534 0 .925.177 1.175.53.166.259.249.765.249 1.519v.3c-.724.059-1.246.106-1.567.141-.937.118-1.726.312-2.367.583-.7.294-1.252.73-1.655 1.307Zm2.804 2.835c-.231-.277-.347-.645-.347-1.104 0-1.013.522-1.666 1.567-1.96.356-.095.878-.142 1.566-.142v.442c0 .376-.003.647-.009.812-.006.165-.041.38-.107.645-.065.264-.163.509-.293.733-.273.506-.659.824-1.157.953a.913.913 0 0 0-.134.018.924.924 0 0 1-.151.017c-.392 0-.703-.138-.935-.415Z"
      clipRule="evenodd"
    />
    <path
      fill="#FF9C66"
      d="M5.522 21.114c.06-.107.158-.113.292-.02 3.036 1.936 6.34 2.904 9.91 2.904 2.38 0 4.73-.487 7.05-1.462l.265-.12c.115-.053.197-.093.246-.12.182-.08.325-.04.428.12.103.16.07.307-.1.44-.219.174-.498.374-.838.601a12.998 12.998 0 0 1-3.498 1.582 13.49 13.49 0 0 1-3.771.561c-1.895 0-3.687-.364-5.375-1.091a14.648 14.648 0 0 1-4.536-3.074c-.073-.067-.11-.134-.11-.2 0-.04.013-.08.037-.12Z"
    />
    <path
      fill="#FF9C66"
      d="M21.966 21.787a.561.561 0 0 1 .107-.144c.295-.203.579-.34.85-.411a5.72 5.72 0 0 1 1.312-.197c.118-.012.23-.006.337.018.532.047.85.137.957.268.047.072.07.18.07.322v.126c0 .417-.111.91-.336 1.476-.224.567-.538 1.023-.94 1.37-.058.047-.111.071-.159.071a.157.157 0 0 1-.07-.018c-.072-.036-.09-.101-.054-.197.437-1.038.656-1.76.656-2.166 0-.13-.024-.226-.071-.286-.118-.143-.449-.215-.992-.215-.201 0-.438.012-.71.036-.295.036-.567.072-.815.108-.07 0-.118-.012-.142-.036-.023-.024-.03-.048-.017-.072 0-.012.006-.03.017-.053Z"
    />
    <rect
      width={32}
      height={32}
      fill="url(#a123)"
      rx={3.657}
      style={{
        mixBlendMode: 'overlay',
      }}
    />
    <linearGradient id="a123" x1={0} x2={0} y1={0} y2={32} gradientUnits="userSpaceOnUse">
      <stop stopColor="#fff" stopOpacity={0.75} />
      <stop offset={1} stopColor="#2C2C2C" stopOpacity={0.01} />
    </linearGradient>
  </svg>
);
