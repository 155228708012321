import { SVGProps } from 'react';

export const RobotIconWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 160 160"
    width="160"
    height="160"
    fill="white"
    stroke="black"
    strokeWidth="2"
  >
    <rect x="25" y="40" width="110" height="85" rx="12" ry="12" fill="white" />

    <line x1="80" y1="20" x2="80" y2="40" stroke="black" strokeWidth="2" />
    <circle cx="80" cy="15" r="10" fill="white" stroke="black" strokeWidth="2" />

    <circle cx="60" cy="75" r="10" fill="black" />
    <circle cx="100" cy="75" r="10" fill="black" />

    <rect x="65" y="100" width="30" height="8" fill="black" />

    <rect
      x="15"
      y="60"
      width="12"
      height="35"
      rx="6"
      ry="6"
      fill="white"
      stroke="black"
      strokeWidth="2"
    />
    <rect
      x="133"
      y="60"
      width="12"
      height="35"
      rx="6"
      ry="6"
      fill="white"
      stroke="black"
      strokeWidth="2"
    />
  </svg>
);
