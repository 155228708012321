import { FormControlLabel, Switch } from '@mui/material';
import {
  AppButton,
  BlurredSpinner,
  ButtonsType,
  CheckboxComponent,
  CustomSelect,
} from 'components';
import { API_ROUTES, APP_ROUTES } from 'const';
import useOptionStockKits, { IStockKitForSelect } from 'hooks/useOptionStockKits';
import ContentBlock from 'pages/Admin/ContentBlock';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { required } from 'services/InputFIeldsValidator/rules';
import useSWR from 'swr';
import { ITeam, ITeamRole, IUser } from 'types';
import { catchRequestErrors, fetchWithConfig } from 'utils';

import { FormErrorsLabel } from '../../../../components/FormErrorsLabel';
import InputField, { InputFieldType } from '../../../../components/InputField';
import useOptionUsers from '../../../../hooks/useOptionUsers';
import ToastifyService from '../../../../services/ToastifyService';
import DashboardLayout from '../../DashboardLayout';
import styles from '../styles.module.scss';

type TTeamAvailablePages = ITeam['availablePages'];

type FormValues = {
  name: string;
  userIds: string[];
  owner: string;
  stockKits: string[];
  role: ITeamRole;
  availablePages: TTeamAvailablePages;
  stockKitsLimit: number;
};

const DEFAULT_AVAILABLE_PAGES: TTeamAvailablePages = {
  competitive: true,
  responder: true,
  reports: true,
};

type TStockKit = { stockKitId: string; isMain: boolean };

export const CreateEditTeam = () => {
  const { t } = useTranslation();
  const { teamId } = useParams();
  const { data, error, isLoading, mutate } = useSWR<ITeam>(
    teamId ? `${API_ROUTES.TEAMS}/${teamId}` : null,
    (url: string) => fetchWithConfig<ITeam>({ url })
  );
  const navigate = useNavigate();

  const { usersOptionData, usersOptionLoading } = useOptionUsers({ filter: 'not_in_team' });
  const { usersOptionData: usersInTeam, usersOptionLoading: usersInTeamLoading } = useOptionUsers({
    teamId,
  });
  const { stockKitsOptionLoading, stockKitsOptionData } = useOptionStockKits();

  const [stockKits, setStockKits] = useState<TStockKit[]>([]);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useForm<FormValues>({
    defaultValues: { stockKits: [], userIds: [], availablePages: DEFAULT_AVAILABLE_PAGES },
  });
  const loadingOptions = usersOptionLoading || usersInTeamLoading || stockKitsOptionLoading;

  const [loading, setLoading] = useState<boolean>(loadingOptions);
  const [globalError, setGlobalError] = useState<string>('');
  const [defaultValueInit, setDefaultValueInit] = useState(!teamId);

  useEffect(() => {
    setLoading(loadingOptions);
  }, [loadingOptions]);

  const selectedStockKits = watch('stockKits');

  const userOptions = useMemo(() => {
    if (teamId) {
      if (!usersOptionData || !usersInTeam) {
        return [];
      }

      return [...usersOptionData, ...usersInTeam];
    }

    return usersOptionData || [];
  }, [usersOptionData, usersInTeam, teamId]);

  const selectedUserIds = watch('userIds');
  const owner = watch('owner');
  const selectedRole = watch('role');
  const availablePages = watch('availablePages');
  const stockKitsLimit = watch('stockKitsLimit');

  useEffect(() => {
    if (!data) {
      return;
    }

    setValue('owner', data.owner);
    setValue('userIds', data.userIds);
    Object.keys(data.availablePages).forEach(key => {
      const keyTyped = key as keyof TTeamAvailablePages;
      setValue(`availablePages.${keyTyped}`, data.availablePages[keyTyped]);
    });
    setValue(
      'stockKits',
      data.stockKits.map(({ stockKitId }) => stockKitId)
    );
    setStockKits(data.stockKits);
    setDefaultValueInit(true);
  }, [data, setValue]);

  useEffect(() => {
    if (!selectedRole) {
      return;
    }

    if (selectedRole === 'user' && selectedStockKits.length > 1) {
      setValue('stockKits', [selectedStockKits[0]]);
    }
  }, [selectedRole, selectedStockKits.length]);

  useEffect(() => {
    if (!defaultValueInit) {
      return;
    }

    const stockKitsData = selectedStockKits
      .map(id => {
        const kit = stockKitsOptionData?.find(({ _id }) => _id === id);
        if (!kit) {
          return null;
        }

        const existed = stockKits.find(kit => kit.stockKitId === id);

        return {
          isMain: existed?.isMain || false,
          stockKitId: id,
        };
      })
      .filter(Boolean);

    setStockKits(stockKitsData as TStockKit[]);
  }, [defaultValueInit, selectedStockKits, stockKitsOptionData]);

  const onSetMain = (stockKitId: string, isMain: any) => {
    const updatedStockKits = stockKits.map(kit => {
      if (kit.stockKitId === stockKitId) {
        return {
          stockKitId,
          isMain,
        };
      }

      return {
        stockKitId: kit.stockKitId,
        isMain: false,
      };
    });

    setStockKits(updatedStockKits);
  };

  const possibleOwners = useMemo(() => {
    // @ts-ignore
    return (userOptions || []).filter(user => selectedUserIds.includes(user._id));
  }, [userOptions, selectedUserIds]);

  useEffect(() => {
    if (!owner || selectedUserIds.includes(owner)) {
      return;
    }

    setValue('owner', '');
  }, [selectedUserIds, owner, setValue]);

  const onSubmit = async (data: FormValues) => {
    setGlobalError('');

    if (stockKits.length && !stockKits.find(({ isMain }) => isMain)) {
      setGlobalError('Need to select 1 main kit');
      return;
    }

    try {
      setLoading(true);
      await fetchWithConfig<ITeam>({
        url: teamId ? `${API_ROUTES.TEAMS}/${teamId}` : API_ROUTES.TEAMS,
        method: teamId ? 'PATCH' : 'POST',
        data: {
          name: data.name,
          stockKitsLimit: data.role === ITeamRole.reseller ? +data.stockKitsLimit : null,
          userIds: data.userIds.filter(Boolean),
          owner: data.owner || null,
          stockKits,
          availablePages: data.availablePages,
          role: data.role,
        },
      });

      ToastifyService.setToast(`Team ${teamId ? 'updated' : 'created'} succesfuly`, 'success');
      setLoading(false);

      if (teamId) {
        mutate();
        return;
      }

      navigate(APP_ROUTES.TEAMS);
    } catch (error: any) {
      catchRequestErrors(error, setError, setGlobalError, setLoading);
    }
  };

  return (
    <DashboardLayout
      title={teamId ? data?.name || t('pages.admin.teamEdit') : t('pages.admin.teamCreate')}
      onSubmit={handleSubmit(onSubmit)}
      actionBtn={
        <AppButton
          disabled={Boolean(errors.name)}
          isLoading={loading}
          label={teamId ? 'Update' : 'Create'}
          btnType={ButtonsType.signIn}
        />
      }
    >
      {isLoading && teamId ? (
        <BlurredSpinner />
      ) : (
        <div>
          <ContentBlock>
            <>
              <div className={styles.row}>
                <div className={styles.labelWrapper}>
                  <p className={styles.label}>Enter team name</p>
                </div>
                <div className={styles.labelRight}>
                  <InputField
                    label={''}
                    control={control}
                    defaultValue={data?.name || ''}
                    name="name"
                    type={InputFieldType.text}
                    register={register}
                    placeholder="Team Name"
                    validateText={t('errors.requiredField')}
                    validate={{
                      required,
                    }}
                    errors={errors.name}
                  />
                </div>
              </div>
              {!!globalError && <div className={styles.error}>{globalError}</div>}
            </>
          </ContentBlock>
          <ContentBlock>
            <div className={styles.row}>
              <div className={styles.labelWrapper}>
                <p className={styles.label}>{t('pages.admin.availableTeamPages')}</p>
              </div>
              <div className={styles.labelRight}>
                {Object.keys(availablePages).map(key => {
                  return (
                    <CheckboxComponent
                      key={key}
                      control={control}
                      register={register}
                      defaultValue={availablePages[key as keyof TTeamAvailablePages]}
                      name={`availablePages.${key}`}
                      label={t(`mainNavigation.${key}`)}
                    />
                  );
                })}
              </div>
            </div>
          </ContentBlock>
          <ContentBlock>
            <div className={styles.row}>
              <div className={styles.labelWrapper}>
                <p className={styles.label}>{t('pages.admin.teamRole')}</p>
              </div>
              <div className={styles.labelRight}>
                <CustomSelect<IStockKitForSelect>
                  data={
                    [
                      { _id: ITeamRole.admin, name: ITeamRole.admin },
                      { _id: ITeamRole.user, name: ITeamRole.user },
                      { _id: ITeamRole.reseller, name: ITeamRole.reseller },
                    ]!
                  }
                  placeholder=""
                  name="role"
                  control={control}
                  defaultValue={data?.role || ''}
                  register={register}
                  required
                />
                <FormErrorsLabel message={errors?.role?.message} />
              </div>
            </div>
          </ContentBlock>
          {selectedRole === ITeamRole.reseller && (
            <ContentBlock>
              <div className={styles.row}>
                <div className={styles.labelWrapper}>
                  <p className={styles.label}>{t('pages.admin.stockKitsLimit')}</p>
                </div>
                <div className={styles.labelRight}>
                  <InputField
                    label={''}
                    control={control}
                    defaultValue={data?.stockKitsLimit || ''}
                    name="stockKitsLimit"
                    type={InputFieldType.number}
                    writePattern={/^(\s*|0|[1-9][0-9]*)$/}
                    register={register}
                    placeholder="Enter stock kits limit"
                    validateText={t('errors.requiredField')}
                    validate={{
                      required,
                    }}
                    errors={errors.stockKitsLimit}
                  />
                </div>
              </div>
            </ContentBlock>
          )}
          {selectedRole && (
            <>
              <ContentBlock>
                <div className={styles.row}>
                  <div className={styles.labelWrapper}>
                    <p className={styles.label}>{t('pages.admin.stockKitsPlaceholder')}</p>
                  </div>
                  <div className={styles.labelRight}>
                    <CustomSelect<IStockKitForSelect>
                      data={stockKitsOptionData!}
                      isMultiple
                      maxValues={
                        selectedRole === ITeamRole.user
                          ? 1
                          : selectedRole === ITeamRole.reseller
                            ? +stockKitsLimit
                            : undefined
                      }
                      placeholder=""
                      loading={stockKitsOptionLoading}
                      name="stockKits"
                      control={control}
                      defaultValue={selectedStockKits}
                      register={register}
                    />
                  </div>
                </div>
              </ContentBlock>
              {!!stockKits.length && (
                <ContentBlock>
                  <div>
                    {stockKits.map(kit => {
                      const { isMain, stockKitId } = kit;
                      const stockKitData = stockKitsOptionData.find(
                        ({ _id }) => _id === stockKitId
                      )!;

                      if (!stockKitData) {
                        return null;
                      }

                      return (
                        <div key={stockKitId}>
                          <div>{stockKitData.name}</div>
                          <FormControlLabel
                            control={
                              <Switch
                                onChange={e => onSetMain(stockKitId, e.target.checked)}
                                checked={isMain}
                              />
                            }
                            label="Is Main"
                          />
                        </div>
                      );
                    })}
                  </div>
                </ContentBlock>
              )}
            </>
          )}
          <ContentBlock>
            <div className={styles.row}>
              <div className={styles.labelWrapper}>
                <p className={styles.label}>{t('pages.admin.usersPlaceholder')}</p>
              </div>
              <div className={styles.labelRight}>
                <CustomSelect<Partial<IUser>>
                  data={userOptions}
                  placeholder=""
                  loading={isLoading}
                  name="userIds"
                  control={control}
                  defaultValue={data?.userIds || []}
                  isMultiple={true}
                  register={register}
                />
                <FormErrorsLabel message={errors?.userIds?.message} />
              </div>
            </div>
          </ContentBlock>
          {!!possibleOwners.length && (
            <ContentBlock>
              <div className={styles.row}>
                <div className={styles.labelWrapper}>
                  <p className={styles.label}>{t('pages.admin.ownerPlaceholder')}</p>
                </div>
                <div className={styles.labelRight}>
                  <>
                    <CustomSelect<Partial<IUser>>
                      data={possibleOwners}
                      placeholder=""
                      loading={usersOptionLoading}
                      name="owner"
                      control={control}
                      defaultValue={data?.owner || ''}
                      register={register}
                    />
                    <FormErrorsLabel message={errors?.userIds?.message} />
                  </>
                </div>
              </div>
            </ContentBlock>
          )}
        </div>
      )}
    </DashboardLayout>
  );
};
