import { Slider } from '@mui/material';
import classNames from 'classnames';
import { AppButton, ButtonsType } from 'components';
import { RobotIcon } from 'components/SvgComponents/RobotIcon';
import { API_ROUTES } from 'const';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IGeneralBotsFilterConfig } from 'types';
import { fetchWithConfig } from 'utils';

import ListPopup from '../../_SharedComponents/ListPopup';
import styles from './styles.module.scss';

export interface CreateBotsFilterFormValues {
  phrases: string[];
  tickerCounter: number;
}

interface ICreatebotsFilterProps {
  stock?: string;
  onChange: (data: CreateBotsFilterFormValues) => void;
  validationError: string;
  setValidationError: (value: string) => void;
}

const marks = [
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  },
];

const CreateGeneralBotsFilter: React.FC<ICreatebotsFilterProps> = props => {
  const [isBotsFilterPopupOpen, setIsBotsFilterPopupOpen] = useState(false);

  const { stock, onChange, setValidationError } = props;

  const { watch, setValue } = useForm<CreateBotsFilterFormValues>({
    defaultValues: {
      phrases: [''],
      tickerCounter: 8,
    },
  });

  const allData = watch();

  useEffect(() => {
    const fetchData = async () => {
      if (stock) {
        try {
          const response: IGeneralBotsFilterConfig = await fetchWithConfig({
            url: `${API_ROUTES.GENERAL_BOTS_FILTER}/${stock}`,
          });
          setValue('phrases', response.phrases);
          setValue('tickerCounter', response.tickerCounter);
        } catch (error) {
          console.error(
            `Failed to fetch filter from ${API_ROUTES.GENERAL_BOTS_FILTER}/${stock}:`,
            error
          );
        }
      }
    };

    fetchData();
  }, [setValue, stock]);

  useEffect(() => {
    onChange(allData);
    setValidationError('');
  }, [JSON.stringify(allData)]);

  const phrases = allData.phrases;
  const tickerCounter = allData.tickerCounter;

  return (
    <>
      <div className={styles.row}>
        <div className={styles.sourceData}>
          <RobotIcon />
          <div className={styles.title}>General bots filter</div>
        </div>
      </div>
      <div className={classNames(styles.row, styles.btn)}>
        <AppButton
          htmlType="button"
          btnType={ButtonsType.outlined}
          onClick={() => setIsBotsFilterPopupOpen(true)}
          label="Bots filter"
        />
      </div>
      <div className={classNames(styles.row, styles.margin)}>
        <div className={styles.sliderLabel}>Ticker counter threshold:</div>
        <Slider
          color="secondary"
          className={styles.slider}
          value={tickerCounter}
          name="tickerCounter"
          onChange={(_, newValue) => setValue('tickerCounter', newValue as number)}
          step={1}
          marks={marks}
          min={4}
          max={10}
          valueLabelDisplay="auto"
        />
      </div>
      {isBotsFilterPopupOpen && (
        <ListPopup
          onClose={() => setIsBotsFilterPopupOpen(false)}
          onSave={value => setValue('phrases', value)}
          defaultValue={phrases}
          title="General bots filter"
        />
      )}
    </>
  );
};

export default CreateGeneralBotsFilter;
