import { APP_ROUTES } from 'const';
import React, { useLayoutEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import { ITeamRole } from '../../types';
import { Spinner } from '../Spinner';

const ProtectedRoute = ({ accessRole = ITeamRole.user }) => {
  const { loading, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;

  useLayoutEffect(() => {
    if (loading) {
      return;
    }

    if (user.role === ITeamRole.user) {
      if (accessRole === ITeamRole.admin) {
        navigate(APP_ROUTES.HOME);
        return;
      }
      if (user._id && (!user.team || !user.team.stockKits.length)) {
        navigate(APP_ROUTES.OUT_OF_STOCK);
        return;
      }
    }
    if (!user?._id && !loading) {
      navigate(APP_ROUTES.LOGIN, { state: { from: `${pathname}${search}` } });
      return;
    }
  }, [user._id, navigate, user.role, user.team, loading, accessRole]);

  if (loading) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  return user?._id ? (
    <Outlet />
  ) : (
    <Navigate to={APP_ROUTES.LOGIN} state={{ from: `${pathname}${search}` }} />
  );
};

export default ProtectedRoute;
