import { AppButton, ButtonsType, IChip, InputChipset, Popup } from 'components';
import { FormErrorsLabel } from 'components/FormErrorsLabel';
import { API_ROUTES } from 'const';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mailRule } from 'services/InputFIeldsValidator/rules';
import ToastifyService from 'services/ToastifyService';
import { IReport } from 'types';
import { fetchWithConfig } from 'utils';

import styles from './styles.module.scss';

interface ISendReportPopupProps {
  onClose: () => void;
  report: IReport;
}

const SendReportPopup: React.FC<ISendReportPopupProps> = ({ onClose, report }) => {
  const { t } = useTranslation();
  const [mailList, setMailList] = useState(report.mailingList);
  const defaultChipsMailing = useMemo(() => {
    return report.mailingList.map((val, index) => ({
      id: new Date().getTime() + index,
      value: val,
    }));
  }, [report.mailingList]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const onSendReport = async () => {
    if (!mailList.length) {
      setError(t('errors.requiredField'));
      return;
    }

    setLoading(true);

    try {
      const res = await fetchWithConfig({
        url: `${API_ROUTES.REPORTS}/send/${report._id}`,
        method: 'POST',
        data: { mailingList: mailList },
      });
      report.mailingList = mailList;
      ToastifyService.setToast(res.message as string, 'success');
    } catch (e: any) {
      ToastifyService.setToast(e.message as string, 'error');
    }

    setLoading(false);
    onClose();
  };

  const onChange = (list: IChip[]) => {
    setError('');
    setMailList(list.map(chip => chip.value));
  };

  return (
    <Popup title={t('pages.reports.modal.sendReport')} onClose={onClose}>
      <div>
        <div className={styles.mailingListTitle}>{t('pages.reports.modal.steps.mailing')}</div>
        <InputChipset
          onChipsChanged={onChange}
          validateFn={mailRule}
          defaultValues={defaultChipsMailing}
        />
        {error && <FormErrorsLabel message={error} />}
        <div className={styles.actions}>
          <AppButton
            disabled={loading}
            onClick={onClose}
            btnType={ButtonsType.simple}
            label={t('pages.reports.modal.cancel')}
          />
          <AppButton
            disabled={loading}
            onClick={onSendReport}
            btnType={ButtonsType.simpleSave}
            label={t('pages.reports.modal.send')}
          />
        </div>
      </div>
    </Popup>
  );
};

export default SendReportPopup;
