import classNames from 'classnames';
import CreateSummaryPopup from 'modules/Charts/components/Summary/CreateSummaryPopup';
import SummaryItem from 'modules/Charts/components/Summary/SummaryItem';
import useSummaries from 'modules/Charts/hooks/useSummaries';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { Sentiment } from 'types';

import { PrPoints, SectionCard } from '../../../components';
import { API_ROUTES } from '../../../const';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getSelectedTimeZone, getUserStockSymbol } from '../../../store/slices/authSlice';
import {
  getFilterDateRangeType,
  getFilterWithoutBotsAndRobots,
  getFiltersDatasource,
  getFiltersDateRangeFrom,
  getFiltersDateRangeTo,
  setLoadingState,
} from '../../../store/slices/filtersSlice';
import { fetchWithConfig, isSameDateIgnoringTime } from '../../../utils';
import ChartSpinner from '../ChartSpinner';
import MixedCharts from '../CommonCharts/MIxedCharts';
import { ChartSeriesName, IPrPoint, ISentimentGraphData } from '../types';
import {
  getMaxAndMinFromSeries,
  getXaxisLabels,
  getXaxisTooltipLabel,
  preloaderLinearChartData,
  renderTooltip,
} from '../utils';
import styles from './styles.module.scss';

interface ISentimentGraphsProps {
  onSetSummaryComponent: (component: React.ReactNode | null) => void;
}

const SentimentGraphs = (props: ISentimentGraphsProps) => {
  const filterFrom = useAppSelector(getFiltersDateRangeFrom);
  const filterTo = useAppSelector(getFiltersDateRangeTo);
  const dataSource = useAppSelector(getFiltersDatasource);
  const filtersRangeType = useAppSelector(getFilterDateRangeType);
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const withoutBotsAndRobots = useAppSelector(getFilterWithoutBotsAndRobots);
  const selectedTimeZone = useAppSelector(getSelectedTimeZone);
  const [prPoints, setPrPoints] = useState<IPrPoint[]>([]);
  const { onSetSummaryComponent } = props;
  const datesRange = { fromDate: filterFrom, toDate: filterTo };

  const dispatch = useAppDispatch();
  const { data, error, isLoading } = useSWR<any>(
    stockSymbol
      ? [
          `${API_ROUTES.SENTIMENT_GRAPH}/${stockSymbol}`,
          { from: filterFrom, to: filterTo, dataSource, withBotsAndRobots: !withoutBotsAndRobots },
        ]
      : null,
    (options: [string, Record<string, any>]) => {
      return fetchWithConfig({
        url: options[0],
        params: options[1],
      });
    }
  );

  useEffect(() => {
    dispatch(setLoadingState({ key: 'dateRange', value: isLoading }));
  }, [dispatch, isLoading]);

  const { t } = useTranslation();

  const renderData: ISentimentGraphData = useMemo(() => {
    return isLoading ? preloaderLinearChartData : data || { series: [], dates: [] };
  }, [data, isLoading]);

  const { min: minPrice, max: maxPrice } = getMaxAndMinFromSeries(renderData.series, 'Price');
  const { max: maxChatter } = getMaxAndMinFromSeries(renderData.series, 'Positive');
  const prData = renderData?.prLinks || [];
  const categories = renderData?.dates?.map(item => item) || [];
  const interval = renderData?.interval || '1d';

  const {
    onMarkerClick,
    onCloseSummaryPopup,
    summaries,
    onGenerateSummary,
    summaryPrevData,
    summaryActiveData,
    summaryPopupData,
    isGeneratingSummary,
    clearSummaryData,
  } = useSummaries({
    queryFilters: {
      from: filterFrom,
      to: filterTo,
      stockSymbol,
      dataSource,
      filtersRangeType,
      sentiments: [Sentiment.positive, Sentiment.negative],
    },
    interval: interval,
    categoryDates: renderData.dates,
    seriesClickConfig: {
      0: Sentiment.positive,
      1: Sentiment.negative,
    },
  });

  const SummaryComponent = useMemo(() => {
    if (!summaryActiveData && !summaryPrevData && !isGeneratingSummary) {
      return null;
    }

    return (
      <SummaryItem
        isLoading={isGeneratingSummary}
        data={summaryActiveData}
        prevData={summaryPrevData}
        onClose={clearSummaryData}
      />
    );
  }, [isGeneratingSummary, clearSummaryData, summaryActiveData, summaryPrevData]);

  useEffect(() => {
    onSetSummaryComponent(SummaryComponent);
  }, [SummaryComponent, onSetSummaryComponent]);

  const { show: showSummaryPopup, data: summaryCreateData } = summaryPopupData;

  const summariesMarkers = summaries
    .map(summary => {
      const dateIndex = categories.findIndex(date => isSameDateIgnoringTime(summary.dayDate, date));
      if (dateIndex < 0) {
        return null;
      }

      return {
        seriesIndex: summary.sentiment === Sentiment.positive ? 0 : 1,
        dataPointIndex: dateIndex,
        fillColor: summary.sentiment === Sentiment.positive ? '#49D28D' : '#F04438',
        strokeColor: '#fff',
        size: 7,
        shape: 'circle',
      };
    })
    .filter(Boolean);

  const options = useMemo(() => {
    return {
      stroke: {
        width: 2,
        curve: ['smooth', 'smooth', 'smooth'],
        colors: ['#49D28D', '#F04438', '#0086C9'],
      },
      markers: {
        size: 0.000001,
        discrete: summariesMarkers,
        hover: {
          size: 10,
        },
      },
      chart: {
        animations: {
          enabled: false,
        },
        events: {
          dataPointSelection: onMarkerClick,
          updated() {
            const linesSeriesWrapper: SVGAElement | null = document.querySelector(
              '.sentiment-chart-wrapper .apexcharts-inner.apexcharts-graphical'
            );

            if (!prData || !linesSeriesWrapper) {
              return;
            }

            const linesSeries = [...linesSeriesWrapper.querySelectorAll('.apexcharts-xaxis-tick')];
            const pointWidth = 24;
            const prOverlay = document.querySelector('.sentiment-chart-wrapper .pr-overlay')!;
            const groupedPrPoints = prData.reduce(
              (acc, pr) => {
                const { dayDate } = pr;

                const dateIndex = categories?.findIndex(dateVal =>
                  isSameDateIgnoringTime(dateVal, dayDate)
                );
                if (dateIndex < 0) {
                  return acc;
                }

                const htmlItem = linesSeries[dateIndex];
                if (!htmlItem) {
                  return acc;
                }

                const graphicalWrapper: SVGAElement | null = document.querySelector(
                  '.sentiment-chart-wrapper .apexcharts-inner.apexcharts-graphical'
                );
                if (prOverlay && graphicalWrapper) {
                  prOverlay.setAttribute('style', `left: ${graphicalWrapper?.getCTM()?.e}px`);
                }

                const firstLine = linesSeriesWrapper.querySelector('.apexcharts-xcrosshairs');
                const heightLine = firstLine?.getAttribute('height') || 0;
                const xLinePosition = parseFloat(htmlItem.getAttribute('x1') || '0');

                const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
                line.setAttribute('x', xLinePosition.toString());
                line.setAttribute('x1', xLinePosition.toString());
                line.setAttribute('x2', xLinePosition.toString());
                line.setAttribute('y1', '0');
                line.setAttribute('y', '0');
                line.setAttribute('y2', heightLine.toString());
                line.setAttribute('width', '1');
                line.setAttribute('height', heightLine.toString());
                line.setAttribute('stroke', '#b6b6b6');
                line.setAttribute('stroke-opacity', '0.4');
                line.setAttribute('stroke-dasharray', '3');
                line.setAttribute('class', 'apexcharts-xcrosshairs apexchart-summary-crosshair');
                line.setAttribute('fill', '#b1b9c4');

                document
                  .querySelector('.sentiment-chart-wrapper .apexcharts-inner.apexcharts-graphical')
                  ?.appendChild(line);

                const x = xLinePosition - pointWidth / 2;

                if (!acc[dayDate]) {
                  acc[dayDate] = {
                    ...pr,
                    x,
                    additionalData: [],
                  };
                } else {
                  acc[dayDate].additionalData.push(pr);
                }

                return acc;
              },
              {} as Record<string, any>
            );

            const newPrPoints = Object.values(groupedPrPoints);

            if (JSON.stringify(prPoints) !== JSON.stringify(newPrPoints)) {
              setPrPoints(newPrPoints as IPrPoint[]);
            }
          },
        },
      },
      colors: ['#49D28D', '#F04438', '#0086C9'],
      xaxis: {
        labels: {
          type: 'datetime',
          //@ts-ignore
          categories: renderData?.dates?.map(item => item) || [],
          formatter: function (value: any) {
            return getXaxisLabels(value, datesRange, selectedTimeZone);
          },
          rotate: 0,
        },
        tooltip: {
          formatter: function (value: any, data: any) {
            return getXaxisTooltipLabel(value, data, selectedTimeZone, datesRange);
          },
        },
        tickAmount: 7,
      },
      yaxis: [
        {
          opposite: true,
          seriesName: 'Positive',
          title: {
            text: t('charts.labels.chatter'),
          },
          max: maxChatter,
          min: 0,
          labels: {
            formatter: function (value: any) {
              return value?.toFixed();
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        {
          seriesName: 'Positive',
          show: false,
          opposite: true,
          title: {
            text: t('charts.labels.negative'),
          },
          min: 0,
          labels: {
            formatter: function (value: any) {
              return value?.toFixed(0);
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        {
          title: {
            text: t('charts.labels.price'),
          },
          max: maxPrice,
          min: minPrice,
          labels: {
            formatter: function (value: any) {
              return value?.toFixed(2);
            },
          },
          tooltip: {
            enabled: true,
          },
        },
      ],
      legend: {
        formatter: (seriesName: string, opts: any) => {
          if (seriesName.toLowerCase() === ChartSeriesName.price) return t('charts.labels.price');
          return seriesName;
        },
        markers: {
          width: [8, 8, 8],
          height: [8, 8, 8],
        },
        position: 'top',
        horizontalAlign: 'right',
        offsetY: -9,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
          return renderTooltip(
            { series, seriesIndex, dataPointIndex, w },
            styles,
            selectedTimeZone
          );
        },
      },
    };
  }, [
    categories,
    filtersRangeType,
    maxPrice,
    minPrice,
    prData,
    prPoints,
    renderData?.dates,
    selectedTimeZone,
    t,
  ]);

  const newSeries =
    renderData?.series?.map((item: any) => ({
      name: item.name,
      type: item.type,
      data: item.data,
      dataLabels: {
        enabled: false,
      },
    })) || [];
  const newDataLabels = renderData?.dates?.map((item: any) => {
    return item;
  });

  return (
    <>
      <SectionCard nativeClassName={styles.pb}>
        <>
          <div
            className={classNames(styles.wrapper, 'sentiment-chart-wrapper', {
              [styles.withClick]: interval === '1d',
            })}
          >
            <MixedCharts
              series={newSeries}
              labels={newDataLabels}
              height={365}
              /*@ts-ignore*/
              options={options}
            />
            <PrPoints
              points={prPoints}
              customClassName={classNames(styles.prOverlay, 'pr-overlay')}
            />
          </div>
          {isLoading && <ChartSpinner />}
        </>
      </SectionCard>
      {showSummaryPopup && (
        <CreateSummaryPopup
          onClose={onCloseSummaryPopup}
          data={summaryCreateData!}
          onSubmit={onGenerateSummary}
        />
      )}
    </>
  );
};

export default SentimentGraphs;
