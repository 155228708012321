import * as React from 'react';
import { SVGProps } from 'react';

export const ChatterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.16667 10C4.96905 9.39821 5.96158 9.10605 6.96204 9.17715C7.9625 9.24825 8.90373 9.67784 9.61295 10.3871C10.3222 11.0963 10.7518 12.0375 10.8229 13.038C10.894 14.0384 10.6018 15.031 10 15.8333M4.16667 10C3.64885 10.3878 3.22859 10.891 2.93927 11.4696C2.64995 12.0483 2.49955 12.6864 2.5 13.3333C2.49973 13.7523 2.56266 14.169 2.68667 14.5692C2.91333 15.3025 2.75 16.1192 2.60417 16.8892C2.59124 16.955 2.5955 17.023 2.61654 17.0867C2.63758 17.1504 2.67469 17.2076 2.72429 17.2527C2.77389 17.2979 2.83429 17.3295 2.89967 17.3445C2.96504 17.3595 3.03318 17.3574 3.0975 17.3383C3.80417 17.145 4.50667 16.98 5.22833 17.245C5.68898 17.4142 6.17593 17.5005 6.66667 17.5C7.31363 17.5006 7.9518 17.3502 8.53045 17.0609C9.10911 16.7716 9.61229 16.3512 10 15.8333M4.16667 10C4.16667 6.09583 6.5625 2.5 10.8333 2.5C11.9025 2.49981 12.956 2.75676 13.9049 3.24916C14.8539 3.74157 15.6706 4.45499 16.286 5.32924C16.9014 6.20349 17.2975 7.21292 17.4409 8.27239C17.5843 9.33185 17.4708 10.4103 17.11 11.4167C16.7133 12.5217 17.1408 14.0017 17.3908 15.2233C17.4054 15.2884 17.4024 15.3561 17.3822 15.4196C17.3619 15.4831 17.3251 15.5401 17.2756 15.5847C17.2261 15.6294 17.1656 15.66 17.1003 15.6735C17.035 15.687 16.9673 15.6829 16.9042 15.6617C15.8108 15.32 14.5275 14.84 13.5 15.2733C12.3883 15.7417 11.22 15.8333 10 15.8333"
      stroke="#475467"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
