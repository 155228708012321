import { useAppSelector } from 'hooks';
import React from 'react';

import { APP_ROUTES } from '../../../const';
import { getUser } from '../../../store/slices/authSlice';
import ProfileLayoutWrapper from 'components/ProfileLayout';

const config = [
  {
    title: 'pages.profile.profile',
    path: APP_ROUTES.PROFILE,
  },
];
const ProfileLayout: React.FC<{
  children: React.ReactNode;
  title?: string;
}> = ({ children, title }) => {
  const user = useAppSelector(getUser);
  const updatedConfig = [...config];

  /*if (user.isTeamOwner) {
    updatedConfig.push({
      title: 'pages.profile.plans',
      path: APP_ROUTS.PLANS
    });
  }*/

  return (
    <ProfileLayoutWrapper title={title} navConfig={updatedConfig} withBackButton={false}>
      {children}
    </ProfileLayoutWrapper>
  );
};

export default ProfileLayout;
