import classNames from 'classnames';
import { CheckboxComponent, CustomSelect } from 'components';
import InputField, { InputFieldType } from 'components/InputField';
import { useAppSelector } from 'hooks';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { required } from 'services/InputFIeldsValidator/rules';
import { getSelectedTimeZone } from 'store/slices/authSlice';
import { getTimeFrame } from 'utils';

import styles from '../styles.module.scss';

const formatDate = (date: string) => {
  return new Date(date.slice(0, -6));
};

interface IInfoFormProps {
  control: any;
  register: any;
  errors: any;
  setValue: any;
  watch: any;
  setError: any;
  configId?: string | null;
}

const InfoForm: React.FC<IInfoFormProps> = ({
  control,
  register,
  errors,
  setValue,
  watch,
  setError,
  configId,
}) => {
  const { t } = useTranslation();
  const scheduled = watch('scheduled');
  const scheduleType = watch('schedule.type');
  const selectedFrom = watch('fromDate');
  const selectedTo = watch('toDate');
  const zone = useAppSelector(getSelectedTimeZone);
  const minDate = DateTime.now().startOf('month').minus({ month: 3 }).toJSDate();
  const initialRange = [
    formatDate(selectedFrom || DateTime.now().minus({ days: 1 }).toJSDate().toString()),
    formatDate(selectedTo || DateTime.now().toJSDate().toString()),
  ];

  const scheduleTypeOptions = useMemo(() => {
    return [
      { _id: 'daily', name: t('pages.reports.modal.schedule.daily') },
      { _id: 'weekly', name: t('pages.reports.modal.schedule.weekly') },
      { _id: 'monthly', name: t('pages.reports.modal.schedule.monthly') },
    ];
  }, [t]);

  const maxEventsOptions = useMemo(() => {
    return [
      { _id: 3, name: 3 },
      { _id: 5, name: 5 },
    ];
  }, [t]);

  const weekDayOptions = useMemo(() => {
    return Array.from({ length: 7 }, (_, i) => i + 1).map(val => ({
      _id: val,
      name: t(`pages.reports.modal.schedule.days.${val}`),
    }));
  }, [t]);

  const onRangeChange = (fromDate: string, toDate: string) => {
    setValue('timeFrame', getTimeFrame(fromDate, toDate, zone));
    setValue('fromDate', fromDate);
    setValue('toDate', toDate);
    setError('timeFrame', null);
  };

  return (
    <>
      <div className={styles.field}>
        <InputField
          label={t('pages.reports.modal.fields.reportName')}
          control={control}
          defaultValue=""
          name="name"
          type={InputFieldType.text}
          register={register}
          placeholder=""
          validate={{
            required,
          }}
          errors={errors.name}
        />
      </div>
      <div className={styles.field}>
        <InputField
          name="stock"
          register={register}
          control={control}
          label={t('pages.reports.modal.fields.stock')}
          errors={errors.stock}
          required
          defaultValue=""
          type={InputFieldType.text}
          validate={{}}
          disabled
          placeholder=""
        />
      </div>
      <div className={classNames(styles.field, styles.rowFields)}>
        <InputField
          className={styles.rowFieldInput}
          min={1}
          max={100}
          label={t('pages.reports.modal.fields.minPriceChangePercentage')}
          control={control}
          register={register}
          toolTip={t('pages.reports.modal.fields.minPriceChangeTooltip')}
          name="minPriceChangePercentage"
          defaultValue={1}
          errors={errors.minPriceChangePercentage}
          placeholder=""
          type={InputFieldType.number}
          validate={{ required }}
        />
        <CustomSelect
          name="maxEvents"
          register={register}
          control={control}
          data={maxEventsOptions}
          placeholder={t('pages.reports.modal.fields.maxEvents')}
          toolTip={t('pages.reports.modal.fields.minPriceChangeTooltip')}
          errors={errors.maxEvents}
          required
        />
      </div>
      {!configId && (
        <div className={styles.field}>
          <CheckboxComponent
            label={t('pages.reports.modal.fields.scheduled')}
            defaultValue={false}
            control={control}
            register={register}
            name="scheduled"
          />
        </div>
      )}
      {!scheduled && (
        <div className={styles.field}>
          <InputField
            autocomplete={false}
            rangePickerConfig={{ minDate, initialRange, onRangeChange }}
            label={t('pages.reports.modal.fields.timeFrame')}
            control={control}
            register={register}
            name="timeFrame"
            defaultValue=""
            errors={errors.timeFrame}
            placeholder=""
            type={InputFieldType.text}
            validate={{ required }}
          />
        </div>
      )}
      {scheduled && (
        <>
          <div className={styles.field}>
            <CustomSelect
              name="schedule.type"
              register={register}
              control={control}
              data={scheduleTypeOptions}
              placeholder={t('pages.reports.modal.fields.scheduleType')}
              errors={errors.schedule?.type}
              required
            />
          </div>
          {scheduleType && (
            <>
              <div className={classNames(styles.field, styles.rowFields)}>
                <InputField
                  min={0}
                  max={23}
                  label={t('pages.reports.modal.fields.hour')}
                  control={control}
                  register={register}
                  name="schedule.hour"
                  defaultValue={0}
                  errors={errors.schedule?.hour}
                  placeholder=""
                  type={InputFieldType.number}
                  validate={{ required }}
                />
                <InputField
                  min={0}
                  max={59}
                  label={t('pages.reports.modal.fields.minutes')}
                  control={control}
                  register={register}
                  name="schedule.minutes"
                  defaultValue={0}
                  errors={errors.schedule?.minutes}
                  placeholder=""
                  type={InputFieldType.number}
                  validate={{ required }}
                />
              </div>
              {scheduleType === 'weekly' && (
                <div className={styles.field}>
                  <CustomSelect
                    name="schedule.weekDay"
                    register={register}
                    control={control}
                    data={weekDayOptions}
                    placeholder={t('pages.reports.modal.fields.weekDay')}
                    errors={errors.schedule?.weekDay}
                    required
                  />
                </div>
              )}
              {scheduleType === 'monthly' && (
                <div className={styles.field}>
                  <InputField
                    min={1}
                    max={31}
                    label={t('pages.reports.modal.fields.monthDay')}
                    control={control}
                    register={register}
                    name="schedule.monthDay"
                    defaultValue={0}
                    errors={errors.schedule?.monthDay}
                    placeholder=""
                    type={InputFieldType.number}
                    validate={{ required }}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default InfoForm;
