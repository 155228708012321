import ShieldIcon from '@mui/icons-material/Shield';
import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import { Title, TitleType } from 'components/Title';
import { useAppSelector } from 'hooks';
import useCompanyInfo from 'hooks/userCompanyInfo';
import { FC, useState, useEffect } from 'react';
import { getUserStockSymbol } from 'store/slices/authSlice';

import styles from './styles.module.scss';

export const CompanyInfo: FC<{ small?: boolean }> = ({ small }) => {
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const { companyInfo, companyLoading } = useCompanyInfo(stockSymbol);
  const [hasErrorImage, setHasErrorImage] = useState(false);

  useEffect(() => {
    setHasErrorImage(false);
  }, [stockSymbol]);

  return (
    <div className={styles.wrapper}>
      {companyLoading ? (
        <div className={styles.skeleton}>
          <Skeleton variant="rounded" height={32} width={32} />
          <Skeleton variant="text" height={38} width={200} />
        </div>
      ) : (
        <>
          {companyInfo?.image && (
            <div className={styles.logo}>
              {hasErrorImage ? (
                <ShieldIcon />
              ) : (
                <img src={companyInfo.image} alt="KOIOS" onError={() => setHasErrorImage(true)} />
              )}
            </div>
          )}
          {companyInfo?.name && (
            <Title
              type={TitleType.h2}
              nativeStyles={classNames(styles.title, { [styles.smallTitle]: small })}
            >
              <>{companyInfo?.name}</>
            </Title>
          )}
          {companyInfo?.symbol && (
            <div className={classNames(styles.label, { [styles.smallLable]: small })}>
              {companyInfo?.symbol}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CompanyInfo;
