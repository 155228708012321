import classNames from 'classnames';
import InputField, { InputFieldType } from 'components/InputField';
import { useAppSelector } from 'hooks';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { required } from 'services/InputFIeldsValidator/rules';
import { getSelectedTimeZone } from 'store/slices/authSlice';
import { getTimeFrame } from 'utils';

import styles from '../styles.module.scss';

const formatDate = (date: string) => {
  return new Date(date.slice(0, -6));
};

interface IInfoFormProps {
  control: any;
  register: any;
  errors: any;
  watch: any;
  setValue: any;
  setError: any;
}

const ComparisonForm: React.FC<IInfoFormProps> = ({
  control,
  register,
  errors,
  setValue,
  watch,
  setError,
}) => {
  const { t } = useTranslation();
  const minDate = DateTime.now().startOf('month').minus({ month: 3 }).toJSDate();
  const zone = useAppSelector(getSelectedTimeZone);
  const firstPeriod = watch('firstPeriod') || [];
  const secondPeriod = watch('secondPeriod') || [];

  const onFirstPeriodChange = (fromDate: string, toDate: string) => {
    setValue('firstPeriodTimeFrame', getTimeFrame(fromDate, toDate, zone));
    setValue('firstPeriod', [fromDate, toDate]);
    setError('firstPeriodTimeFrame', null);
    setValue('secondPeriod', []);
    setValue('secondPeriodTimeFrame', '');
  };

  const onSecondPeriodChange = (fromDate: string, toDate: string) => {
    setValue('secondPeriodTimeFrame', getTimeFrame(fromDate, toDate, zone));
    setValue('secondPeriod', [fromDate, toDate]);
    setError('secondPeriodTimeFrame', null);
  };

  const secondRangeConfig = useMemo(() => {
    if (!firstPeriod.length) {
      return {
        maxDate: undefined,
        minDate,
      };
    }

    const [from, to] = firstPeriod;
    return {
      minDate: formatDate(
        DateTime.fromISO(from).toUTC().setZone(zone).minus({ month: 3 }).toString()
      ),
      maxDate: formatDate(
        DateTime.fromISO(from).toUTC().setZone(zone).minus({ day: 1 }).toString()
      ),
    };
  }, [firstPeriod, zone, minDate]);

  return (
    <>
      <div className={styles.field}>
        <InputField
          label={t('pages.reports.modal.fields.reportName')}
          control={control}
          defaultValue=""
          name="name"
          type={InputFieldType.text}
          register={register}
          placeholder=""
          validate={{
            required,
          }}
          errors={errors.name}
        />
      </div>
      <div className={styles.field}>
        <InputField
          name="stock"
          register={register}
          control={control}
          label={t('pages.reports.modal.fields.stock')}
          errors={errors.stock}
          required
          defaultValue=""
          type={InputFieldType.text}
          validate={{}}
          disabled
          placeholder=""
        />
      </div>
      <div className={classNames(styles.field, styles.rowFields)}>
        <InputField
          autocomplete={false}
          rangePickerConfig={{
            minDate,
            initialRange: firstPeriod.map(formatDate),
            onRangeChange: onFirstPeriodChange,
          }}
          label={t('pages.reports.modal.fields.firstPeriod')}
          control={control}
          register={register}
          name="firstPeriodTimeFrame"
          defaultValue=""
          errors={errors.firstPeriodTimeFrame}
          placeholder=""
          type={InputFieldType.text}
          validate={{ required }}
        />
        <InputField
          disabled={firstPeriod.length === 0}
          autocomplete={false}
          rangePickerConfig={{
            minDate: secondRangeConfig.minDate,
            maxDate: secondRangeConfig.maxDate,
            initialRange: secondPeriod.map(formatDate),
            onRangeChange: onSecondPeriodChange,
          }}
          label={t('pages.reports.modal.fields.secondPeriod')}
          control={control}
          register={register}
          name="secondPeriodTimeFrame"
          defaultValue=""
          errors={errors.secondPeriodTimeFrame}
          placeholder=""
          type={InputFieldType.text}
          validate={{ required }}
        />
      </div>
    </>
  );
};

export default ComparisonForm;
