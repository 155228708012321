import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { ITopPostResponse } from 'modules/Conversation/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ChangeSentimentPopup from './ChangeSentimentPopup';
import DeleteConversationPopup from './DeleteConversationPopup';
import styles from './styles.module.scss';

type PopupTypes = 'sentiment' | 'deletion';

interface IContextMenuProps {
  post: ITopPostResponse;
  onChangedSentimentOrDeleted: () => void;
}

const ContextMenu = (props: IContextMenuProps) => {
  const { post, onChangedSentimentOrDeleted } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openedPopup, setOpenedPopup] = useState<null | PopupTypes>(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onOpenPopup = (type: PopupTypes) => {
    return () => {
      setOpenedPopup(type);
    };
  };

  const onClosePopup = () => {
    setOpenedPopup(null);
  };

  return (
    <>
      <IconButton size="small" onClick={handleMenuClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem className={styles.menuItem} dense={true} onClick={onOpenPopup('sentiment')}>
          <SwitchLeftIcon />
          {t('posts.changeSentiment')}
        </MenuItem>
        <MenuItem className={styles.menuItem} dense={true} onClick={onOpenPopup('deletion')}>
          <DeleteIcon />
          {t('posts.delete')}
        </MenuItem>
      </Menu>
      {openedPopup === 'sentiment' && (
        <ChangeSentimentPopup
          post={post}
          onClose={onClosePopup}
          onChangedSentimentOrDeleted={onChangedSentimentOrDeleted}
        />
      )}
      {openedPopup === 'deletion' && (
        <DeleteConversationPopup
          postId={post._id}
          onClose={onClosePopup}
          onChangedSentimentOrDeleted={onChangedSentimentOrDeleted}
        />
      )}
    </>
  );
};

export default ContextMenu;
