import InputField, { InputFieldType } from 'components/InputField';
import { SearchIcon } from 'components/SvgComponents';
import useOutsideClick from 'hooks/useOutsideClick';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import { fetchWithConfig } from 'utils';

import { SuggestionsList } from './List';
import styles from './styles.module.scss';

type FormValues = {
  query: string;
};

interface IAutocompleteListProps {
  placeholder: string;
  url: string;
  formatSuggestion: (data: any) => any;
  onClick: (value: string, suggestions: any) => void;
  closeOnSelect?: boolean;
  autocompleteInput?: boolean;
  queryOptions?: Record<string, any>;
  searchKey?: string;
}

export const AutoCompleteList: React.FC<IAutocompleteListProps> = ({
  placeholder,
  url,
  formatSuggestion,
  onClick = () => {},
  closeOnSelect,
  autocompleteInput = true,
  queryOptions = {},
  searchKey = 'query',
}) => {
  const {
    register,
    formState: { errors },
    control,
    watch,
  } = useForm<FormValues>();

  const search = watch('query');
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [responseData, setResponseData] = useState<any>(null);
  const [isShowList, setIsShowList] = useState(false);

  let searchTimeout = useRef<any>(null);

  const throttleSetQuery = (value: string) => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    if (!value) return setQuery('');

    searchTimeout.current = setTimeout(() => {
      setQuery(value);
    }, 1000);
  };

  useEffect(() => {
    throttleSetQuery(search);
  }, [search]);

  const { data, isLoading } = useSWR(
    query ? [`${url}`, { [searchKey]: query, ...queryOptions }] : null,
    (options: [string, Record<string, any>]) =>
      fetchWithConfig({
        url: options[0],
        params: options[1],
      })
  );

  useEffect(() => {
    if (!data) {
      return setSuggestions([]);
    }

    const suggestionsData = formatSuggestion(data as unknown as any[]);

    setResponseData(data);
    setSuggestions(suggestionsData);
  }, [data, formatSuggestion]);

  const onClose = () => {
    setIsShowList(false);
  };

  const onItemClick = (value: string) => {
    if (closeOnSelect) {
      onClose();
    }

    onClick(value, responseData);
  };

  const ref = useOutsideClick(onClose);

  return (
    <div
      className={styles.wrapper}
      /*@ts-ignore*/
      ref={ref}
    >
      <InputField
        autocomplete={autocompleteInput}
        noFocus
        onFocus={() => {
          setIsShowList(true);
        }}
        className={isLoading || (suggestions.length && isShowList) ? styles.inputWithSearch : ''}
        LeftIcon={SearchIcon}
        register={register}
        name="query"
        control={control}
        defaultValue=""
        errors={errors.query}
        placeholder={placeholder}
        type={InputFieldType.text}
        validate={{}}
      />
      <SuggestionsList
        isShow={isShowList}
        suggestions={suggestions}
        loading={isLoading}
        onClick={onItemClick}
      />
    </div>
  );
};
