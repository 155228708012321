import { Typography } from '@mui/material';
import { Title, TitleType } from 'components';
import { useAppSelector } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getUser } from 'store/slices/authSlice';

import Form from './Form';
import styles from './styles.module.scss';

const ConfigurePopup = () => {
  const { t } = useTranslation();
  const user = useAppSelector(getUser);
  const { selectedStock } = user;

  return (
    <div className={styles.wrapper}>
      <Title nativeStyles={styles.title} type={TitleType.h1}>
        {t('pages.competitiveAnalysis.configurePopup.title')}
      </Title>
      <Title nativeStyles={styles.subTitle} type={TitleType.h3}>
        {t('pages.competitiveAnalysis.configurePopup.stockCompareTitle')}
      </Title>
      <Typography variant="body2">
        {t('pages.competitiveAnalysis.configurePopup.competitorsHint', {
          stock: selectedStock.stock,
        })}
      </Typography>

      <Form />
    </div>
  );
};

export default ConfigurePopup;
