interface IWhiteLabelConfig {
  alias: string;
  logo: string;
  loginImage: string;
  metadata: {
    title: string;
    favicon: string;
    description: string;
  };
}

export const koiosConfig: IWhiteLabelConfig = {
  alias: 'koios',
  logo: require('./resources/images/logos/koios.png'),
  loginImage: require('./resources/images/koios-animation.mp4'),
  metadata: {
    title: 'KOIOS',
    favicon: require('./resources/images/favicons/koios.png'),
    description: 'KOIOS application',
  },
};

export const dezenhallConfig: IWhiteLabelConfig = {
  alias: 'dezenhall',
  logo: require('./resources/images/logos/dezenhall.png'),
  loginImage: require('./resources/images/dezenhall-login.png'),
  metadata: {
    title: 'Dezenhall',
    favicon: require('./resources/images/favicons/dezenhall.png'),
    description: 'Dezenhall application',
  },
};

export const reevemarkConfig: IWhiteLabelConfig = {
  alias: 'reevemark',
  logo: require('./resources/images/logos/reevemark.png'),
  loginImage: require('./resources/images/reevemark-login.jpg'),
  metadata: {
    title: 'Reevemark',
    favicon: require('./resources/images/favicons/reevemark.png'),
    description: 'Reevemark application',
  },
};

const whiteLabelConfig: Record<string, IWhiteLabelConfig> = {
  'test.koios.live': reevemarkConfig,
  'app.koios.live': koiosConfig,
  'staging.koios.live': koiosConfig,
  'app.dezenhall.com': dezenhallConfig,
  'warroom.reevemark.com': reevemarkConfig,
};

export default whiteLabelConfig;
