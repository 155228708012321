import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import classNames from 'classnames';
import { Layout } from 'components';
import { useStockNews } from 'pages/NewsRoom/useStockNews';
import { DateTime } from 'luxon';
import StockFilterHeader from 'modules/StockFilterHeader';
import StockNews, { StockNewsRef } from './StockNews';
import { FC, useEffect, useRef } from 'react';
import { Doughnut, Line, Pie } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedTimeZone, getUserStockSymbol } from 'store/slices/authSlice';
import { setDateRangeFilter } from 'store/slices/filtersSlice';
import { FiltersDateRangeType } from 'types';
import { getStaticTimeRange } from 'utils';
import BackToTop from 'pages/NewsRoom/BackToTop';

import styles from './styles.module.scss';
import StockNewsTimeline from './StockNewsTimeline';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const NewsRoom: FC<{ blurContent?: boolean }> = ({ blurContent }) => {
  const dispatch = useDispatch();
  const selectedTimeZone = useSelector(getSelectedTimeZone);
  const selectedStock = useSelector(getUserStockSymbol);
  const { newsData, newsMetrics, sourceDistribution, isLoading } = useStockNews();
  const newsRef = useRef<StockNewsRef>(null);

  const getDateRangeInfo = () => {
    if (!newsData.length) return '';

    const dates = newsData.map(news => DateTime.fromISO(news.published_utc));
    const firstDate = DateTime.min(...dates);
    const lastDate = DateTime.max(...dates);

    return `${firstDate.toFormat('LLL dd, yyyy')} - ${lastDate.toFormat('LLL dd, yyyy')}`;
  };

  useEffect(() => {
    const filterType: FiltersDateRangeType = FiltersDateRangeType.m1;

    const result = getStaticTimeRange(filterType, selectedTimeZone);
    dispatch(setDateRangeFilter({ type: filterType, ...result }));
  }, [dispatch, selectedTimeZone]);

  const renderSentimentChart = () => {
    if (!newsMetrics || newsMetrics.length === 0) {
      return <div>No sentiment data available</div>;
    }

    // Sort metrics by date in ascending order
    const sortedMetrics = [...newsMetrics].sort(
      (a, b) => DateTime.fromISO(a.timestamp).toMillis() - DateTime.fromISO(b.timestamp).toMillis()
    );

    const chartData = {
      labels: sortedMetrics.map(metric => DateTime.fromISO(metric.timestamp).toFormat('LLL dd')),
      datasets: [
        {
          label: '',
          data: sortedMetrics.map(metric => metric.sentiment),
          segment: {
            borderColor: ctx => {
              const yAxis = ctx.chart.scales.y;
              const gradient = ctx.chart.ctx.createLinearGradient(
                0,
                yAxis.getPixelForValue(0.4),
                0,
                yAxis.getPixelForValue(-0.4)
              );

              if (
                ctx.p0.parsed.y >= -0.05 &&
                ctx.p0.parsed.y <= 0.05 &&
                ctx.p1.parsed.y >= -0.05 &&
                ctx.p1.parsed.y <= 0.05
              ) {
                return '#94a3b8'; // Both points in neutral range, solid gray
              } else if (ctx.p0.parsed.y >= 0 && ctx.p1.parsed.y >= 0) {
                return '#22c55e'; // Both points above 0, solid green
              } else if (ctx.p0.parsed.y < 0 && ctx.p1.parsed.y < 0) {
                return '#ef4444'; // Both points below 0, solid red
              } else {
                // One point above, one below, create gradient
                gradient.addColorStop(0, '#22c55e');
                gradient.addColorStop(0.5, '#22c55e');
                gradient.addColorStop(0.5, '#ef4444');
                gradient.addColorStop(1, '#ef4444');
                return gradient;
              }
            },
          },
          borderWidth: 2,
          tension: 0.4,
          pointRadius: 0,
          pointHoverRadius: 6,
          pointBackgroundColor: (context: any) => {
            const value = context.raw;
            if (value > 0) return '#22c55e';
            if (value < 0) return '#ef4444';
            return '#94a3b8'; // gray for 0
          },
          pointBorderColor: (context: any) => {
            const value = context.raw;
            if (value > 0) return '#22c55e';
            if (value < 0) return '#ef4444';
            return '#94a3b8';
          },
          pointHoverBackgroundColor: (context: any) => {
            const value = context.raw;
            if (value > 0) return '#22c55e';
            if (value < 0) return '#ef4444';
            return '#94a3b8';
          },
          pointHoverBorderColor: (context: any) => {
            const value = context.raw;
            if (value > 0) return '#22c55e';
            if (value < 0) return '#ef4444';
            return '#94a3b8';
          },
          pointHoverBorderWidth: 2,
          spanGaps: true,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      hover: {
        intersect: false,
        mode: 'nearest' as const,
      },
      onClick: (event: any, elements: any[]) => {
        if (elements.length > 0) {
          const index = elements[0].index;
          const clickedDate = sortedMetrics[index].timestamp;
          console.log('Clicked date:', clickedDate);

          // Find the first news item from this date
          const newsFromDate = newsData.find(
            news => DateTime.fromISO(news.published_utc).toFormat('yyyy-MM-dd') === clickedDate
          );

          if (newsFromDate) {
            console.log('Scrolling to news with date:', newsFromDate.published_utc);
            newsRef.current?.scrollToNews(newsFromDate.published_utc);
          }
        }
      },
      plugins: {
        title: {
          display: true,
          text: `Daily Average Sentiment Analysis for ${selectedStock}`,
          color: '#f6f7f8',
        },
        legend: {
          display: true,
          labels: {
            color: '#f6f7f8',
          },
        },
        tooltip: {
          enabled: true,
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          titleColor: '#f6f7f8',
          bodyColor: '#f6f7f8',
          intersect: false,
          mode: 'nearest' as const,
          usePointStyle: true,
          boxPadding: 4,
          callbacks: {
            title: (context: any) => {
              const date = DateTime.fromISO(sortedMetrics[context[0].dataIndex].timestamp);
              return date.toFormat('LLL dd, yyyy');
            },
            label: (context: any) => {
              const value = sortedMetrics[context.dataIndex].sentiment;
              let sentiment;
              if (value >= 0.35) sentiment = 'Bullish';
              else if (value >= 0.15) sentiment = 'Somewhat-Bullish';
              else if (value > -0.15) sentiment = 'Neutral';
              else if (value > -0.35) sentiment = 'Somewhat-Bearish';
              else sentiment = 'Bearish';
              return [
                `Average Sentiment: ${sentiment} (${value.toFixed(3)})`,
                `Articles: ${sortedMetrics[context.dataIndex].count}`,
              ];
            },
          },
        },
      },
      scales: {
        y: {
          type: 'linear' as const,
          min: -0.4,
          max: 0.4,
          grid: {
            color: 'rgba(246, 247, 248, 0.1)',
          },
          ticks: {
            color: '#f6f7f8',
            callback: function (this: any, tickValue: number | string) {
              const value = Number(tickValue);
              if (value === 0.35) return 'Bullish';
              if (value === 0.15) return 'Somewhat-Bullish';
              if (value === 0) return 'Neutral';
              if (value === -0.15) return 'Somewhat-Bearish';
              if (value === -0.35) return 'Bearish';
              return value.toFixed(2);
            },
            // Include specific values we want to show on the y-axis
            values: [-0.35, -0.15, 0, 0.15, 0.35],
          },
          title: {
            display: true,
            text: 'Sentiment Score',
            color: '#f6f7f8',
          },
        },
        x: {
          type: 'category' as const,
          grid: {
            color: 'rgba(246, 247, 248, 0.1)',
          },
          ticks: {
            color: '#f6f7f8',
          },
        },
      },
    };

    return (
      <div
        style={{
          backgroundColor: '#101828',
          padding: '20px',
          borderRadius: '8px',
          height: '400px',
        }}
      >
        <Line data={chartData} options={options} />
      </div>
    );
  };

  const renderSourceDistributionDoughnut = () => {
    const chartData = {
      labels: sourceDistribution.map(item => item.source),
      datasets: [
        {
          data: sourceDistribution.map(item => item.count),
          backgroundColor: [
            '#2563eb', // Blue
            '#dc2626', // Red
            '#16a34a', // Green
            '#9333ea', // Purple
            '#ea580c', // Orange
            '#0891b2', // Cyan
            '#ca8a04', // Yellow
            '#be185d', // Pink
            '#1d4ed8', // Royal Blue
            '#15803d', // Forest Green
            '#7c2d12', // Brown
            '#4f46e5', // Indigo
            '#b91c1c', // Dark Red
            '#c026d3', // Magenta
            '#059669', // Emerald
            '#854d0e', // Golden Brown
            '#6d28d9', // Violet
            '#0369a1', // Sky Blue
            '#92400e', // Burnt Orange
            '#475569', // Slate
          ],
          borderColor: '#101828',
          borderWidth: 2,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 20,
        },
      },
      plugins: {
        title: {
          display: true,
          text: 'News Sources Distribution',
          color: '#f6f7f8',
          font: {
            size: 16,
            weight: 'bold' as const,
          },
          padding: {
            bottom: 10,
          },
        },
        legend: {
          display: true,
          position: 'right' as const,
          align: 'center' as const,
          labels: {
            color: '#f6f7f8',
            padding: 10,
            boxWidth: 15,
            font: {
              size: 11,
            },
          },
        },
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          titleColor: '#f6f7f8',
          bodyColor: '#f6f7f8',
        },
      },
    };

    return (
      <div
        style={{
          backgroundColor: '#101828',
          padding: '20px',
          borderRadius: '8px',
          width: '48%',
          minHeight: '400px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ flex: 1, minHeight: 0 }}>
          <Doughnut data={chartData} options={options} />
        </div>
      </div>
    );
  };

  const renderSentimentDistributionPie = () => {
    // Calculate sentiment distribution
    const sentimentCounts = newsData.reduce((acc: { [key: string]: number }, news) => {
      const stockInsight = news.insights.find(insight => insight.ticker === selectedStock);
      if (!stockInsight) return acc;

      const score = stockInsight.sentiment_score;
      let sentiment;
      if (score >= 0.35) sentiment = 'Bullish';
      else if (score >= 0.15) sentiment = 'Somewhat-Bullish';
      else if (score > -0.15) sentiment = 'Neutral';
      else if (score > -0.35) sentiment = 'Somewhat-Bearish';
      else sentiment = 'Bearish';

      acc[sentiment] = (acc[sentiment] || 0) + 1;
      return acc;
    }, {});

    const chartData = {
      labels: ['Bullish', 'Somewhat-Bullish', 'Neutral', 'Somewhat-Bearish', 'Bearish'],
      datasets: [
        {
          data: [
            sentimentCounts['Bullish'] || 0,
            sentimentCounts['Somewhat-Bullish'] || 0,
            sentimentCounts['Neutral'] || 0,
            sentimentCounts['Somewhat-Bearish'] || 0,
            sentimentCounts['Bearish'] || 0,
          ],
          backgroundColor: ['#22c55e', '#86efac', '#94a3b8', '#fca5a5', '#ef4444'],
          borderColor: '#101828',
          borderWidth: 2,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 20,
        },
      },
      plugins: {
        title: {
          display: true,
          text: 'Sentiment Distribution',
          color: '#f6f7f8',
          font: {
            size: 16,
            weight: 'bold' as const,
          },
          padding: {
            bottom: 10,
          },
        },
        legend: {
          display: true,
          position: 'right' as const,
          align: 'center' as const,
          labels: {
            color: '#f6f7f8',
            padding: 10,
            boxWidth: 15,
            font: {
              size: 11,
            },
          },
        },
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          titleColor: '#f6f7f8',
          bodyColor: '#f6f7f8',
        },
      },
    };

    return (
      <div
        style={{
          backgroundColor: '#101828',
          padding: '20px',
          borderRadius: '8px',
          width: '48%',
          minHeight: '400px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ flex: 1, minHeight: 0 }}>
          <Pie data={chartData} options={options} />
        </div>
      </div>
    );
  };

  return (
    <Layout blurContent={blurContent}>
      <div className={classNames(styles.wrapper, styles.topWrapper)}>
        <StockFilterHeader />
        <div className={styles.titleSection}>
          <h1 className={styles.title}>Web Overview</h1>
          <div className={styles.subtitle}>
            <span>{newsData.length} news articles</span>
            <span className={styles.dateRange}>{getDateRangeInfo()}</span>
          </div>
        </div>
        {isLoading ? (
          <div>Loading news metrics...</div>
        ) : (
          <>
            <div className={styles.chartContainer}>{renderSentimentChart()}</div>
            <div
              className={styles.chartContainer}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              {renderSourceDistributionDoughnut()}
              {renderSentimentDistributionPie()}
            </div>
          </>
        )}
        {/* <StockNews ref={newsRef} /> */}
        <StockNewsTimeline ref={newsRef} />
        <BackToTop />
      </div>
    </Layout>
  );
};

export default NewsRoom;
