import * as React from 'react';
import { SVGProps } from 'react';

export const CompetitiveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.5">
      <path
        d="M21.7099 7.50088C21.617 7.40715 21.5064 7.33275 21.3845 7.28199C21.2627 7.23122 21.132 7.20508 20.9999 7.20508C20.8679 7.20508 20.7372 7.23122 20.6154 7.28199C20.4935 7.33275 20.3829 7.40715 20.2899 7.50088L13.9999 13.8009L9.70994 9.50088C9.61698 9.40715 9.50637 9.33275 9.38452 9.28199C9.26266 9.23122 9.13195 9.20508 8.99994 9.20508C8.86793 9.20508 8.73722 9.23122 8.61536 9.28199C8.4935 9.33275 8.3829 9.40715 8.28994 9.50088L2.28994 15.5009C2.19621 15.5938 2.12182 15.7044 2.07105 15.8263C2.02028 15.9482 1.99414 16.0789 1.99414 16.2109C1.99414 16.3429 2.02028 16.4736 2.07105 16.5955C2.12182 16.7173 2.19621 16.8279 2.28994 16.9209C2.3829 17.0146 2.4935 17.089 2.61536 17.1398C2.73722 17.1905 2.86793 17.2167 2.99994 17.2167C3.13195 17.2167 3.26266 17.1905 3.38452 17.1398C3.50637 17.089 3.61698 17.0146 3.70994 16.9209L8.99994 11.6209L13.2899 15.9209C13.3829 16.0146 13.4935 16.089 13.6154 16.1398C13.7372 16.1905 13.8679 16.2167 13.9999 16.2167C14.132 16.2167 14.2627 16.1905 14.3845 16.1398C14.5064 16.089 14.617 16.0146 14.7099 15.9209L21.7099 8.92088C21.8037 8.82791 21.8781 8.71731 21.9288 8.59545C21.9796 8.47359 22.0057 8.34289 22.0057 8.21088C22.0057 8.07886 21.9796 7.94816 21.9288 7.8263C21.8781 7.70444 21.8037 7.59384 21.7099 7.50088Z"
        fill="white"
      />
    </g>
  </svg>
);
