import classNames from 'classnames';
import React from 'react';

import { UserAvaType } from '../../types';
import { CrownIcon } from '../SvgComponents';
import { Title, TitleType } from '../Title';
import styles from './styles.module.scss';

type UserData = {
  name: string;
  src: string;
  bottomLabel?: string | React.ReactElement;
  Icon?: React.ReactElement;
};
type UserAvaProps = { type: UserAvaType; data: UserData; size?: number; withCrown?: boolean };
export const UserAva: React.FC<UserAvaProps> = ({ type, data, size = 34, withCrown = false }) => {
  const { src, bottomLabel, name, Icon } = data;
  return (
    <div className={styles.wrapper}>
      <div className={styles.relative}>
        {withCrown && <CrownIcon className={styles.crownIcon} />}
        <div
          className={classNames(
            styles.ava,
            {
              [styles.border]: withCrown,
              [styles.withBorder]: type === UserAvaType.topUser,
            },
            'avatar'
          )}
          style={{ width: size, height: size }}
        >
          {Icon ? Icon : <img src={src} alt={name || ''} />}
        </div>
      </div>
      <div>
        <Title type={TitleType.h5}>
          <>{name}</>
        </Title>
        {bottomLabel && (
          <p
            className={classNames(styles.label, {
              [styles.nickName]: type === UserAvaType.topUser,
            })}
          >
            {bottomLabel}
          </p>
        )}
      </div>
    </div>
  );
};
