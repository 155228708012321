import LoginForm from 'components/AuthComponents/FormColumn/LoginForm';
import { API_ROUTES } from 'const';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AuthLayout } from '../../../components';

export enum AuthFormType {
  login = 'login',
  signUp = 'signUp',
  confirm = 'confirm',
}

const formHeadingConfig: Record<string, any> = {
  login: {
    title: 'auth.login',
    subtitle: 'auth.loginSubtitle',
    fetchRout: API_ROUTES.LOGIN,
  },
};

const FormColumn: React.FC<{ authType: AuthFormType }> = ({ authType }) => {
  const { t } = useTranslation();

  const headingLabels: Record<string, any> = formHeadingConfig[authType];

  return (
    <AuthLayout formTitle={t(headingLabels.title)} formSubtitle={t(headingLabels.subtitle)}>
      <LoginForm fetchRout={headingLabels.fetchRout} />
    </AuthLayout>
  );
};

export default FormColumn;
