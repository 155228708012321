import { Card } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

export const SectionCard: React.FC<{
  children: React.ReactNode;
  nativeClassName?: string;
  noBorder?: boolean;
}> = ({ children, nativeClassName, noBorder }) => {
  return (
    <Card
      className={classNames(styles.card, nativeClassName && nativeClassName, {
        [styles.noBorder]: noBorder,
      })}
    >
      {children}
    </Card>
  );
};
