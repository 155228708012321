import React from 'react';
import { APP_ROUTES } from 'const';

import ProfileLayoutWrapper from 'components/ProfileLayout';

const config = [
  {
    title: 'pages.admin.stocks',
    path: APP_ROUTES.RESELLER_STOCKS,
  },
];
const ResellerLayout: React.FC<{
  children: React.ReactNode;
  title?: string;
  createLink?: React.JSX.Element;
}> = ({ children, title, createLink }) => {
  return (
    <ProfileLayoutWrapper
      title={title}
      navConfig={config}
      withBackButton={false}
      createLink={createLink}
    >
      {children}
    </ProfileLayoutWrapper>
  );
};

export default ResellerLayout;
