import * as React from 'react';
import { SVGProps } from 'react';

export const LikeNegativeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.1368 1.61394C11.8779 1.08733 11.465 0.651944 10.9528 0.365624C10.4406 0.079303 9.85335 -0.0444036 9.26917 0.0109367L4.05242 0.590187H1.75C1.28587 0.590187 0.840752 0.774561 0.512563 1.10275C0.184374 1.43094 0 1.87606 0 2.34019L0 8.17352C0 8.63765 0.184374 9.08277 0.512563 9.41096C0.840752 9.73915 1.28587 9.92352 1.75 9.92352H3.79167L6.38633 13.3535C6.5418 13.5547 6.74069 13.7181 6.96816 13.8316C7.19562 13.9452 7.4458 14.0059 7.7 14.0092C8.13118 14.0047 8.54346 13.8316 8.84869 13.527C9.15393 13.2224 9.32787 12.8105 9.33333 12.3794V9.92352H12.25C12.7141 9.92352 13.1592 9.73915 13.4874 9.41096C13.8156 9.08277 14 8.63765 14 8.17352V5.80519C14.0002 5.34883 13.8924 4.8989 13.6856 4.4921L12.1368 1.61394ZM1.16667 8.17352V2.34019C1.16667 2.18548 1.22812 2.0371 1.33752 1.92771C1.44692 1.81831 1.59529 1.75685 1.75 1.75685H3.5V8.75685H1.75C1.59529 8.75685 1.44692 8.6954 1.33752 8.586C1.22812 8.4766 1.16667 8.32823 1.16667 8.17352ZM12.8333 8.17352C12.8333 8.32823 12.7719 8.4766 12.6625 8.586C12.5531 8.6954 12.4047 8.75685 12.25 8.75685H8.75C8.59529 8.75685 8.44692 8.81831 8.33752 8.92771C8.22812 9.0371 8.16667 9.18548 8.16667 9.34019V12.3794C8.16253 12.4762 8.12881 12.5695 8.07006 12.6466C8.0113 12.7237 7.93034 12.7809 7.83805 12.8106C7.74577 12.8403 7.64661 12.841 7.55393 12.8126C7.46124 12.7842 7.37949 12.728 7.31967 12.6518L4.66667 9.14477V1.6956L9.3835 1.1706C9.73496 1.14179 10.0869 1.21984 10.3933 1.3945C10.6996 1.56917 10.946 1.83231 11.1002 2.14944L12.6519 5.0311C12.7725 5.27124 12.8347 5.53647 12.8333 5.80519V8.17352Z"
      fill="#FC471E"
    />
  </svg>
);
