import { AppButton, AutoCompleteList, ButtonsType, Popup } from 'components';
import { API_ROUTES } from 'const';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormErrorsLabel } from 'components/FormErrorsLabel';
import ToastifyService from 'services/ToastifyService';
import { updateTeamStockKitsLimit } from 'store/slices/authSlice';
import { catchRequestErrors, fetchWithConfig } from 'utils';
import { useSWRConfig } from 'swr';

import styles from './styles.module.scss';

interface ISuggestionStock {
  name: string;
  symbol: string;
  exchangeName: string;
}

interface ICreateStockPopup {
  onClose: () => void;
}

type FormValues = {
  stock: string;
};

const CreateStockPopup: React.FC<ICreateStockPopup> = ({ onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { mutate } = useSWRConfig();
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      stock: '',
    },
  });

  const [selectedStock, setSelectedStock] = useState<ISuggestionStock | undefined>(undefined);

  useEffect(() => {
    register('stock', { required: 'Stock is required' });
  }, []);

  const formatSuggestion = useCallback((data: ISuggestionStock[]) => {
    return data.map(item => ({
      value: item.symbol,
      name: `${item.name} (${item.symbol})`,
    }));
  }, []);

  const onSuggestionClick = (symbol: string, items: ISuggestionStock[]) => {
    setSelectedStock(items.find(item => item.symbol === symbol));
    clearErrors();
    setValue('stock', symbol);
  };

  const onCloseForm = () => {
    reset();
    onClose();
  };

  const onSubmit = async (data: FormValues) => {
    try {
      setLoading(true);
      const res = await fetchWithConfig({
        url: API_ROUTES.RESELLER_STOCK_KITS,
        method: 'POST',
        data,
      });

      ToastifyService.setToast(res.message as string, 'success');
      setLoading(false);
      mutate([API_ROUTES.RESELLER_STOCK_KITS, { page: 1, take: 10 }]);
      dispatch(updateTeamStockKitsLimit('increase'));
      onCloseForm();
    } catch (error: any) {
      catchRequestErrors(error, setError, () => {}, setLoading);
    }
  };

  return (
    <>
      <Popup
        title={t('pages.reseller.stockKits.create')}
        fullWidth
        onClose={onCloseForm}
        visibleOverflow
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <AutoCompleteList
            closeOnSelect
            autocompleteInput={false}
            onClick={onSuggestionClick}
            url={API_ROUTES.STOCKS_SUGGESTIONS}
            placeholder={t('pages.competitiveAnalysis.configurePopup.searchPlaceholder')}
            formatSuggestion={formatSuggestion}
          />
          <FormErrorsLabel message={errors.stock?.message} />
          {selectedStock && (
            <ul className={styles.selectedStockListProp}>
              <li>Symbol: {selectedStock.symbol}</li>
              <li>Company Name: {selectedStock.name}</li>
              <li>Stock Exchange: {selectedStock.exchangeName}</li>
            </ul>
          )}
          <div className={styles.dialogActions}>
            <AppButton isLoading={loading} btnType={ButtonsType.simpleSave} label="Submit" />
          </div>
        </form>
      </Popup>
    </>
  );
};

export default CreateStockPopup;
