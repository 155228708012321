import AddAlertIcon from '@mui/icons-material/AddAlert';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import React from 'react';
import ProfileLayout, { IProfileLayoutProps } from 'components/ProfileLayout';

import { APP_ROUTES } from '../../../const';

const config = [
  {
    title: 'pages.admin.homeTitle',
    path: APP_ROUTES.HOME,
    icon: HomeIcon,
  },
  {
    title: 'pages.admin.stocks',
    path: APP_ROUTES.STOCKS,
    icon: ShowChartIcon,
  },
  {
    title: 'pages.admin.stockKits',
    path: APP_ROUTES.STOCK_KITS,
    icon: StackedLineChartIcon,
  },
  {
    title: 'pages.admin.teams',
    path: APP_ROUTES.TEAMS,
    icon: Diversity3Icon,
  },
  {
    title: 'pages.admin.users',
    path: APP_ROUTES.USERS,
    icon: PersonIcon,
  },
  {
    title: 'pages.admin.usersBlackList.title',
    path: APP_ROUTES.BLACKLIST_USERS,
    icon: GroupRemoveIcon,
  },
  {
    title: 'pages.admin.analystAlert.title',
    path: APP_ROUTES.ANALYST_ALERT_CREATE,
    icon: AddAlertIcon,
  },
];

type DashboardLayoutProps = Omit<IProfileLayoutProps, 'navConfig'>;

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children,
  createLink,
  title,
  onSubmit,
  actionBtn,
}) => {
  return (
    <ProfileLayout
      createLink={createLink}
      navConfig={config}
      title={title}
      onSubmit={onSubmit}
      actionBtn={actionBtn}
    >
      {children}
    </ProfileLayout>
  );
};

export default DashboardLayout;
