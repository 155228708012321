import { FC } from 'react';
import { SVGProps } from 'react';

export const ActionBadAccountIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.5" clipPath="url(#clip0_1894_1618)">
      <path
        d="M31.425 16.5H33.75V19.5H31.425C30.75 25.755 25.755 30.75 19.5 31.425V33.75H16.5V31.425C10.245 30.75 5.25 25.755 4.575 19.5H2.25V16.5H4.575C5.25 10.245 10.245 5.25 16.5 4.575V2.25H19.5V4.575C25.755 5.25 30.75 10.245 31.425 16.5ZM7.605 16.5H9.75V19.5H7.605C7.92377 21.7462 8.96351 23.8281 10.5677 25.4323C12.1719 27.0365 14.2538 28.0762 16.5 28.395V26.25H19.5V28.395C21.7462 28.0762 23.8281 27.0365 25.4323 25.4323C27.0365 23.8281 28.0762 21.7462 28.395 19.5H26.25V16.5H28.395C28.0762 14.2538 27.0365 12.1719 25.4323 10.5677C23.8281 8.96351 21.7462 7.92377 19.5 7.605V9.75H16.5V7.605C14.2538 7.92377 12.1719 8.96351 10.5677 10.5677C8.96351 12.1719 7.92377 14.2538 7.605 16.5ZM24 24H12V22.5C12 20.505 16.005 19.5 18 19.5C19.995 19.5 24 20.505 24 22.5V24ZM18 12C18.7956 12 19.5587 12.3161 20.1213 12.8787C20.6839 13.4413 21 14.2044 21 15C21 15.7956 20.6839 16.5587 20.1213 17.1213C19.5587 17.6839 18.7956 18 18 18C17.2044 18 16.4413 17.6839 15.8787 17.1213C15.3161 16.5587 15 15.7956 15 15C15 14.2044 15.3161 13.4413 15.8787 12.8787C16.4413 12.3161 17.2044 12 18 12Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1894_1618">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
