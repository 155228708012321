import { DateTime } from 'luxon';
import { useMemo } from 'react';

import {
  getFilterDateRangeType,
  getFiltersDataRangeLoadingState,
  getFiltersDateRangeFrom,
  getFiltersDateRangeTo,
} from '../store/slices/filtersSlice';
import { DatePickerFilterConfig, FiltersDateRangeType } from '../types';
import { useAppSelector } from './index';

type useHomeDateFiltersProps = {
  onClick: (type: FiltersDateRangeType) => void;
  onClose: () => void;
};

const formatDate = (date: string) => {
  return new Date(date.slice(0, -6));
};

const useDateFilters = (props: useHomeDateFiltersProps) => {
  const { onClose, onClick } = props;
  const activeFilterType = useAppSelector(getFilterDateRangeType);
  const loading = useAppSelector(getFiltersDataRangeLoadingState);
  const filterFrom = useAppSelector(getFiltersDateRangeFrom) as string;
  const filterTo = useAppSelector(getFiltersDateRangeTo) as string;
  const minDate = DateTime.now().minus({ month: 3 }).toJSDate();

  const initialRange = useMemo(() => {
    return [formatDate(filterFrom), formatDate(filterTo)];
  }, [filterFrom, filterTo]);

  const filterConfig: DatePickerFilterConfig[] = useMemo(
    () => [
      {
        label: '24h',
        type: FiltersDateRangeType.h24,
        isActive: activeFilterType === FiltersDateRangeType.h24,
        onClick: () => {
          if (loading) {
            return;
          }

          onClick(FiltersDateRangeType.h24);
        },
        onClose: onClose,
      },
      {
        label: '48h',
        type: FiltersDateRangeType.h48,
        isActive: activeFilterType === FiltersDateRangeType.h48,
        onClick: () => {
          if (loading) {
            return;
          }
          onClick(FiltersDateRangeType.h48);
        },
        onClose: onClose,
      },
      {
        label: '72h',
        type: FiltersDateRangeType.h72,
        isActive: activeFilterType === FiltersDateRangeType.h72,
        onClick: () => {
          if (loading) {
            return;
          }
          onClick(FiltersDateRangeType.h72);
        },
        onClose: onClose,
      },
      {
        label: '1 week',
        type: FiltersDateRangeType.w1,
        isActive: activeFilterType === FiltersDateRangeType.w1,
        onClick: () => {
          if (loading) {
            return;
          }
          onClick(FiltersDateRangeType.w1);
        },
        onClose: onClose,
      },
      {
        label: '1 month',
        type: FiltersDateRangeType.m1,
        isActive: activeFilterType === FiltersDateRangeType.m1,
        onClick: () => {
          if (loading) {
            return;
          }
          onClick(FiltersDateRangeType.m1);
        },
        onClose: onClose,
      },
      {
        label: '',
        type: FiltersDateRangeType.range,
        isActive: activeFilterType === FiltersDateRangeType.range,
        onClick: () => {
          if (loading) {
            return;
          }
          onClick(FiltersDateRangeType.range);
        },
        onClose: onClose,
      },
    ],
    [activeFilterType, loading, onClick, onClose]
  );

  return {
    filterConfig,
    initialRange,
    minDate,
  };
};

export default useDateFilters;
