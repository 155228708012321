import useSWR from 'swr';

import { API_ROUTES } from '../const';
import { ITeam } from '../types';
import { fetchWithConfig } from '../utils';

const useTeams = () => {
  const { data, error, isLoading } = useSWR<Partial<ITeam>[]>(
    API_ROUTES.SELECT_OPTION_TEAMS,
    (url: string) => fetchWithConfig<Partial<ITeam>[]>({ url })
  );
  return {
    teamsData: data as Partial<ITeam>[],
    teamsError: error as any,
    teamsLoading: isLoading as boolean,
  };
};

export default useTeams;
