import { FormErrorsLabel } from 'components/FormErrorsLabel';
import React, { useEffect, useState } from 'react';

import styles from './styles.module.scss';

export interface IChip {
  id: number;
  value: string;
}

interface IInputChipsetProps {
  onChipsChanged: (chips: IChip[]) => void;
  validateFn?: (value: string) => string | boolean;
  defaultValues?: IChip[];
  onValueChange?: (value: string) => void;
  error?: string;
}

export const InputChipset: React.FC<IInputChipsetProps> = ({
  onChipsChanged,
  validateFn,
  defaultValues = [],
  onValueChange,
  error: parentError,
}) => {
  const [chips, setChips] = useState<IChip[]>(defaultValues);
  const [activeValue, setActiveValue] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    onChipsChanged(chips);
  }, [JSON.stringify(chips)]);

  const onInputEnter = (e: any) => {
    if (e.key === 'Enter') {
      if (validateFn) {
        const isValid = validateFn(activeValue);
        if (typeof isValid === 'string') {
          setError('Not valid email');
          return;
        }
      }

      setChips(prevState => {
        return [
          ...prevState,
          {
            id: new Date().getTime(),
            value: activeValue,
          },
        ];
      });
      setActiveValue('');

      if (onValueChange) {
        onValueChange('');
      }
    }
  };

  const onDeleteChip = (id: number) => {
    return () => {
      setChips(prevState => prevState.filter(chip => chip.id !== id));
    };
  };

  const onInput = (e: any) => {
    setError('');
    const value = e.target.value;
    setActiveValue(value);
    if (onValueChange) {
      onValueChange(value);
    }
  };

  const errorDisplay = error || parentError;

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.chipsList}>
          {chips.map(chip => {
            return (
              <div key={chip.id} className={styles.chip}>
                {chip.value}
                <span onClick={onDeleteChip(chip.id)} className={styles.removeChip}>
                  &#10006;
                </span>
              </div>
            );
          })}
          <input
            className={styles.input}
            type="text"
            value={activeValue}
            onKeyDown={onInputEnter}
            onInput={onInput}
            placeholder="Type email and press Enter to mark"
          />
        </div>
      </div>
      {errorDisplay && <FormErrorsLabel message={errorDisplay} />}
    </>
  );
};

export default InputChipset;
