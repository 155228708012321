import GaugeComponent from 'react-gauge-component';

import { GAUGE_SENTIMENT_CONFIG, GaugeSentimentKey } from '../types';

interface IGaugeChartProps {
  loading: boolean;
  percentage: number | null;
}

const GaugeChart = (props: IGaugeChartProps) => {
  const { percentage, loading } = props;

  if (loading || !percentage) {
    return null;
  }

  const width = 220;
  const height = 170;
  const radius = 60;

  const subArcs = (Object.keys(GAUGE_SENTIMENT_CONFIG) as GaugeSentimentKey[]).map(key => {
    const { max } = GAUGE_SENTIMENT_CONFIG[key];

    return {
      limit: max,
    };
  });

  const createArcPath = (
    startPercent: number,
    endPercent: number,
    radius: number,
    customPath?: string
  ) => {
    if (customPath) {
      return customPath;
    }

    const startAngle = (startPercent / 100) * 180;
    const endAngle = (endPercent / 100) * 180;

    const startX = width / 2 + radius * Math.cos((Math.PI / 180) * (startAngle - 90));
    const startY = height / 2 + radius * Math.sin((Math.PI / 180) * (startAngle - 90));

    const endX = width / 2 + radius * Math.cos((Math.PI / 180) * (endAngle - 90));
    const endY = height / 2 + radius * Math.sin((Math.PI / 180) * (endAngle - 90));

    return `M ${startX} ${startY} A ${radius} ${radius} 0 0 1 ${endX} ${endY}`;
  };

  const labels = [
    { text: 'Low', start: 0, end: 50 },
    { text: 'Medium', start: 51, end: 70 },
    { text: 'High', start: 71, end: 90 },
    { text: 'Critical', start: 88, end: 104, customPath: 'M 134 144 A 66.3 69.3 0 0 1 100 151' },
  ];

  return (
    <div>
      <GaugeComponent
        value={percentage}
        style={{ width: 220, height: 220 }}
        labels={{
          valueLabel: { hide: true },
          tickLabels: {
            hideMinMax: true,
          },
        }}
        pointer={{ color: '#fff', type: 'needle', width: 10 }}
        arc={{
          cornerRadius: 0,
          padding: 0.02,
          width: 0.3,
          colorArray: ['#17B26A', '#FAC515', '#F79009', '#F04438'],
          subArcs,
        }}
        type="semicircle"
      />
      <svg
        width={width}
        height={height}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          pointerEvents: 'none',
          transform: 'rotate(-90deg)',
          transformOrigin: 'center',
        }}
      >
        {labels.map(({ text, start, end, customPath }, index) => (
          <g key={index}>
            <path
              id={`arcPath-${index}`}
              d={createArcPath(start, end, radius + index * 2.1, customPath)}
              fill="none"
              stroke="none"
            />
            <text fill="#fff" fontSize="8" textAnchor="middle">
              <textPath href={`#arcPath-${index}`} startOffset="50%">
                {text}
              </textPath>
            </text>
          </g>
        ))}
      </svg>
    </div>
  );
};

export default GaugeChart;
