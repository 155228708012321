import classNames from 'classnames';
import { Title, TitleType } from 'components';
import React, { useCallback, useEffect } from 'react';
import { getSelectedTimeZone, getUser } from 'store/slices/authSlice';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import useDateFilters from '../../../hooks/useDateFilters';
import { DateFilter, SourceFilters } from '../../../modules/index';
import {
  getRangeOpenState,
  resetDatasourceFilters,
  setDateRangeFilter,
  setOpenRangeState,
  setRangeFilterType,
  setTopActiveUserId,
  updateRequestedListPage,
} from '../../../store/slices/filtersSlice';
import { FiltersDateRangeType } from '../../../types';
import { getStaticTimeRange } from '../../../utils';
import WithoutBotsAndRobots from './WithoutBotsAndRobots';
import useDataSource from './hooks/useDataSource';
import styles from './styles.module.scss';

const StickyFilters = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(getRangeOpenState);
  const selectedTimeZone = useAppSelector(getSelectedTimeZone);
  const { role } = useAppSelector(getUser);

  useEffect(() => {
    return () => {
      dispatch(resetDatasourceFilters());
    };
  }, [dispatch]);

  const onDateFilterBtnClick = useCallback(
    (filterType: FiltersDateRangeType) => {
      const result = getStaticTimeRange(filterType, selectedTimeZone);

      dispatch(setTopActiveUserId(null));
      dispatch(updateRequestedListPage({ count: 1, storeKeyName: 'postFilters' }));
      dispatch(updateRequestedListPage({ count: 1, storeKeyName: 'usersFilters' }));
      if (filterType === FiltersDateRangeType.range) {
        dispatch(setRangeFilterType(filterType));
        dispatch(setOpenRangeState(!isOpen));
        return;
      }
      dispatch(setOpenRangeState(false));
      dispatch(setDateRangeFilter({ type: filterType, ...result }));
    },
    [dispatch, isOpen]
  );
  const onClose = () => {
    dispatch(setOpenRangeState(false));
  };
  const { dataSourceConfig, isLoading } = useDataSource();
  const { filterConfig, initialRange, minDate } = useDateFilters({
    onClick: onDateFilterBtnClick,
    onClose,
  });
  return (
    <div className={classNames(styles.wrapper, styles[role as string])}>
      <div className={styles.innerWrapper}>
        <Title nativeStyles={styles.statisticTitle} type={TitleType.h2}>
          Statistics
        </Title>
        <div className={styles.inputsWrapper}>
          <WithoutBotsAndRobots isLoading={isLoading} />
          <DateFilter filterConfig={filterConfig} initialRange={initialRange} minDate={minDate} />
        </div>
      </div>
      <SourceFilters data={dataSourceConfig} isLoading={isLoading} />
    </div>
  );
};
export default StickyFilters;
