import { Typography } from '@mui/material';
import classNames from 'classnames';
import { Title, TitleType } from 'components';
import { useAppSelector } from 'hooks';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getUser } from 'store/slices/authSlice';
import { ISubscriptionPlan } from 'types';

import styles from './styles.module.scss';

const PlanItem: React.FC<{
  plan: ISubscriptionPlan;
}> = ({ plan }) => {
  const { t } = useTranslation();
  const user = useAppSelector(getUser);

  const isActive = user.team && user.team.plan?._id === plan._id;

  const {
    name,
    descriptions,
    alias,
    price: { value, currency },
  } = plan;

  const buttonKeyLang = useMemo(() => {
    if (isActive) {
      return 'plans.currentPlanBtnText';
    }

    if (alias === 'enterprise') {
      return 'plans.contactUs';
    }

    if (user.team && !user.team.plan) {
      return 'plans.subscribe';
    }

    return 'plans.downgrade';
  }, [isActive, alias, user.team]);

  return (
    <div className={classNames(styles.wrapper, { [styles.activePlan]: isActive })}>
      <Title type={TitleType.h2}>{name}</Title>
      <div className={styles.features}>
        {descriptions.map((description, index) => (
          <Typography className={styles.feature} key={index}>
            {description}
          </Typography>
        ))}
      </div>
      <div className={styles.priceWrapper}>
        <span className={styles.price}>
          {currency.symbol}
          {value}
        </span>
        <span className={styles.pricePeriod}>{t('plans.perMonthPeriod')}</span>
      </div>
      <button
        className={classNames(styles.button, { [styles.enterprise]: alias === 'enterprise' })}
      >
        {t(buttonKeyLang)}
      </button>
    </div>
  );
};

export default PlanItem;
