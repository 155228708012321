import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import { Grid } from '@mui/material';
import Heading from 'modules/Charts/Heading';
import SentimentGraphs from 'modules/Charts/SantimentedGraphs';
import SnapshotGraphs from 'modules/Charts/SnapshotGraphs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const SentimentGraphsSection = () => {
  const { t } = useTranslation();
  const [SummaryComponent, setSummaryComponent] = useState<React.ReactNode | null>(null);

  return (
    <>
      <Heading
        title={t('charts.sentimentGraphs')}
        infoIcon={
          <div className={styles.infoIcon}>
            <Tooltip title={t('charts.sentimnetGraphInfoTooltipText')}>
              <InfoOutlinedIcon sx={{ fontSize: '16px', display: 'flex' }} htmlColor="#667085" />
            </Tooltip>
          </div>
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <SentimentGraphs onSetSummaryComponent={setSummaryComponent} />
        </Grid>
        <Grid item xs={12} md={4}>
          <SnapshotGraphs />
        </Grid>
        {SummaryComponent && <div className={styles.summaryContainer}>{SummaryComponent}</div>}
      </Grid>
    </>
  );
};

export default SentimentGraphsSection;
