import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionCard } from '../../components';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { resetAlertState } from '../../store/slices/alertsSlice';
import {
  getAlertsFiltersTabsState,
  setTabFilters,
  updateRequestedListPage,
} from '../../store/slices/filtersSlice';
import { AlertStorageTypes, TabConfig } from '../../types';
import { convertNumbersToK } from '../../utils';
import { TabFilters } from '../index';
import AlertsContainerGrid from './AlertsContainerGrid';
import Heading from './Heading';
import useAlertsCounts from './hooks/useAlertsCounts';
import styles from './styles.module.scss';

export const Alerts = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data } = useAlertsCounts();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { value } = useAppSelector(getAlertsFiltersTabsState);
  const queryParams = new URLSearchParams(window.location.search);
  const filteredType = queryParams.get('activeAlertType');

  useEffect(() => {
    if (!filteredType) {
      return;
    }

    wrapperRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [filteredType]);

  const tabConfig: TabConfig[] = useMemo(() => {
    return [
      {
        name: t('alerts.inbox'),
        isActive: value === AlertStorageTypes.INBOX,
        toggleFn: () => {
          dispatch(resetAlertState());
          dispatch(updateRequestedListPage({ count: 1, storeKeyName: 'alertsFilters' }));
          dispatch(setTabFilters({ keyName: 'alertsFilters', value: AlertStorageTypes.INBOX }));
        },
        count: convertNumbersToK(data?.inbox || '0'),
      },
      {
        name: t('alerts.archived'),
        isActive: value === AlertStorageTypes.ARCHIVED,
        toggleFn: () => {
          dispatch(resetAlertState());
          dispatch(updateRequestedListPage({ count: 1, storeKeyName: 'alertsFilters' }));
          dispatch(setTabFilters({ keyName: 'alertsFilters', value: AlertStorageTypes.ARCHIVED }));
        },
        count: convertNumbersToK(data?.archived || '0'),
      },
    ];
  }, [t, value, data?.inbox, data?.archived, dispatch]);

  return (
    <div ref={wrapperRef}>
      <SectionCard nativeClassName={styles.wrapper} noBorder>
        <>
          <Heading title={t('alerts.pageTitle')} nativeClassname={styles.title} />
          <TabFilters data={tabConfig} loading={false} />
          <AlertsContainerGrid />
        </>
      </SectionCard>
    </div>
  );
};
