import { AppButton, ButtonsType, CustomSelect, IChip, Popup } from 'components';
import { API_ROUTES } from 'const';
import { useAppSelector } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ToastifyService from 'services/ToastifyService';
import { getUserStockSymbol } from 'store/slices/authSlice';
import { getUser } from 'store/slices/authSlice';
import useSWR, { useSWRConfig } from 'swr';
import { IReportConfig, ITeamRole, ReportType } from 'types';
import { catchRequestErrors, fetchWithConfig } from 'utils';

import InfoForm from './Forms/Info';
import MailingListForm from './Forms/MailingList';
import styles from './styles.module.scss';

interface ICreateReportPopupProps {
  onClose: () => void;
  configId?: string | null;
}

type FormValues = {
  name: string;
  type: ReportType | '';
  activeMailingListValue: string;
  mailingList: { id: number; value: string }[];
  fromDate: string | null;
  toDate: string | null;
  firstPeriod: string[];
  secondPeriod: string[];
  firstPeriodTimeFrame: string;
  secondPeriodTimeFrame: string;
  timeFrame: string;
  scheduled: boolean;
  stock: string;
  minPriceChangePercentage: number | string;
  maxEvents: number;
  schedule: { type: string; hour: string; minutes: string; weekDay: string; monthDay: string };
  pdfFile: File | undefined;
  teamId: string;
};

export type ActiveStepForm = 'type' | 'info' | 'mailing';

const formatDateString = (str: string) => {
  return str && str.slice(0, 23);
};

const CreateReportPopup: React.FC<ICreateReportPopupProps> = ({ onClose, configId }) => {
  const user = useAppSelector(getUser);
  const { t } = useTranslation();
  const activeStock = useAppSelector(getUserStockSymbol);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
    watch,
    setValue,
    clearErrors,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      activeMailingListValue: '',
      firstPeriod: [],
      secondPeriod: [],
      mailingList: [{ id: new Date().getTime(), value: '' }],
      stock: activeStock,
      timeFrame: '',
      scheduled: false,
      maxEvents: 3,
      type: '',
      minPriceChangePercentage: 1,
      schedule: {
        type: '',
        hour: '0',
        minutes: '0',
        weekDay: '',
        monthDay: '1',
      },
      fromDate: null,
      toDate: null,
    },
  });

  useEffect(() => {
    setValue('stock', activeStock);
  }, [activeStock]);

  const onChangeActiveMailingListValue = (value: string) => {
    setValue('activeMailingListValue', value);
    clearErrors('activeMailingListValue');
  };

  const isScheduled = watch('scheduled');

  const { data: reportConfigEditData, isLoading: isLoadingReportConfigData } =
    useSWR<IReportConfig>(
      configId ? `${API_ROUTES.REPORT_CONFIGS}/${configId}` : null,
      (url: string): Promise<IReportConfig> => {
        return fetchWithConfig<IReportConfig>({
          url,
        });
      }
    );

  useEffect(() => {
    if (!reportConfigEditData) {
      return;
    }

    const {
      name,
      stock,
      schedule,
      scheduleType,
      mailingList,
      maxEvents,
      minPriceChangePercentage,
    } = reportConfigEditData;
    const [min, hours, dayMonth, day, dayWeek] = schedule.split(' ');
    setValue('schedule.type', scheduleType);
    setValue('schedule.minutes', min);
    setValue('schedule.hour', hours);
    setValue('name', name);
    setValue('stock', stock);
    setValue('scheduled', true);
    setValue('maxEvents', maxEvents);
    setValue('minPriceChangePercentage', minPriceChangePercentage);
    setValue(
      'mailingList',
      mailingList.map((val, i) => ({ value: val, id: new Date().getTime() + i }))
    );

    if (scheduleType === 'weekly') {
      setValue('schedule.weekDay', dayWeek);
    }

    if (scheduleType === 'monthly') {
      setValue('schedule.monthDay', dayMonth);
    }
  }, [reportConfigEditData]);

  const [loading, setLoading] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<ActiveStepForm>(configId ? 'info' : 'type');
  const { mutate } = useSWRConfig();

  const mailingList = watch('mailingList');

  const onCloseForm = () => {
    reset();
    onClose();
    setActiveStep('type');
  };

  const onMailsChanged = (list: IChip[]) => {
    setValue('mailingList', list);
  };

  const getCronTab = (schedule: FormValues['schedule']) => {
    const { hour, minutes, monthDay, weekDay, type } = schedule;
    if (type === 'daily') {
      return `${parseInt(minutes)} ${parseInt(hour)} * * *`;
    }

    if (type === 'weekly') {
      return `${parseInt(minutes)} ${parseInt(hour)} * * ${weekDay}`;
    }

    if (type === 'monthly') {
      return `${parseInt(minutes)} ${parseInt(hour)} ${parseInt(monthDay)} * *`;
    }
  };

  const onCreate = async (formData: FormValues) => {
    const {
      name,
      stock,
      type,
      maxEvents,
      minPriceChangePercentage,
      mailingList,
      schedule,
      scheduled,
      fromDate,
      toDate,
      activeMailingListValue,
      firstPeriod,
      secondPeriod,
      pdfFile,
      teamId,
    } = formData;
    if (activeStep === 'type') {
      setActiveStep('info');
      return;
    }

    if (activeStep === 'info') {
      if (watch('type') === 'manual') {
        const pdfFile = watch('pdfFile');
        if (!pdfFile) {
          setError('pdfFile', { message: 'A PDF file is required for manual reports.' });
          return;
        }
      }
      setActiveStep('mailing');
      return;
    }

    if (activeMailingListValue.trim().length) {
      setError('activeMailingListValue', { message: 'Please add email to the list' });
      return;
    }

    try {
      setLoading(true);
      const isManualCase = watch('type');
      if (isManualCase === 'manual') {
        const formDataBody = new FormData();
        if (!pdfFile) {
          throw new Error('No file provided');
        }
        if (!toDate) {
          throw new Error('No toDate provided');
        }
        if (!fromDate) {
          throw new Error('No fromDate provided');
        }
        formDataBody.append('manualTeamId', teamId);
        formDataBody.append('type', type);
        formDataBody.append('pdfFile', pdfFile);
        formDataBody.append('stock', stock);
        formDataBody.append('name', name);
        formDataBody.append('toDate', formatDateString(toDate as string));
        formDataBody.append('fromDate', formatDateString(fromDate as string));
        const mailing = JSON.stringify(mailingList.map(({ value }) => value).filter(Boolean));
        formDataBody.append('mailingListAsString', mailing);
        const res = await fetchWithConfig({
          url: API_ROUTES.REPORTS,
          method: 'POST',
          data: formDataBody,
        });
        ToastifyService.setToast(res.message as string, 'success');
        setLoading(false);
        onCloseForm();
      } else {
        const res = await fetchWithConfig({
          url: scheduled
            ? `${API_ROUTES.REPORT_CONFIGS}/${configId ? configId : ''}`
            : API_ROUTES.REPORTS,
          method: configId ? 'PATCH' : 'POST',
          data: {
            name,
            stock,
            type,
            maxEvents,
            firstPeriod: firstPeriod.map(formatDateString),
            secondPeriod: secondPeriod.map(formatDateString),
            minPriceChangePercentage:
              typeof minPriceChangePercentage === 'string'
                ? parseInt(minPriceChangePercentage)
                : minPriceChangePercentage,
            mailingList: mailingList.map(({ value }) => value).filter(Boolean),
            ...(!scheduled && {
              fromDate: formatDateString(fromDate as string),
              toDate: formatDateString(toDate as string),
            }),
            ...(scheduled && { schedule: getCronTab(schedule), scheduleType: schedule.type }),
          },
        });

        ToastifyService.setToast(res.message as string, 'success');
        setLoading(false);
        onCloseForm();
        mutate([
          scheduled ? API_ROUTES.REPORT_CONFIGS : API_ROUTES.REPORTS,
          { page: 1, take: 10, stock: activeStock },
        ]);
      }
    } catch (error: any) {
      catchRequestErrors(error, setError, () => {}, setLoading);
    }
  };
  const reportTypesOptions = useMemo(() => {
    const options = [
      { _id: 'periodic', name: t('pages.reports.reportTypes.periodic') },
      { _id: 'comparison', name: t('pages.reports.reportTypes.comparison') },
      { _id: 'bot-activity', name: t('pages.reports.reportTypes.bot-activity') },
    ];
    if (user.role === ITeamRole.admin) {
      options.push({ _id: 'manual', name: t('pages.reports.reportTypes.manual') });
    }
    return options;
  }, [t]);

  const backBtnClicked = (activeStepText: ActiveStepForm) => {
    setActiveStep(activeStepText);
    // if back to first stage, reset form.
  };

  const generateBackBtn = () => {
    let backStep: ActiveStepForm = 'info';
    if (activeStep === 'type') return null;
    if (activeStep === 'info') backStep = 'type';
    if (activeStep === 'mailing') backStep = 'info';
    return (
      <AppButton
        onClick={() => backBtnClicked(backStep)}
        btnType={ButtonsType.simple}
        label={t('pages.reports.modal.back')}
      />
    );
  };

  return (
    <>
      <Popup
        title={t(
          `pages.reports.modal.${
            configId
              ? isScheduled
                ? 'editConfig'
                : 'editTitle'
              : isScheduled
                ? 'titleConfig'
                : 'title'
          }`,
          { stock: activeStock }
        )}
        fullWidth
        onClose={onCloseForm}
      >
        <>
          {activeStep !== 'type' && (
            <div className={styles.stepTitle}>{t(`pages.reports.modal.steps.${activeStep}`)}</div>
          )}
          {activeStep === 'type' && (
            <CustomSelect
              name="type"
              register={register}
              control={control}
              data={reportTypesOptions}
              placeholder={t('pages.reports.modal.fields.type')}
              errors={errors.type}
              required
            />
          )}
          {activeStep === 'info' && (
            <InfoForm
              configId={configId}
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
              watch={watch}
              setError={setError}
              clearErrors={clearErrors}
            />
          )}
          {activeStep === 'mailing' && (
            <MailingListForm
              error={errors.activeMailingListValue?.message}
              list={mailingList}
              onChangeActiveMailingListValue={onChangeActiveMailingListValue}
              onMailsChanged={onMailsChanged}
            />
          )}
          <div className={styles.dialogActions}>
            {generateBackBtn()}
            <AppButton
              isLoading={loading}
              onClick={handleSubmit(onCreate)}
              btnType={ButtonsType.simpleSave}
              label={t(
                activeStep === 'mailing'
                  ? `pages.reports.modal.${configId ? 'update' : 'create'}`
                  : 'pages.reports.modal.next'
              )}
            />
          </div>
        </>
      </Popup>
    </>
  );
};

export default CreateReportPopup;
