import { AppButton, ButtonsType, Popup } from 'components';
import { useAppSelector } from 'hooks';
import { DateTime } from 'luxon';
import { ICreateSummary } from 'modules/Charts/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getSelectedTimeZone } from 'store/slices/authSlice';

import styles from './styles.module.scss';

interface ICreateSummaryPopupProps {
  data: ICreateSummary;
  onClose: () => void;
  onSubmit: (data: ICreateSummary) => Promise<void>;
}

const CreateSummaryPopup: React.FC<ICreateSummaryPopupProps> = ({ data, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const zone = useAppSelector(getSelectedTimeZone);

  const onGenerate = () => {
    onSubmit(data);
    onClose();
  };

  const date = DateTime.fromJSDate(new Date(data.date)).setZone(zone).toFormat('LLL dd, yyyy');

  return (
    <Popup
      hideCloseIcon
      additionalClass={styles.popup}
      size="small"
      title={`${t('charts.chartToMarketCreateSummary')} ${date}`}
      onClose={onClose}
    >
      <>
        <div className={styles.text}>It will take a few seconds</div>
        <div className={styles.footerActions}>
          <AppButton
            btnType={ButtonsType.borderedTransparentLowBorder}
            onClick={onClose}
            label="Cancel"
          />
          <AppButton
            btnType={ButtonsType.simpleSave}
            label="Generate report"
            onClick={onGenerate}
          />
        </div>
      </>
    </Popup>
  );
};

export default CreateSummaryPopup;
