import * as React from 'react';
import { SVGProps } from 'react';

export const LikePositiveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.86317 12.3861C2.12205 12.9127 2.53503 13.3481 3.04724 13.6344C3.55945 13.9207 4.14665 14.0444 4.73083 13.9891L9.94758 13.4098L12.25 13.4098C12.7141 13.4098 13.1592 13.2254 13.4874 12.8973C13.8156 12.5691 14 12.1239 14 11.6598L14 5.82648C14 5.36235 13.8156 4.91723 13.4874 4.58904C13.1592 4.26085 12.7141 4.07648 12.25 4.07648L10.2083 4.07648L7.61367 0.646479C7.4582 0.445329 7.25931 0.281889 7.03185 0.168357C6.80438 0.0548261 6.55421 -0.00586575 6.3 -0.00918646C5.86883 -0.00464987 5.45654 0.168411 5.15131 0.472988C4.84608 0.777565 4.67213 1.18948 4.66667 1.62065L4.66667 4.07648L1.75 4.07648C1.28587 4.07648 0.840752 4.26085 0.512565 4.58904C0.184376 4.91723 1.70897e-06 5.36235 7.14723e-07 5.82648L5.07676e-07 8.19481C-0.000150213 8.65117 0.107584 9.1011 0.314417 9.5079L1.86317 12.3861ZM12.8333 5.82648L12.8333 11.6598C12.8333 11.8145 12.7719 11.9629 12.6625 12.0723C12.5531 12.1817 12.4047 12.2431 12.25 12.2431L10.5 12.2431L10.5 5.24315L12.25 5.24315C12.4047 5.24315 12.5531 5.3046 12.6625 5.414C12.7719 5.5234 12.8333 5.67177 12.8333 5.82648ZM1.16667 5.82648C1.16667 5.67177 1.22813 5.5234 1.33752 5.414C1.44692 5.3046 1.59529 5.24315 1.75 5.24315L5.25 5.24315C5.40471 5.24315 5.55308 5.18169 5.66248 5.07229C5.77188 4.9629 5.83333 4.81452 5.83333 4.65981L5.83334 1.62065C5.83747 1.5238 5.87119 1.43054 5.92994 1.35344C5.9887 1.27634 6.06967 1.21909 6.16195 1.18941C6.25423 1.15973 6.35339 1.15904 6.44607 1.18744C6.53876 1.21583 6.62052 1.27195 6.68033 1.34823L9.33333 4.85523L9.33333 12.3044L4.6165 12.8294C4.26504 12.8582 3.91308 12.7802 3.60674 12.6055C3.3004 12.4308 3.05397 12.1677 2.89975 11.8506L1.34808 8.9689C1.22748 8.72876 1.16532 8.46353 1.16667 8.19481L1.16667 5.82648Z"
      fill="#49D28D"
    />
  </svg>
);
