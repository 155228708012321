import { AppButton, ButtonsType, CheckboxComponent, Spinner } from 'components';
import { API_ROUTES } from 'const';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ToastifyService from 'services/ToastifyService';
import useSWR from 'swr';
import { IResponderDisclaimer } from 'types';
import { fetchWithConfig } from 'utils';

import CreateDisclaimerPopup from './CreateDisclaimerPopup';
import styles from './styles.module.scss';

interface FormValues {
  isDeclineUse: boolean;
}

interface ICreateDisclaimerProps {
  stock: string;
}

const CreateDisclaimer: React.FC<ICreateDisclaimerProps> = props => {
  const { stock } = props;
  const [openDisclaimer, setOpenDisclaimer] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      isDeclineUse: false,
    },
  });

  const isDeclineUse = watch('isDeclineUse');

  const {
    data: disclaimerData,
    isLoading: isLoadingDisclaimerData,
    mutate,
    error,
  } = useSWR<IResponderDisclaimer>(
    `${API_ROUTES.RESPONDER_DISCLAIMERS}/${stock}`,
    (url: string): Promise<IResponderDisclaimer> => {
      return fetchWithConfig<IResponderDisclaimer>({
        url,
      });
    }
  );

  const updateUsePolicy = async () => {
    try {
      const res = await fetchWithConfig({
        url: `${API_ROUTES.RESPONDER_DISCLAIMERS}/use-disclaimer`,
        method: 'POST',
        data: {
          isUsed: !isDeclineUse,
          stock,
        },
      });

      ToastifyService.setToast(res?.message as string, 'success');
      mutate();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!disclaimerData) {
      return;
    }

    setValue('isDeclineUse', !disclaimerData.isUsed);
  }, [disclaimerData]);

  const notExist = !disclaimerData && !isLoadingDisclaimerData;

  return (
    <div className={styles.wrapper}>
      {isLoadingDisclaimerData ? (
        <Spinner />
      ) : (
        <>
          <div className={styles.headWrapper}>
            <h3 className={styles.title}>Response disclaimer</h3>
            <AppButton
              htmlType="button"
              btnType={ButtonsType.simpleSave}
              onClick={() => setOpenDisclaimer(true)}
              label={notExist ? 'Add Disclaimer' : 'Edit Disclaimer'}
            />
          </div>
          <CheckboxComponent
            register={register}
            control={control}
            defaultValue={isDeclineUse}
            label="I am not interested in disclaimer"
            name="isDeclineUse"
          />
          <p className={styles.note}>
            Note: Please be aware that the Responder reply does not include a disclaimer, even
            though it is generally not recommended to omit one.
          </p>
          <AppButton
            htmlType="button"
            btnType={ButtonsType.simpleSave}
            onClick={updateUsePolicy}
            label="Update"
          />
          {openDisclaimer && (
            <CreateDisclaimerPopup
              onClose={() => {
                setOpenDisclaimer(false);
                mutate();
              }}
              initialData={disclaimerData}
              stock={stock}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CreateDisclaimer;
