import { UserAva } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IRespondItem, UserAvaType } from 'types';
import { getDateSourceIcon } from 'utils';

import styles from './styles.module.scss';

interface IReplyUserItemProps {
  data: IRespondItem['reply'];
}

const ReplyUserItem: React.FC<IReplyUserItemProps> = ({ data }) => {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  const {
    // user: { image, displayName },
    stock,
    source,
    replyText,
  } = data;

  const DataSourceIcon = getDateSourceIcon(source);

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        <div className={styles.imageWrapper}>
          <UserAva
            type={UserAvaType.topPost}
            data={{
              name: stock,
              Icon: <DataSourceIcon />,
              src: '',
            }}
          />
        </div>
        <span className={styles.responderAnswer}>{t('pages.responder.answer')}</span>
      </div>
      <p className={styles.message}>{replyText}</p>
    </div>
  );
};

export default ReplyUserItem;
