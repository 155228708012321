import { useEffect } from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../const';
import { getUserStockSymbol } from '../store/slices/authSlice';
import {
  getFilterWithoutBotsAndRobots,
  getFiltersDatasource,
  getFiltersDateRangeFrom,
  getFiltersDateRangeTo,
  getTopUsersActiveId,
} from '../store/slices/filtersSlice';
import { setListCanRequest } from '../store/slices/filtersSlice';
import { fetchWithConfig } from '../utils';
import { useAppDispatch, useAppSelector } from './index';

const useConversationCounts = (): {
  posts: {
    isLoading: boolean;
    data: { positive: string; negative: string; neutral: string } | null;
    error: null;
  };
  refetch: () => void;
} => {
  const dispatch = useAppDispatch();
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const filterFrom = useAppSelector(getFiltersDateRangeFrom);
  const filterTo = useAppSelector(getFiltersDateRangeTo);
  const dataSource = useAppSelector(getFiltersDatasource);
  const withoutBotsAndRobots = useAppSelector(getFilterWithoutBotsAndRobots);
  const activeTopUserId = useAppSelector(getTopUsersActiveId);

  const filtersPostCounters = {
    from: filterFrom,
    to: filterTo,
    dataSource,
    stockSymbol,
    withBotsAndRobots: !withoutBotsAndRobots,
    ...(activeTopUserId && { userId: activeTopUserId }),
  };

  const { data, isLoading, isValidating, mutate } = useSWR<any>(
    [`${API_ROUTES.TOP_POST_COUNT}/${stockSymbol}`, filtersPostCounters],
    ([url, params]: [string, Record<string, any>]) => fetchWithConfig({ url, params })
  );

  useEffect(() => {
    dispatch(setListCanRequest({ storeKeyName: 'postFilters', listCanRequest: false }));
  }, [JSON.stringify(filtersPostCounters)]);

  const result = {
    posts: {
      error: null,
      isLoading: isValidating || isLoading,
      data: data ? data : null,
    },
    refetch: mutate,
  };
  return result;
};

export default useConversationCounts;
