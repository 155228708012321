import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import { NoData, Title, TitleType } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ITopic } from '../index';
import styles from './styles.module.scss';

interface IMentionsListProps {
  data?: ITopic;
  loading: boolean;
}

const Topic = ({ data, emptyMessage }: { data: string[]; emptyMessage: string }) => {
  if (!data.length) {
    return <NoData maxImgHeight={145} message={emptyMessage} />;
  }

  return (
    <>
      {data.map((topic, index) => {
        const number = index + 1;
        return (
          <div className={styles.topicItem} key={number}>
            <div className={styles.topicNumber}>{number}</div>
            <div>{topic}</div>
          </div>
        );
      })}
    </>
  );
};

const TopicsList: React.FC<IMentionsListProps> = ({ data, loading }) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <div className={styles.listWrapper}>
        {Array.from(Array(2)).map((item, index) => {
          return (
            <div className={styles.skeletonWrapper} key={index}>
              <Skeleton variant="text" height={46} width="100%" />
              <div className={styles.topicsList}>
                {Array.from(Array(5)).map((item, index) => {
                  return (
                    <div className={styles.skeletonTopicItem} key={index}>
                      <Skeleton variant="text" height={32} width="100%" />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  if (!data) {
    return <h3 className={styles.emptyTitle}>{t('topics.empty')}</h3>;
  }

  const { negativeTopics, positiveTopics } = data;

  return (
    <div className={styles.listWrapper}>
      <div className={classNames(styles.itemWrapper, styles.positive)}>
        <div className={styles.content}>
          <div className={styles.heading}>
            <Title type={TitleType.h4}>{t('topics.positiveTopics')}</Title>
          </div>
          <div className={styles.topicsList}>
            <Topic data={positiveTopics} emptyMessage={t('topics.positiveTopicsEmpty')} />
          </div>
        </div>
      </div>
      <div className={classNames(styles.itemWrapper, styles.negative)}>
        <div className={styles.content}>
          <div className={styles.heading}>
            <Title type={TitleType.h4}>{t('topics.negativeTopics')}</Title>
          </div>
          <div className={styles.topicsList}>
            <Topic data={negativeTopics} emptyMessage={t('topics.negativeTopicsEmpty')} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopicsList;
