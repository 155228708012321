import { useAppDispatch, useAppSelector } from 'hooks';
import useCompetitiveStocks from 'hooks/useCompetitiveStocks';
import React, { JSX, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserCompetitiveStocks, getUserStockSymbol } from 'store/slices/authSlice';
import {
  getCompetitiveActiveStocks,
  getFiltersDataRangeLoadingState,
  getFiltersDatasource,
  setCompetitiveActiveStock,
  setLoadingState,
} from 'store/slices/filtersSlice';

const useStocksFilter = () => {
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const competitiveStocks = useAppSelector(getUserCompetitiveStocks);
  const activeCompetitiveStocks = useAppSelector(getCompetitiveActiveStocks);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filteredDatasource = useAppSelector(getFiltersDatasource);
  const globalLoading = useAppSelector(getFiltersDataRangeLoadingState);

  useEffect(() => {
    dispatch(setLoadingState({ key: 'dateRange', value: false }));
  }, [dispatch]);

  const onClick = (stock: string) => {
    if (globalLoading) {
      return;
    }

    let newCompetitiveStocks = activeCompetitiveStocks;

    if (activeCompetitiveStocks.includes(stock)) {
      newCompetitiveStocks = activeCompetitiveStocks.filter(val => val !== stock);
    } else {
      newCompetitiveStocks = [...activeCompetitiveStocks, stock];
    }

    dispatch(setCompetitiveActiveStock(newCompetitiveStocks));
  };

  const { competitiveStocks: data } = useCompetitiveStocks();

  const dataSourceConfig: {
    title: string;
    counter?: number;
    dataSource: string;
    onClick: () => void;
    isActive: boolean;
    Icon?: JSX.Element | undefined;
  }[] = useMemo(() => {
    const stockConfig = data?.length
      ? data
      : competitiveStocks.map(stock => ({ name: stock, symbol: stock, image: '' }));

    const config = stockConfig.map(stock => ({
      title: `${stock.name} (${stock.symbol})`,
      Icon: !stock.image ? undefined : <img height={20} width={20} src={stock.image} alt="" />,
      dataSource: stock.symbol,
      onClick: () => {
        onClick(stock.symbol);
      },
      isActive: activeCompetitiveStocks.includes(stock.symbol),
    }));

    return config;
  }, [data, competitiveStocks, activeCompetitiveStocks, onClick]);
  return {
    dataSourceConfig,
  };
};

export default useStocksFilter;
