import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import React from 'react';

import { Title, TitleType } from '../Title';
import styles from './styles.module.scss';

export const SectionCardHeading: React.FC<{
  title: string;
  subtitle?: string;
  nativeClassname?: string;
  infoIcon?: React.ReactNode;
}> = ({ title, subtitle, nativeClassname = '', infoIcon = '' }) => {
  return (
    <div className={classNames(styles.wrapper, !!nativeClassname && nativeClassname)}>
      <Title type={TitleType.h3}>
        <>
          {title}
          {subtitle && <Typography variant="body2">{subtitle}</Typography>}
        </>
      </Title>
      {infoIcon}
    </div>
  );
};
