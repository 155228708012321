import { Box, Tooltip } from '@mui/material';
import React from 'react';

type EllipsisWithTooltipProps = {
  text: string;
  width?: number;
};

export const EllipsisWithTooltip: React.FC<EllipsisWithTooltipProps> = ({ text, width = 200 }) => {
  return (
    <Tooltip title={text}>
      <Box
        component="div"
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          fontSize: 'inherit',
          fontWeight: 'inherit',
          width,
        }}
      >
        {text}
      </Box>
    </Tooltip>
  );
};
