import { API_ROUTES, TOKEN_KEY } from 'const';
import { useAppDispatch, useAppSelector } from 'hooks/index';
import { useEffect, useState } from 'react';
import { authUser, getUser, setSelectedTimeZone, setUiConfig } from 'store/slices/authSlice';
import { initDateRangeFilter } from 'store/slices/filtersSlice';
import { IUiConfig, IUser } from 'types';
import { axiosErrorHandler, fetchWithConfig } from 'utils';

const useAuth = () => {
  const storeUser = useAppSelector(getUser);
  const accessToken = localStorage.getItem(TOKEN_KEY) || storeUser.accessToken;
  const dispatch = useAppDispatch();
  const [{ loading, user }, setState] = useState<{
    loading: boolean;
    user: IUser;
  }>({
    loading: !!accessToken,
    user: storeUser,
  });

  const fetchUser = async () => {
    try {
      setState(state => ({
        ...state,
        loading: true,
      }));
      const responseData = await fetchWithConfig<IUser>({
        url: API_ROUTES.USER,
        method: 'GET',
      });

      const userUiConfig = await fetchWithConfig<IUiConfig>({
        url: API_ROUTES.UI_CONFIG,
      });

      const normalisedObject = {
        _id: responseData._id,
        email: responseData.email,
        accessToken: user.accessToken,
        role: responseData.role,
        team: responseData.team,
        timeZone: responseData.timeZone,
        isTeamOwner: responseData.isTeamOwner,
      };

      dispatch(authUser(normalisedObject));
      dispatch(initDateRangeFilter(normalisedObject.timeZone));
      dispatch(setUiConfig(userUiConfig));
      dispatch(setSelectedTimeZone(normalisedObject.timeZone));
      setState(state => ({
        ...state,
        user: normalisedObject,
        loading: false,
      }));
    } catch (error: any) {
      const errorResponse = error?.response?.data?.error;
      //todo add error response listener

      setState(state => ({
        ...state,
        loading: false,
      }));
    }
  };

  useEffect(() => {
    if (!accessToken) {
      return;
    }
    fetchUser().then(() => {});
  }, [accessToken, dispatch]);

  return { loading, user };
};
export default useAuth;
