import * as React from 'react';
import { SVGProps } from 'react';

export const AlertIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.6467 4.97602V7.51013M7.6467 10.0442H7.65303M6.76946 1.73978L1.55877 10.7401C1.26975 11.2393 1.12524 11.4889 1.1466 11.6938C1.16523 11.8724 1.25885 12.0348 1.40415 12.1405C1.57074 12.2616 1.85916 12.2616 2.436 12.2616H12.8574C13.4342 12.2616 13.7227 12.2616 13.8892 12.1405C14.0345 12.0348 14.1282 11.8724 14.1468 11.6938C14.1682 11.4889 14.0236 11.2393 13.7346 10.7401L8.52393 1.73978C8.23595 1.24236 8.09196 0.993649 7.9041 0.910117C7.74023 0.837253 7.55316 0.837253 7.38929 0.910117C7.20144 0.993649 7.05744 1.24236 6.76946 1.73978Z"
      stroke="#F2F4F7"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
