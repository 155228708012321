import { Layout } from 'components/Layout';
import styles from 'pages/Admin/DashboardLayout/styles.module.scss';
import { SidebarNav } from 'components/SidebarNav';
import PageHeader from 'pages/Admin/PageHeader';
import React from 'react';

export interface IProfileLayoutProps {
  children: React.ReactNode;
  createLink?: React.ReactElement;
  title?: string;
  onSubmit?: () => void;
  actionBtn?: React.ReactElement;
  navConfig: { title: string; path: string; icon?: any }[];
  withBackButton?: boolean;
}

const ProfileLayout = (props: IProfileLayoutProps) => {
  const {
    createLink,
    onSubmit,
    title,
    actionBtn,
    children,
    navConfig,
    withBackButton = true,
  } = props;
  const Tag = onSubmit && typeof onSubmit === 'function' ? 'form' : 'div';
  const customProps = onSubmit && typeof onSubmit === 'function' ? { onSubmit } : {};
  return (
    <Layout>
      <div className={styles.wrapper}>
        <SidebarNav config={navConfig} />
        <Tag className={styles.flexCol} {...customProps}>
          {title && (
            <div className={styles.row}>
              <PageHeader withBack={withBackButton} title={title} />
              {!!actionBtn && <div className={styles.gowFalse}>{actionBtn}</div>}
              {!!createLink && createLink}
            </div>
          )}
          {children}
        </Tag>
      </div>
    </Layout>
  );
};

export default ProfileLayout;
