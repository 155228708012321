import InputField, { InputFieldType } from 'components/InputField';
import { useAppSelector } from 'hooks';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { required } from 'services/InputFIeldsValidator/rules';
import { getSelectedTimeZone } from 'store/slices/authSlice';
import { getTimeFrame } from 'utils';

import styles from '../styles.module.scss';

const formatDate = (date: string) => {
  return new Date(date.slice(0, -6));
};

interface IInfoFormProps {
  control: any;
  register: any;
  errors: any;
  setValue: any;
  watch: any;
  setError: any;
}

const BotsActivityForm: React.FC<IInfoFormProps> = ({
  control,
  register,
  errors,
  setValue,
  watch,
  setError,
}) => {
  const { t } = useTranslation();
  const selectedFrom = watch('fromDate');
  const selectedTo = watch('toDate');
  const zone = useAppSelector(getSelectedTimeZone);
  const minDate = DateTime.now().startOf('month').minus({ month: 3 }).toJSDate();
  const initialRange = [
    formatDate(selectedFrom || DateTime.now().minus({ days: 1 }).toJSDate().toString()),
    formatDate(selectedTo || DateTime.now().toJSDate().toString()),
  ];

  const onRangeChange = (fromDate: string, toDate: string) => {
    setValue('timeFrame', getTimeFrame(fromDate, toDate, zone));
    setValue('fromDate', fromDate);
    setValue('toDate', toDate);
    setError('timeFrame', null);
  };

  return (
    <>
      <div className={styles.field}>
        <InputField
          label={t('pages.reports.modal.fields.reportName')}
          control={control}
          defaultValue=""
          name="name"
          type={InputFieldType.text}
          register={register}
          placeholder=""
          validate={{
            required,
          }}
          errors={errors.name}
        />
      </div>
      <div className={styles.field}>
        <InputField
          name="stock"
          register={register}
          control={control}
          label={t('pages.reports.modal.fields.stock')}
          errors={errors.stock}
          required
          defaultValue=""
          type={InputFieldType.text}
          validate={{}}
          disabled
          placeholder=""
        />
      </div>
      <div className={styles.field}>
        <InputField
          autocomplete={false}
          rangePickerConfig={{ minDate, initialRange, onRangeChange }}
          label={t('pages.reports.modal.fields.timeFrame')}
          control={control}
          register={register}
          name="timeFrame"
          defaultValue=""
          errors={errors.timeFrame}
          placeholder=""
          type={InputFieldType.text}
          validate={{ required }}
        />
      </div>
    </>
  );
};

export default BotsActivityForm;
