import classNames from 'classnames';
import { useAppSelector } from 'hooks';
import useCompanyInfo from 'hooks/userCompanyInfo';
import { IPrPoint } from 'modules/Charts/types';
import React, { useState } from 'react';
import { getUserStockSymbol } from 'store/slices/authSlice';

import { PrPoint } from './Point';
import styles from './styles.module.scss';

interface IPrPointsProps {
  points: IPrPoint[];
  customClassName?: string;
}

export const PrPoints: React.FC<IPrPointsProps> = ({ points, customClassName }) => {
  const [activePrPoint, setActivePrPoint] = useState('');
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const { companyInfo } = useCompanyInfo(stockSymbol);

  if (!points.length) {
    return null;
  }

  const onPrPointClick = (id: string) => {
    if (id === activePrPoint) {
      setActivePrPoint('');
      return;
    }

    setActivePrPoint(id);
  };

  return (
    <div className={classNames(styles.prOverlay, customClassName)}>
      {points.map(point => {
        return (
          <PrPoint
            key={point._id}
            data={point}
            activePoint={activePrPoint}
            onClick={onPrPointClick}
            companyInfo={companyInfo}
          />
        );
      })}
    </div>
  );
};
