import { Id, ToastContent, ToastOptions, toast } from 'react-toastify';
import { TypeOptions } from 'react-toastify/dist/types';

class ToastifyService {
  private readonly toastConfig: Record<string, any>;
  private readonly toastCb: Record<
    string,
    <TData = unknown>(content: ToastContent<TData>, options?: ToastOptions<{}> | undefined) => Id
  >;
  constructor() {
    this.toastConfig = {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    };
    this.toastCb = {
      success: toast.success,
      info: toast.info,
      warning: toast.warn,
      error: toast.error,
      default: toast,
    };
  }

  setToast(message: string, toastType: TypeOptions, customConfig = {}) {
    this.toastCb[toastType](message, { ...this.toastConfig, ...customConfig });
  }
}

export default new ToastifyService();
