import { createAction, createSlice } from '@reduxjs/toolkit';
import { IRespondItem, IResponderStore } from 'types';

import { RootState } from '../index';

const initialState: IResponderStore = {
  activeItem: null,
  repliedId: null,
  sort: 'impression',
};

export const getActiveItem = (store: RootState) => store.responder.activeItem;
export const getSort = (store: RootState) => store.responder.sort;
export const getRepliedId = (store: RootState) => store.responder.repliedId;
export const setActiveItem = createAction<IRespondItem | null>('responderSlice/setActiveItem');
export const setSort = createAction<'impression' | 'dateCreated'>('responderSlice/setSort');
export const setRepliedId = createAction<string | null>('responderSlice/setRepliedId');

const responderSlice = createSlice({
  name: 'responderSlice',
  reducers: {},
  initialState,
  extraReducers: builder => {
    builder
      .addCase(setActiveItem, (store, { payload }) => {
        store.activeItem = payload;
      })
      .addCase(setRepliedId, (store, { payload }) => {
        store.repliedId = payload;
      })
      .addCase(setSort, (store, { payload }) => {
        store.sort = payload;
      });
  },
});

export const responderReducers = responderSlice.reducer;
