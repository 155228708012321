import { Avatar, Skeleton } from '@mui/material';
import { Title, TitleType } from 'components';
import { NoStockImgIcon } from 'components/SvgComponents';
import useCompetitiveStocks from 'hooks/useCompetitiveStocks';
import React from 'react';

import { IWeeklyTopic } from '../index';
import styles from './styles.module.scss';

interface IMentionsListProps {
  data?: IWeeklyTopic[];
  loading: boolean;
}

const TopicsList: React.FC<IMentionsListProps> = ({ data, loading }) => {
  const { competitiveStocks, mainStock } = useCompetitiveStocks();

  if (!data || loading || !competitiveStocks) {
    return (
      <div className={styles.listWrapper}>
        {Array.from(Array(4)).map((item, index) => {
          return (
            <div className={styles.skeletonWrapper} key={index}>
              <Skeleton variant="text" height={46} width="100%" />
              <div className={styles.topicsList}>
                {Array.from(Array(5)).map((item, index) => {
                  return (
                    <div className={styles.skeletonTopicItem} key={index}>
                      <Skeleton variant="text" height={32} width="100%" />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={styles.listWrapper}>
      {data.map(({ topics, symbol }) => {
        const stockData = [...competitiveStocks, mainStock].find(
          ({ symbol: stock }) => symbol === stock
        );

        if (!stockData) {
          return null;
        }

        const { image, name } = stockData;

        return (
          <div className={styles.itemWrapper} key={symbol}>
            <div className={styles.heading}>
              <Avatar sx={{ width: 24, height: 24 }} src={image}>
                <NoStockImgIcon />
              </Avatar>
              <Title type={TitleType.h4}>{name}</Title>
            </div>
            <div className={styles.topicsList}>
              {topics.map((topic, index) => {
                const [numberKey] = topic.match(/^\d+\./)!;
                const [text] = topic
                  .split(numberKey)
                  .map(item => item.trim())
                  .filter(Boolean);

                return (
                  <div className={styles.topicItem} key={index}>
                    <div className={styles.topicNumber}>{numberKey.replace('.', '')}</div>
                    <div>{text}</div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TopicsList;
