import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { matchValue, required } from 'services/InputFIeldsValidator/rules';

import AppButton, { ButtonsType } from '../../../../../components/AppButton';
import { FormErrorsLabel } from '../../../../../components/FormErrorsLabel';
import InputField, { InputFieldType } from '../../../../../components/InputField';
import { EyeIcon, LockIcon, VisibilityOfIcon } from '../../../../../components/SvgComponents';
import { API_ROUTES } from '../../../../../const';
import ToastifyService from '../../../../../services/ToastifyService';
import { fetchWithConfig } from '../../../../../utils';
import ContentBlock from '../../../ContentBlock';
import FormGroup from '../../../components/FormGroup';
import styles from './styles.module.scss';

type FormValues = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};
const PasswordForm = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
    getValues,
    reset,
  } = useForm<FormValues>();
  const [{ newPassShow, oldPassShow, confirmPassShow }, setShow] = useState<{
    newPassShow: boolean;
    oldPassShow: boolean;
    confirmPassShow: boolean;
  }>({
    newPassShow: false,
    oldPassShow: false,
    confirmPassShow: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [globalError, setGlobalError] = useState<string>('');
  const onSubmit = async (data: FormValues) => {
    setGlobalError('');
    try {
      setLoading(true);
      const responseData = await fetchWithConfig<{
        message: string;
      }>({
        url: API_ROUTES.CHANGE_PASSWORD,
        method: 'POST',
        data: {
          currentPassword: data.currentPassword,
          newPassword: data.newPassword,
        },
      });
      ToastifyService.setToast(responseData.message, 'success');
      reset();
      setLoading(false);
    } catch (error: any) {
      const errorResponse = error?.response?.data?.error;

      if (Array.isArray(errorResponse?.data)) {
        errorResponse.data.forEach((err: any) => {
          setError(err.field, {
            type: 'manual',
            message: err.error,
          });
        });
      }
      if (typeof errorResponse?.data === 'string') {
        setGlobalError(errorResponse?.data);
      }
      setLoading(false);
    }
  };

  const onRightIconClick = (key: string, value: boolean) => {
    setShow(state => ({
      ...state,
      [key]: value,
    }));
  };

  const onInputFocus = () => {
    setGlobalError('');
  };

  const matchPassword = (v: string) => {
    const { newPassword } = getValues();
    return matchValue(v, newPassword);
  };

  return (
    <FormGroup
      title={t('pages.tabs.password')}
      subtitle={t('pages.tabs.passwordSubtitle')}
      onSubmit={handleSubmit(onSubmit)}
      submitLink={
        <AppButton
          btnType={ButtonsType.simpleSave}
          label={t('buttons.save')}
          isLoading={loading}
          disabled={loading}
        />
      }
    >
      <>
        <div>
          <ContentBlock>
            <div className={styles.row}>
              <div className={styles.labelWrapper}>
                <p className={styles.label}>{t('pages.tabs.oldPasswordSubtitle')}</p>
              </div>
              <div className={styles.labelRight}>
                <InputField
                  label={t('')}
                  control={control}
                  defaultValue={''}
                  name={'currentPassword'}
                  type={newPassShow ? InputFieldType.text : InputFieldType.password}
                  register={register}
                  placeholder={t('pages.tabs.labels.passLabel')}
                  validateText={t('errors.passwordIsnNotMatchField')}
                  LeftIcon={LockIcon}
                  RightIcon={newPassShow ? VisibilityOfIcon : EyeIcon}
                  onRightIconClick={() => {
                    onRightIconClick('newPassShow', !newPassShow);
                  }}
                  validate={{
                    required,
                  }}
                  errors={errors.currentPassword}
                />
              </div>
            </div>
          </ContentBlock>
          <ContentBlock>
            <div className={styles.row}>
              <div className={styles.labelWrapper}>
                <p className={styles.label}>{t('pages.tabs.newPasswordSubtitle')}</p>
              </div>
              <div className={styles.labelRight}>
                <InputField
                  label={''}
                  control={control}
                  defaultValue={''}
                  name={'newPassword'}
                  type={oldPassShow ? InputFieldType.text : InputFieldType.password}
                  register={register}
                  placeholder={t('pages.tabs.labels.newPassLabel')}
                  validateText={t('errors.passwordIsnNotMatchField')}
                  LeftIcon={LockIcon}
                  RightIcon={oldPassShow ? VisibilityOfIcon : EyeIcon}
                  onRightIconClick={() => {
                    onRightIconClick('oldPassShow', !oldPassShow);
                  }}
                  validate={{
                    required,
                  }}
                  errors={errors.newPassword}
                />
              </div>
            </div>
          </ContentBlock>
          <ContentBlock>
            <div className={styles.row}>
              <div className={styles.labelWrapper}>
                <p className={styles.label}>{t('auth.confirmPass')}</p>
              </div>
              <div className={styles.labelRight}>
                <InputField
                  onFocus={onInputFocus}
                  label={''}
                  control={control}
                  defaultValue={''}
                  name={'confirmPassword'}
                  type={confirmPassShow ? InputFieldType.text : InputFieldType.password}
                  register={register}
                  placeholder={t('auth.confirmPass')}
                  validateText={t('errors.passwordIsnNotMatchField')}
                  LeftIcon={LockIcon}
                  RightIcon={confirmPassShow ? VisibilityOfIcon : EyeIcon}
                  onRightIconClick={() => {
                    onRightIconClick('confirmPassShow', !confirmPassShow);
                  }}
                  validate={{
                    required,
                    matchPassword,
                  }}
                  errors={errors.confirmPassword}
                />
                {!!globalError && <FormErrorsLabel message={globalError} />}
              </div>
            </div>
          </ContentBlock>
        </div>
      </>
    </FormGroup>
  );
};

export default PasswordForm;
