import classNames from 'classnames';
import { AppButton, ButtonsType, Spinner } from 'components';
import { FormErrorsLabel } from 'components/FormErrorsLabel';
import InputField, { InputFieldType } from 'components/InputField';
import { API_ROUTES } from 'const';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import { IRedditSourceConfig } from 'types';
import { fetchWithConfig } from 'utils';

import ListPopup from '../../_SharedComponents/ListPopup';
import DictionaryPopup from './DictionaryPopup';
import styles from './styles.module.scss';

export interface CreateRedditSourcesFormValues {
  queries: string[];
  subredditFiltersLists: string[];
  subredditDedicatedLists: string[];
  postsDictionary: string;
}

interface ICreateRedditSourcesProps {
  stock?: string;
  onChange: (data: CreateRedditSourcesFormValues) => void;
  validationError: string;
  setValidationError: (value: string) => void;
}

const CreateRedditSources: React.FC<ICreateRedditSourcesProps> = ({
  stock,
  onChange,
  validationError,
  setValidationError,
}) => {
  const [isSubredditListsPopupOpen, setIsSubredditListsPopupOpen] = useState(false);
  const [isDedicatedSubredditListsPopupOpen, setIsDedicatedSubredditListsPopupOpen] =
    useState(false);
  const [isDictionaryPopupOpen, setIsDictionaryPopupOpen] = useState(false);
  const [dataSetted, setDataSetted] = useState(false);

  const { register, control, watch, setValue } = useForm<CreateRedditSourcesFormValues>({
    defaultValues: {
      queries: [''],
      subredditFiltersLists: [],
      subredditDedicatedLists: [],
      postsDictionary: '',
    },
  });

  const allData = watch();
  const queries = allData.queries;
  const dictionary = allData.postsDictionary;
  const subredditFiltersLists = allData.subredditFiltersLists;
  const subredditDedicatedLists = allData.subredditDedicatedLists;

  useEffect(() => {
    onChange(allData);
    setValidationError('');
  }, [JSON.stringify(allData)]);

  const { data, isLoading, isValidating, error } = useSWR<IRedditSourceConfig>(
    stock ? `${API_ROUTES.REDDIT_SOURCE_CONFIG}/${stock}` : null,
    (url: string): Promise<IRedditSourceConfig> => {
      return fetchWithConfig<IRedditSourceConfig>({
        url,
      });
    }
  );

  useEffect(() => {
    if (!error) {
      return;
    }

    setDataSetted(true);
  }, [error]);

  useEffect(() => {
    if (!data) {
      return;
    }

    setValue('queries', data.queries);
    setValue('postsDictionary', data.postsDictionary);
    setValue('subredditFiltersLists', data.subredditFiltersLists);
    setValue('subredditDedicatedLists', data.subredditDedicatedLists);
    setDataSetted(true);
  }, [data, setValue]);

  return (
    <div className={styles.wrapper}>
      {stock && (isLoading || isValidating || !dataSetted) ? (
        <Spinner staticPosition size={150} />
      ) : (
        <>
          <div className={styles.row}>
            <div className={classNames(styles.title, styles.firstCol)}>Reddit Sources</div>
            <div className={styles.sourcesButtons}>
              <AppButton
                htmlType="button"
                btnType={ButtonsType.outlined}
                label="Subreddits filter"
                onClick={() => setIsSubredditListsPopupOpen(true)}
              />
              <AppButton
                htmlType="button"
                btnType={ButtonsType.outlined}
                label="Dedicated Subreddits"
                onClick={() => setIsDedicatedSubredditListsPopupOpen(true)}
              />
              <AppButton
                htmlType="button"
                btnType={ButtonsType.outlined}
                label="Posts dictionary"
                onClick={() => setIsDictionaryPopupOpen(true)}
              />
            </div>
          </div>
          <FormErrorsLabel message={validationError} />
          <div className={styles.queries}>
            <div className={styles.rows}>
              {queries.map((query, index) => {
                return (
                  <div key={index} className={styles.row}>
                    <span className={styles.firstCol}>Query {index + 1}</span>
                    <InputField
                      control={control}
                      defaultValue={query}
                      name={`queries.${index}`}
                      type={InputFieldType.text}
                      register={register}
                      placeholder=""
                      validate={{}}
                      errors={undefined}
                    />
                    <AppButton
                      htmlType="button"
                      disabled={queries.length <= 1}
                      btnType={ButtonsType.simple}
                      label="Delete"
                      onClick={() => {
                        const updatedQueries = queries.filter((_, i) => i !== index); // Удаляем по индексу
                        setValue('queries', updatedQueries);
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <div className={styles.addQueryWrapper}>
              <AppButton
                htmlType="button"
                btnType={ButtonsType.outlined}
                onClick={() => setValue('queries', [...queries, ''])}
                label="Add new query"
              />
            </div>
          </div>
        </>
      )}
      {isSubredditListsPopupOpen && (
        <ListPopup
          defaultValue={subredditFiltersLists}
          onClose={() => setIsSubredditListsPopupOpen(false)}
          onSave={value => {
            setValue('subredditFiltersLists', value);
          }}
          title="Filter subreddit list"
        />
      )}
      {isDedicatedSubredditListsPopupOpen && (
        <ListPopup
          defaultValue={subredditDedicatedLists}
          onClose={() => setIsDedicatedSubredditListsPopupOpen(false)}
          onSave={value => {
            setValue('subredditDedicatedLists', value);
          }}
          title="Dedicated subreddit list"
        />
      )}
      {isDictionaryPopupOpen && (
        <DictionaryPopup
          defaultValue={dictionary}
          onClose={() => setIsDictionaryPopupOpen(false)}
          onSave={value => {
            setValue('postsDictionary', value);
          }}
        />
      )}
    </div>
  );
};

export default CreateRedditSources;
