export enum ChangeStatusType {
  up = 'up',
  down = 'down',
  neutral = 'null',
}

export enum SummaryType {
  simple,
  chart,
}

export interface SummaryAnalyze {
  value: string | number;
  change: { value: string; status: ChangeStatusType };
}

export interface SummarySentimentAnalyze {
  negative: SummaryAnalyze;
  positive: SummaryAnalyze;
  neutral: SummaryAnalyze;
}

export interface ExecutiveSummaryResponse {
  mentions: SummaryAnalyze;
  sentiment: SummarySentimentAnalyze;
  bots: SummaryAnalyze;
  alerts: SummaryAnalyze;
}

export interface CompanyResponse {
  _id: string;
  symbol: string;
  name: string;
  image: string;
  isActive: boolean;
  dateCreate: string;
}
