import * as React from 'react';
import { SVGProps } from 'react';

export const HomeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.5">
      <path
        d="M19.9996 10.2107C19.9996 9.94551 19.8942 9.69115 19.7067 9.50362C19.5192 9.31608 19.2648 9.21072 18.9996 9.21072C18.7344 9.21072 18.48 9.31608 18.2925 9.50362C18.1049 9.69115 17.9996 9.94551 17.9996 10.2107H19.9996ZM5.99959 10.2107C5.99959 9.94551 5.89424 9.69115 5.7067 9.50362C5.51916 9.31608 5.26481 9.21072 4.99959 9.21072C4.73438 9.21072 4.48002 9.31608 4.29249 9.50362C4.10495 9.69115 3.99959 9.94551 3.99959 10.2107H5.99959ZM20.2926 12.9177C20.4812 13.0999 20.7338 13.2007 20.996 13.1984C21.2582 13.1961 21.509 13.091 21.6944 12.9055C21.8798 12.7201 21.985 12.4693 21.9873 12.2071C21.9895 11.9449 21.8888 11.6923 21.7066 11.5037L20.2926 12.9177ZM11.9996 3.21072L12.7066 2.50372C12.5191 2.31625 12.2648 2.21094 11.9996 2.21094C11.7344 2.21094 11.4801 2.31625 11.2926 2.50372L11.9996 3.21072ZM2.29259 11.5037C2.19708 11.596 2.1209 11.7063 2.06849 11.8283C2.01608 11.9503 1.9885 12.0815 1.98734 12.2143C1.98619 12.3471 2.01149 12.4788 2.06177 12.6017C2.11205 12.7246 2.18631 12.8362 2.2802 12.9301C2.37409 13.024 2.48574 13.0983 2.60864 13.1485C2.73154 13.1988 2.86321 13.2241 2.99599 13.223C3.12877 13.2218 3.25999 13.1942 3.382 13.1418C3.504 13.0894 3.61435 13.0132 3.70659 12.9177L2.29259 11.5037ZM6.99959 22.2107H16.9996V20.2107H6.99959V22.2107ZM19.9996 19.2107V10.2107H17.9996V19.2107H19.9996ZM5.99959 19.2107V10.2107H3.99959V19.2107H5.99959ZM21.7066 11.5037L12.7066 2.50372L11.2926 3.91772L20.2926 12.9177L21.7066 11.5037ZM11.2926 2.50372L2.29259 11.5037L3.70659 12.9177L12.7066 3.91772L11.2926 2.50372ZM16.9996 22.2107C17.7952 22.2107 18.5583 21.8947 19.1209 21.332C19.6835 20.7694 19.9996 20.0064 19.9996 19.2107H17.9996C17.9996 19.4759 17.8942 19.7303 17.7067 19.9178C17.5192 20.1054 17.2648 20.2107 16.9996 20.2107V22.2107ZM6.99959 20.2107C6.73438 20.2107 6.48002 20.1054 6.29249 19.9178C6.10495 19.7303 5.99959 19.4759 5.99959 19.2107H3.99959C3.99959 20.0064 4.31566 20.7694 4.87827 21.332C5.44088 21.8947 6.20394 22.2107 6.99959 22.2107V20.2107Z"
        fill="#F2F4F7"
      />
    </g>
  </svg>
);
