import { Avatar } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppButton, ButtonsType, CrudActions, CrudTable, Title, TitleType } from 'components';
import { getUser, updateTeamStockKitsLimit } from 'store/slices/authSlice';
import { API_ROUTES, APP_ROUTES } from 'const';
import { IResellerStockKit, ITableColumns } from 'types';
import { useAppDispatch, useAppSelector } from 'hooks';
import Layout from '../../components/Layout';
import CreateStockPopup from './CreateStockPopup';
import styles from './styles.module.scss';

export const ResellerStockKits = () => {
  const { t } = useTranslation();
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const user = useAppSelector(getUser);
  const dispatch = useAppDispatch();
  const { team } = user;

  const rows: ITableColumns<IResellerStockKit>[] = useMemo(() => {
    return [
      {
        title: '',
        dataIndex: 'image',
        key: 'image',
        width: 30,
        render: (item, value) => {
          return <Avatar src={value} sx={{ width: 24, height: 24 }} alt={item.name} />;
        },
      },
      {
        title: 'Company Name',
        dataIndex: 'name',
        key: 'name',
        sortable: true,
      },
      {
        title: 'Symbol',
        dataIndex: 'symbol',
        key: 'symbol',
        sortable: true,
      },
      {
        title: '',
        dataIndex: '',
        key: '_id',
        render: (item, value, refresh = () => {}) => {
          return (
            <CrudActions
              callbackDelete={() => {
                dispatch(updateTeamStockKitsLimit('decrease'));
              }}
              onRemoveUrl={`${API_ROUTES.RESELLER_STOCK_KITS}/${value}`}
              dialogTitle={
                <div>
                  <Title type={TitleType.h3}>
                    <>Confirm Stock deletion</>
                  </Title>
                </div>
              }
              refresh={refresh}
            />
          );
        },
      },
    ];
  }, [dispatch]);

  const toggleCreatePopup = (val: boolean) => {
    return () => setOpenCreatePopup(val);
  };

  return (
    <Layout
      title={t('pages.reseller.stockKits.title')}
      createLink={
        <AppButton
          onClick={toggleCreatePopup(true)}
          btnType={ButtonsType.borderedFilled}
          label={t('pages.reseller.stockKits.create')}
        />
      }
    >
      {team && team.stockKitsLimit && (
        <div className={styles.licenseWrapper}>
          Package used slots {team.totalStockKits}/{team.stockKitsLimit}
        </div>
      )}
      <CrudTable<IResellerStockKit> columns={rows} requestUrl={API_ROUTES.RESELLER_STOCK_KITS} />
      {openCreatePopup && <CreateStockPopup onClose={toggleCreatePopup(false)} />}
    </Layout>
  );
};
