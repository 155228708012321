import classNames from 'classnames';
import React from 'react';

import { DatePickerFilterConfig } from '../../../types';
import DateFilterItem from './DateFilterItem';
import styles from './styles.module.scss';

type DateFilterProps = {
  filterConfig: DatePickerFilterConfig[];
  minDate?: Date;
  initialRange?: Date[];
  className?: string;
  forceLoading?: boolean;
};

export const DateFilter: React.FC<DateFilterProps> = ({
  filterConfig,
  minDate,
  initialRange,
  className,
  forceLoading,
}) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      {filterConfig.map(item => {
        return (
          <DateFilterItem
            key={item.type}
            filterConfig={item}
            minDate={minDate}
            initialRange={initialRange}
            forceLoading={forceLoading}
          />
        );
      })}
    </div>
  );
};
