import React from 'react';

import BotsActivityForm from './Bots';
import ComparisonForm from './Comparison';
import ManualForm from './Manual';
import PeriodicForm from './Periodic';

interface IInfoFormProps {
  control: any;
  register: any;
  errors: any;
  setValue: any;
  watch: any;
  setError: any;
  configId?: string | null;
  clearErrors?: any;
}

const InfoForm: React.FC<IInfoFormProps> = ({
  control,
  register,
  errors,
  setValue,
  watch,
  setError,
  configId,
  clearErrors,
}) => {
  const type = watch('type');

  if (type === 'comparison') {
    return (
      <ComparisonForm
        control={control}
        register={register}
        errors={errors}
        watch={watch}
        setValue={setValue}
        setError={setError}
      />
    );
  }
  if (type === 'manual') {
    return (
      <ManualForm
        control={control}
        register={register}
        errors={errors}
        watch={watch}
        setValue={setValue}
        setError={setError}
        clearErrors={clearErrors}
      />
    );
  }
  if (type === 'bot-activity') {
    return (
      <BotsActivityForm
        control={control}
        register={register}
        errors={errors}
        watch={watch}
        setValue={setValue}
        setError={setError}
      />
    );
  }
  return (
    <PeriodicForm
      configId={configId}
      control={control}
      register={register}
      errors={errors}
      setValue={setValue}
      watch={watch}
      setError={setError}
    />
  );
};

export default InfoForm;
