import { ApexOptions } from 'apexcharts';
import React from 'react';
import Chart from 'react-apexcharts';

type HalfDonutChartProps = {
  series: number[];
  options?: ApexOptions;
};

const windowWidth = window.innerWidth;

const HalfDonutChart: React.FC<HalfDonutChartProps> = ({ series, options = {} }) => {
  const data = {
    series,
    options: {
      theme: {
        mode: 'dark',
      },

      tooltip: {
        show: false,
      },

      grid: {
        padding: {
          bottom: -80,
        },
      },

      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      dataLabels: {
        enabled: false,
        enabledOnSeries: false,
      },
      aspectRatio: 1,
      ...options,
    },
  };

  return (
    <Chart
      /* @ts-ignore */
      options={data.options}
      series={data.series}
      type={'donut'}
      width={windowWidth > 557 ? 490 : 400}
    />
  );
};

export default HalfDonutChart;
