import ProtectedAvailablePage from 'components/ProtectedAvailablePage';
import ProtectedRout from 'components/ProtectedRout';
import { APP_ROUTES, IS_DEV } from 'const';
import useWhiteLabelConfig from 'hooks/useWhiteLabelConfig';
import { LoginPage } from 'pages/AuthPages';
import CeoMatrix from 'pages/CeoMatrix';
import HomePage from 'pages/HomePage';
import NotFoundPage from 'pages/NotFoundPage';
import NewsRoom from 'pages/NewsRoom';
import { Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  BlackListUserCreate,
  BlackListUserEdit,
  BlackListUsersList,
  CreateAnalystAlert,
  CreateEditStock,
  CreateEditTeam,
  CreateStockKit,
  DetailsTeam,
  EditStockKit,
  StockKitsList,
  StocksList,
  TeamsList,
  UserCreateEdit,
  UserDetails,
  UsersList,
} from './pages/Admin';
import AgencyOverviewPage from './pages/AgencyOverview';
import ForgotPassword from './pages/AuthPages/ForgotPassword';
import ResetPassword from './pages/AuthPages/ResetPassword';
import CompetitiveAnalysis from './pages/CompetitiveAnalysis';
import IconsStoryBook from './pages/IconsStoryBook';
import OutOfStock from './pages/OutOfStock';
import { PlansPage, ProfilePage } from './pages/ProfilePage';
import RecommendedActions from './pages/RecommendedActions';
import Reports from './pages/Reports';
import Responder from './pages/Responder';
import './styles/base.scss';
import './styles/reset.css';
import { ITeamRole } from './types';
import { ResellerStockKits } from 'pages/ResellerSettings';

// declaring ht to avoid typescript error
declare global {
  interface Window {
    hj?: any;
  }
}

function App() {
  const { metadata } = useWhiteLabelConfig();
  useEffect(() => {
    const { favicon, title, description } = metadata;
    document.title = title;
    const metaDesc = document.querySelector("meta[name='description']")!;
    metaDesc.textContent = description;

    const metaFavicon: HTMLLinkElement = document.querySelector("link[rel~='icon']")!;
    metaFavicon.href = favicon;
    if (window.hj) {
      console.log('Hotjar is loaded');
    } else {
      console.log('Hotjar is not loaded');
    }
  }, [metadata]);

  return (
    <Suspense fallback={'Loading'}>
      <Routes>
        <Route element={<ProtectedRout />}>
          <Route path={APP_ROUTES.HOME} element={<HomePage />} />
          <Route path={APP_ROUTES.AGENCY_OVERVIEW} element={<AgencyOverviewPage />} />
          <Route path={APP_ROUTES.CEO_MATRIX} element={<CeoMatrix />} />
          <Route path={APP_ROUTES.NEWS_ROOM} element={<NewsRoom />} />
          <Route
            path={APP_ROUTES.COMPETITIVE_ANALYSIS}
            element={<ProtectedAvailablePage page="competitive" Component={CompetitiveAnalysis} />}
          />
          <Route
            path={APP_ROUTES.RESPONDER}
            element={<ProtectedAvailablePage page="responder" Component={Responder} />}
          />
          <Route
            path={APP_ROUTES.REPORTS}
            element={<ProtectedAvailablePage page="reports" Component={Reports} />}
          />
          <Route path={APP_ROUTES.RECOMMENDED_ACTIONS} element={<RecommendedActions />} />
          <Route path={APP_ROUTES.OUT_OF_STOCK} element={<OutOfStock />} />
          <Route path={APP_ROUTES.PROFILE}>
            <Route path={APP_ROUTES.PROFILE} element={<ProfilePage />} />
            <Route path={APP_ROUTES.PLANS} element={<PlansPage />} />
          </Route>
        </Route>
        <Route element={<ProtectedRout accessRole={ITeamRole.reseller} />}>
          <Route path={APP_ROUTES.RESELLER_STOCKS}>
            <Route path={APP_ROUTES.RESELLER_STOCKS} element={<ResellerStockKits />} />
          </Route>
        </Route>
        <Route element={<ProtectedRout accessRole={ITeamRole.admin} />}>
          <Route path={APP_ROUTES.TEAMS}>
            <Route path={APP_ROUTES.TEAMS} element={<TeamsList />} />
            <Route path={`${APP_ROUTES.TEAMS_EDIT}/:teamId`} element={<CreateEditTeam />} />
            <Route path={APP_ROUTES.TEAMS_CREATE} element={<CreateEditTeam />} />
            <Route path={`${APP_ROUTES.TEAMS_DETAILS}/:teamId`} element={<DetailsTeam />} />
          </Route>
          <Route path={`${APP_ROUTES.STOCKS}`}>
            <Route path={APP_ROUTES.STOCKS} element={<StocksList />} />
            <Route path={`${APP_ROUTES.STOCKS_EDIT}/:stocksSymbol`} element={<CreateEditStock />} />
            <Route path={`${APP_ROUTES.STOCKS_CREATE}`} element={<CreateEditStock />} />
          </Route>
          <Route path={APP_ROUTES.USERS}>
            <Route path={APP_ROUTES.USERS} element={<UsersList />} />
            <Route path={`${APP_ROUTES.USERS}/:userId`} element={<UserDetails />} />
            <Route path={`${APP_ROUTES.USERS_EDIT}/:userId`} element={<UserCreateEdit />} />
            <Route path={'create'} element={<UserCreateEdit />} />
          </Route>
          <Route path={APP_ROUTES.BLACKLIST_USERS}>
            <Route path={APP_ROUTES.BLACKLIST_USERS} element={<BlackListUsersList />} />
            <Route path={APP_ROUTES.BLACKLIST_USERS_CREATE} element={<BlackListUserCreate />} />
            <Route
              path={`${APP_ROUTES.BLACKLIST_USERS_EDIT}/:userId`}
              element={<BlackListUserEdit />}
            />
          </Route>
          <Route path={APP_ROUTES.STOCK_KITS}>
            <Route path={APP_ROUTES.STOCK_KITS} element={<StockKitsList />} />
            <Route path={APP_ROUTES.STOCK_KITS_CREATE} element={<CreateStockKit />} />
            <Route path={`${APP_ROUTES.STOCK_KITS_EDIT}/:stockKitId`} element={<EditStockKit />} />
          </Route>
          <Route path={APP_ROUTES.ANALYST_ALERT}>
            <Route path={APP_ROUTES.ANALYST_ALERT_CREATE} element={<CreateAnalystAlert />} />
          </Route>
        </Route>
        {IS_DEV && <Route path={APP_ROUTES.SVG_STORY_BOOK} element={<IconsStoryBook />} />}
        <Route path={APP_ROUTES.LOGIN} element={<LoginPage />} />
        <Route path={APP_ROUTES.FORGOT_PASS} element={<ForgotPassword />} />
        <Route path={APP_ROUTES.RESET_PASS} element={<ResetPassword />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <ToastContainer />
    </Suspense>
  );
}

export default App;
