import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CustomSelect, Title, TitleType } from '../../../components';
import AppButton, { ButtonsType } from '../../../components/AppButton';
import { API_ROUTES } from '../../../const';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  clearUnreadAlerts,
  getActiveAlertType,
  getUnreadAlertCount,
  setAlertsFilter,
  setHiddenAlertTypes,
} from '../../../store/slices/alertsSlice';
import { getUserStockSymbol } from '../../../store/slices/authSlice';
import {
  getAlertsFiltersTabsState,
  setAlertsStorageCounters,
  updateRequestedListPage,
} from '../../../store/slices/filtersSlice';
import { AlertStorageTypes } from '../../../types';
import { fetchWithConfig } from '../../../utils';
import useAlertsCounts from '../hooks/useAlertsCounts';
import { AlertTypes } from '../types';
import styles from './styles.module.scss';

const Heading: React.FC<{ title: string; subtitle?: string; nativeClassname?: string }> = ({
  title,
  subtitle,
  nativeClassname = '',
}) => {
  const dispatch = useAppDispatch();
  const newAlertCount = useAppSelector(getUnreadAlertCount);
  const activeAlertType = useAppSelector(getActiveAlertType);
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const activeStock = useAppSelector(getUserStockSymbol);
  const { value: activeStorageType } = useAppSelector(getAlertsFiltersTabsState);

  const { data } = useAlertsCounts();
  const { t } = useTranslation();

  const { register, control, reset } = useForm({ mode: 'all' });

  useEffect(() => {
    reset();
  }, [reset, stockSymbol]);

  const onFilterChange = (value: string | string[]) => {
    dispatch(setAlertsFilter(value));
    dispatch(updateRequestedListPage({ count: 1, storeKeyName: 'alertsFilters' }));
  };

  const deleteAllAlerts = useCallback(async () => {
    try {
      await fetchWithConfig<any>({
        url: `${API_ROUTES.ALERTS}/${activeStock}/delete-all`,
        method: 'POST',
      });

      dispatch(clearUnreadAlerts());
      dispatch(
        setAlertsStorageCounters({
          key: AlertStorageTypes.ARCHIVED,
          value: 0,
        })
      );
    } catch (e) {
      console.error(e);
    }
  }, [activeStock, dispatch]);

  const archiveAllAlerts = useCallback(async () => {
    try {
      await fetchWithConfig<any>({
        url: `${API_ROUTES.ALERTS_ARCHIVE_ALL.replace(':stockSymbol', activeStock)}`,
        method: 'POST',
      });

      const currentArchived = data?.archived || 0;

      dispatch(clearUnreadAlerts());
      dispatch(setHiddenAlertTypes(AlertStorageTypes.INBOX));
      dispatch(
        setAlertsStorageCounters({
          [AlertStorageTypes.INBOX]: 0,
          [AlertStorageTypes.ARCHIVED]: currentArchived + (data?.inbox || 0),
        })
      );
    } catch (e) {
      console.error(e);
    }
  }, [activeStock, data?.archived, data?.inbox, dispatch]);

  const unarchiveAllAlerts = useCallback(async () => {
    try {
      await fetchWithConfig<any>({
        url: `${API_ROUTES.ALERTS_UNARCHIVE_ALL.replace(':stockSymbol', activeStock)}`,
        method: 'POST',
      });

      dispatch(clearUnreadAlerts());
      dispatch(setHiddenAlertTypes(AlertStorageTypes.ARCHIVED));
      const currentInbox = data?.inbox || 0;

      dispatch(
        setAlertsStorageCounters({
          [AlertStorageTypes.INBOX]: currentInbox + (data?.archived || 0),
          [AlertStorageTypes.ARCHIVED]: 0,
        })
      );
    } catch (e) {
      console.error(e);
    }
  }, [activeStock, data?.archived, dispatch]);

  const labels = useMemo(
    () => [
      {
        type: 'unread',
        count: newAlertCount,
      },
    ],
    [newAlertCount]
  );

  const alertTypesOptions = useMemo(() => {
    return [
      { name: t('alerts.all'), symbol: 'all' },
      ...Object.values(AlertTypes)
        .filter(val => typeof val === 'number')
        .map(type => {
          return {
            name: t(`alerts.types.${type}`),
            symbol: type.toString(),
          };
        }),
    ];
  }, [t]);

  return (
    <div className={classNames(styles.wrapper, !!nativeClassname && nativeClassname)}>
      <Title type={TitleType.h3}>
        <>{title}</>
      </Title>
      {labels.map(item => {
        return item.count ? (
          <div
            className={classNames(styles.label, { [styles.new]: item.type === 'new' })}
            key={item.type}
          >
            {item.count} {t(`alerts.labels.${item.type}`)}
          </div>
        ) : (
          <div key={item.type}></div>
        );
      })}
      <div className={styles.actions}>
        {data && activeStorageType === AlertStorageTypes.INBOX && !!data[activeStorageType] && (
          <AppButton
            onClick={archiveAllAlerts}
            btnType={ButtonsType.outlined}
            label={t('alerts.actions.archiveAll')}
          />
        )}
        {data && activeStorageType === AlertStorageTypes.ARCHIVED && !!data[activeStorageType] && (
          <>
            <AppButton
              onClick={unarchiveAllAlerts}
              btnType={ButtonsType.outlined}
              label={t('alerts.actions.unarchiveAll')}
            />
            <AppButton
              onClick={deleteAllAlerts}
              btnType={ButtonsType.outlined}
              label={t('alerts.actions.deleteAll')}
            />
          </>
        )}
        <div className={styles.typesSelect}>
          <CustomSelect
            defaultValue={activeAlertType}
            loading={false}
            data={alertTypesOptions}
            name="type"
            control={control}
            register={register}
            onFieldChange={onFilterChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Heading;
