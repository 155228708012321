import React from 'react';

export const PriceMarketIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.09 9.37C18.0353 9.72243 18.8765 10.3075 19.5358 11.0712C20.195 11.8349 20.6511 12.7524 20.8617 13.7391C21.0724 14.7257 21.0309 15.7495 20.741 16.7158C20.4512 17.6822 19.9223 18.5598 19.2034 19.2676C18.4845 19.9754 17.5987 20.4905 16.628 20.7652C15.6572 21.04 14.6329 21.0655 13.6497 20.8395C12.6665 20.6134 11.7561 20.1431 11.0028 19.472C10.2495 18.8009 9.67763 17.9507 9.34 17M6 5H7V9M15.71 12.88L16.41 13.59L13.59 16.41M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z"
        stroke="#1570EF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
