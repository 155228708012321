import classNames from 'classnames';
import { AppButton, ButtonsType, Popup } from 'components';
import { API_ROUTES } from 'const';
import { ITopPostResponse } from 'modules/Conversation/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Sentiment } from 'types';
import { fetchWithConfig } from 'utils';

import styles from './styles.module.scss';

interface IChangeSentimentPopupProps {
  onClose: () => void;
  post: ITopPostResponse;
  onChangedSentimentOrDeleted: () => void;
}

const ChangeSentimentPopup = (props: IChangeSentimentPopupProps) => {
  const { onClose, post, onChangedSentimentOrDeleted } = props;
  const { ai: sentiment, _id } = post;
  const { t } = useTranslation();
  const [activeSentiment, setActiveSentiment] = useState<Sentiment>(sentiment);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    setLoading(true);

    await fetchWithConfig<any>({
      url: `${API_ROUTES.CHANGE_SENTIMENT.replace(':id', _id)}`,
      method: 'POST',
      data: {
        sentiment: activeSentiment,
      },
    });

    setLoading(false);
    onClose();
    onChangedSentimentOrDeleted();
  };

  const renderSentiments = () => {
    return Object.values(Sentiment)
      .filter((key): key is keyof typeof Sentiment => typeof key === 'string')
      .map(sentimentKey => {
        return (
          <button
            onClick={() => setActiveSentiment(Sentiment[sentimentKey] as Sentiment)}
            className={classNames(styles.sentimentButton, {
              [styles.active]: Sentiment[sentimentKey] === activeSentiment,
            })}
            key={sentimentKey}
          >
            {t(`posts.${sentimentKey}`)}
          </button>
        );
      });
  };

  return (
    <Popup title={t('posts.changeSentiment')} onClose={onClose} size="small">
      <div className={styles.sentimentsList}>{renderSentiments()}</div>
      <div className={styles.popupFooter}>
        <AppButton
          onClick={onSubmit}
          isLoading={loading}
          disabled={activeSentiment === sentiment}
          btnType={ButtonsType.simpleSave}
          label="Submit"
        />
      </div>
    </Popup>
  );
};

export default ChangeSentimentPopup;
