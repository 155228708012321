import { Chip, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { ITeam } from 'types';

import AppButton, { ButtonsType } from '../../../../components/AppButton';
import { API_ROUTES, APP_ROUTES } from '../../../../const';
import ToastifyService from '../../../../services/ToastifyService';
import { fetchWithConfig } from '../../../../utils';
import ContentBlock from '../../ContentBlock';
import DashboardLayout from '../../DashboardLayout';
import styles from '../styles.module.scss';

export const DetailsTeam = () => {
  const { t } = useTranslation();
  const { teamId } = useParams();
  const navigate = useNavigate();
  const { data, error, isLoading } = useSWR<ITeam>(
    [`${API_ROUTES.TEAMS}/${teamId}`],
    (options: [string, Record<string, any>]): Promise<ITeam> => {
      return fetchWithConfig({
        url: options[0],
        params: options[1],
      });
    }
  );
  const [loading, setLoading] = useState(false);

  const onRemove = async () => {
    try {
      setLoading(true);
      const response = await fetchWithConfig<{ message: string }>({
        url: `${API_ROUTES.TEAMS}/${teamId}`,
        method: 'DELETE',
      });
      ToastifyService.setToast('Team was removed', 'info');
      setLoading(false);
      navigate(APP_ROUTES.TEAMS, { replace: true });
    } catch (error: any) {
      ToastifyService.setToast(error, 'error');
      setLoading(false);
    }
  };
  return (
    <DashboardLayout
      title={data?.name || ''}
      createLink={
        <div className={styles.remove}>
          <AppButton
            onClick={onRemove}
            isLoading={loading}
            btnType={ButtonsType.simple}
            label={'remove'}
            disabled={loading}
          />
        </div>
      }
    >
      <>
        {!!data?.dateCreated && (
          <ContentBlock>
            <>
              <div className={styles.row}>
                <div className={styles.labelWrapper}>
                  <p className={styles.label}>{t('pages.admin.dateCreate')}</p>
                </div>
                <div className={styles.labelRight}>
                  {DateTime.fromJSDate(new Date(data?.dateCreated)).toFormat('LLL dd, yyyy')}
                </div>
              </div>
            </>
          </ContentBlock>
        )}
        {!!data?.dateUpdated && (
          <ContentBlock>
            <>
              <div className={styles.row}>
                <div className={styles.labelWrapper}>
                  <p className={styles.label}>{t('pages.admin.dateUpdate')}</p>
                </div>
                <div className={styles.labelRight}>
                  {DateTime.fromJSDate(new Date(data?.dateUpdated)).toFormat('LLL dd, yyyy')}
                </div>
              </div>
            </>
          </ContentBlock>
        )}
        {!!data?.usersCount && (
          <ContentBlock>
            <>
              <div className={styles.row}>
                <div className={styles.labelWrapper}>
                  <p className={styles.label}>{t('pages.admin.usersInTeam')}</p>
                </div>
                <div className={styles.labelRight}>{data?.usersCount}</div>
              </div>
            </>
          </ContentBlock>
        )}
        {!!data?.stock && (
          <ContentBlock>
            <>
              <div className={styles.row}>
                <div className={styles.labelWrapper}>
                  <p className={styles.label}>{t('pages.admin.availableStock')}</p>
                </div>
                <div className={styles.labelRight}>
                  <Stack direction="row" spacing={1}>
                    <Chip label={data.stock} size={'small'} />
                  </Stack>
                </div>
              </div>
            </>
          </ContentBlock>
        )}
      </>
    </DashboardLayout>
  );
};
