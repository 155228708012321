import * as React from 'react';
import { SVGProps } from 'react';

export const TrendIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5912 0.602445L7.89305 7.0078C7.70693 7.33017 7.61387 7.49136 7.48004 7.57826C7.36233 7.65471 7.22343 7.69193 7.08326 7.68458C6.92391 7.67623 6.76272 7.58317 6.44035 7.39705L4.21212 6.11058C3.88975 5.92446 3.72856 5.8314 3.56921 5.82305C3.42904 5.8157 3.29014 5.85292 3.17243 5.92936C3.0386 6.01627 2.94554 6.17746 2.75942 6.49983L0.471238 10.4631M11.5912 0.602445L7.09711 1.80663M11.5912 0.602445L12.7954 5.09652"
      stroke="#F2F4F7"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
