import classNames from 'classnames';
import React from 'react';

import { Spinner } from '../Spinner';
import styles from './styles.module.scss';

type BlurredSpinnerProps = { size?: number; nativeClassNames?: string; fillContent?: boolean };

export const BlurredSpinner: React.FC<BlurredSpinnerProps> = ({
  size = 72,
  nativeClassNames,
  fillContent,
}) => {
  return (
    <div
      className={classNames(
        styles.loader,
        { [styles.fillContent]: fillContent },
        nativeClassNames && nativeClassNames
      )}
    >
      <Spinner size={size} />
    </div>
  );
};

export default BlurredSpinner;
