import { IconButton, Typography } from '@mui/material';
import classNames from 'classnames';
import { BlurredSpinner, Title, TitleType } from 'components';
import { useAppSelector } from 'hooks';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getSelectedTimeZone } from 'store/slices/authSlice';
import { getDateSourceIcon } from 'utils';

import useAlertActions from '../../../hooks/useAlertActions';
import { AlertTypes, IAlertsListItemResponse } from '../../../types';
import styles from './styles.module.scss';

type AlertItemProps = {
  data: IAlertsListItemResponse;
  loading: boolean;
  index?: number;
};

const AlertItem: React.FC<AlertItemProps> = ({ data, loading, index }) => {
  const { _id, title, createdAt, dataSource, type } = data;
  const { activeId, onAlertClick, alertActions, isHide, isNew, IconByType } = useAlertActions(
    data,
    loading
  );
  const selectedTimeZone = useAppSelector(getSelectedTimeZone);
  const DataSourceIcon = getDateSourceIcon(dataSource);
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const filteredType = queryParams.get('activeAlertType');

  const isInfluencer = type === AlertTypes.INFLUENCER;

  useEffect(() => {
    if (index === 0 && !!filteredType) {
      onAlertClick();
    }
  }, [filteredType, index]);

  const getTitleText = () => {
    if (!isInfluencer) {
      return title;
    }

    return (
      <>
        {title} on{' '}
        <span className={styles.sourceTitleIcon}>
          <DataSourceIcon />
        </span>{' '}
        {t(`dataSource.${dataSource}`)}
      </>
    );
  };

  return (
    <div
      role="button"
      tabIndex={-1}
      onClick={onAlertClick}
      className={classNames(styles.wrapper, {
        [styles.loading]: loading,
        [styles.isNew]: isNew,
        [styles.active]: _id === activeId,
        [styles.hide]: isHide,
      })}
    >
      <div className={styles.icon}>{<IconByType />}</div>
      <div className={styles.content}>
        <Title type={TitleType.h4} nativeStyles={styles.titleText}>
          {getTitleText()}
        </Title>
        <Typography variant={'body2'}>
          <>
            {DateTime.fromJSDate(new Date(createdAt))
              .setZone(selectedTimeZone)
              .toFormat('LLL dd, yyyy, hh:mm:ss a')}
          </>
        </Typography>
      </div>
      {!!alertActions.length && (
        <div>
          {alertActions.map((alertAction, index) => {
            const { action, Icon, title } = alertAction;

            return (
              <IconButton key={index} onClick={action} title={title}>
                <Icon />
              </IconButton>
            );
          })}
        </div>
      )}
      {loading && <BlurredSpinner size={32} fillContent={true} />}
    </div>
  );
};

export default AlertItem;
