import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Title, TitleType } from '../../../components';
import { theme } from '../../../theme';
import styles from './styles.module.scss';

const PageHeader: React.FC<{ title: string; withBack?: boolean }> = ({
  title,
  withBack = true,
}) => {
  const navigation = useNavigate();
  return (
    <div className={styles.wrapper}>
      {withBack && (
        <button
          type={'button'}
          onClick={() => {
            navigation(-1);
          }}
        >
          <ArrowBackIcon style={{ color: theme.palette.grey[100] }} />
        </button>
      )}
      <Title type={TitleType.h2} nativeStyles={styles.title}>
        <>{title}</>
      </Title>
    </div>
  );
};

export default PageHeader;
