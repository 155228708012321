import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ToastifyService from 'services/ToastifyService';
import { theme } from 'theme';
import { fetchWithConfig } from 'utils';

import AppButton, { ButtonsType } from '../AppButton';
import styles from './styles.module.scss';
import { Popup } from 'components/Popup';

export const CrudActions: React.FC<{
  toEdit?: string;
  editReplace?: boolean;
  toDetails?: string;
  onRemoveUrl?: string;
  dialogTitle?: any;
  refresh?: () => void;
  callbackDelete?: () => void;
}> = ({
  toEdit,
  toDetails,
  onRemoveUrl,
  dialogTitle,
  refresh = () => {},
  editReplace,
  callbackDelete,
}) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const themeColor = theme.palette;

  const onRemoveClick = async () => {
    if (!onRemoveUrl) {
      return;
    }
    setLoading(true);
    try {
      const response = await fetchWithConfig({
        url: onRemoveUrl,
        method: 'DELETE',
      });
      ToastifyService.setToast('Successfuly removed', 'info');
      setLoading(false);
      setOpen(false);

      if (typeof callbackDelete === 'function') {
        callbackDelete();
      }

      refresh();
    } catch (error: any) {
      ToastifyService.setToast(error, 'error');
      setLoading(false);
      setOpen(false);
    }
  };

  const toggleModal = (value: boolean) => {
    setOpen(value);
  };

  return (
    <div className={styles.wrapper}>
      {toEdit && (
        <Link to={toEdit} replace={editReplace}>
          <EditIcon
            style={{
              color: themeColor.success.light,
            }}
          />
        </Link>
      )}
      {!!toDetails && (
        <Link to={toDetails}>
          <VisibilityIcon
            style={{
              //@ts-ignore
              color: themeColor.primary[500],
            }}
          />
        </Link>
      )}
      {!!onRemoveUrl && (
        <>
          <button
            type="button"
            onClick={() => {
              toggleModal(true);
            }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <DeleteOutlineIcon
                style={{
                  color: themeColor.error.main,
                }}
              />
            )}
          </button>
          {open && (
            <Popup
              title={dialogTitle}
              onClose={() => {
                toggleModal(false);
              }}
            >
              <div className={styles.dialogActions}>
                <AppButton
                  onClick={() => {
                    toggleModal(false);
                  }}
                  btnType={ButtonsType.simple}
                  label={'Cancel'}
                  customStyles={{ padding: '10px 12px' }}
                  disabled={loading}
                />
                <AppButton
                  onClick={onRemoveClick}
                  isLoading={loading}
                  btnType={ButtonsType.simple}
                  label={'Delete'}
                  disabled={loading}
                  customStyles={{
                    color: theme.palette.error.main,
                    padding: '10px 12px',
                  }}
                />
              </div>
            </Popup>
          )}
        </>
      )}
    </div>
  );
};
