import { SwitchField } from 'components';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import AppButton, { ButtonsType } from '../../../../components/AppButton';
import { API_ROUTES, APP_ROUTES } from '../../../../const';
import ToastifyService from '../../../../services/ToastifyService';
import { IConversationUser } from '../../../../types';
import { catchRequestErrors, fetchWithConfig } from '../../../../utils';
import ContentBlock from '../../ContentBlock';
import DashboardLayout from '../../DashboardLayout';

interface FormValues {
  isRobot: boolean;
  isBot: boolean;
}
export const BlackListUserEdit = () => {
  const { register, handleSubmit, setError, control } = useForm<FormValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { userId } = useParams();
  const navigate = useNavigate();

  const { data, error, isLoading, mutate } = useSWR<IConversationUser>(
    [`${API_ROUTES.CONVERSATION_USERS}/${userId}`],
    (options: [string, Record<string, any>]): Promise<IConversationUser> => {
      return fetchWithConfig({
        url: options[0],
        params: options[1],
      });
    }
  );

  const onSubmit = async (data: FormValues) => {
    try {
      setLoading(true);
      const responseData = await fetchWithConfig({
        url: `${API_ROUTES.CONVERSATION_USERS_BLACKLIST}/${userId}`,
        method: 'PATCH',
        data: {
          ...data,
        },
      });

      ToastifyService.setToast('User updated succesfuly.', 'success');
      setLoading(false);
      navigate(APP_ROUTES.BLACKLIST_USERS);

      await mutate();
    } catch (error: any) {
      catchRequestErrors(error, setError, () => {}, setLoading);
    }
  };

  return (
    <DashboardLayout
      title={t('pages.admin.usersBlackList.title')}
      onSubmit={handleSubmit(onSubmit)}
      actionBtn={<AppButton isLoading={loading} label={'Update'} btnType={ButtonsType.signIn} />}
    >
      <>
        {data && (
          <>
            <div>
              {data.userName} {data.displayName && `(${data.displayName})`}
            </div>
            <ContentBlock>
              <SwitchField
                name="isRobot"
                defaultValue={data.isRobot}
                control={control}
                register={register}
                label={t('pages.admin.usersBlackList.isRobot')}
              />
            </ContentBlock>
            <ContentBlock>
              <SwitchField
                name="isBot"
                defaultValue={data.isBot}
                control={control}
                register={register}
                label={t('pages.admin.usersBlackList.isBot')}
              />
            </ContentBlock>
          </>
        )}
      </>
    </DashboardLayout>
  );
};
