import CustomSelect from 'components/CustomSelect';
import { useAppDispatch, useAppSelector } from 'hooks';
import useTimeZonesOptions from 'hooks/useTimeZonesOptions';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getSelectedTimeZone, setSelectedTimeZone } from 'store/slices/authSlice';

import styles from './styles.module.scss';

const ChooseTimeZone = () => {
  const selectedTimeZone = useAppSelector(getSelectedTimeZone);
  const dispatch = useAppDispatch();
  const timeZones = useTimeZonesOptions();
  const { t } = useTranslation();

  const { register, control } = useForm();

  const onChange = (value: string | string[]) => {
    dispatch(setSelectedTimeZone(value as string));
  };

  if (!timeZones.length) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <CustomSelect
        withSearch
        searchPlaceholder={t('labels.searchTimeZone')}
        data={timeZones}
        loading={false}
        name="timeZone"
        isMultiple={false}
        defaultValue={selectedTimeZone}
        control={control}
        register={register}
        onFieldChange={onChange}
      />
    </div>
  );
};
export default ChooseTimeZone;
