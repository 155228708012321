import { FormControlLabel, Switch } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { Controller } from 'react-hook-form';

import styles from './styles.module.scss';

interface SwitchFieldProps {
  control: any;
  name: string;
  register: any;
  label?: string;
  disabled?: boolean;
  defaultValue?: boolean;
  labelStyles?: string;
}

export const SwitchField: React.FC<SwitchFieldProps> = ({
  control,
  name,
  register,
  label,
  disabled = false,
  defaultValue,
  labelStyles,
}) => {
  const checkedProps = typeof defaultValue !== 'undefined' ? { defaultChecked: defaultValue } : {};

  return (
    <div className={styles.inputWrapper}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <FormControlLabel
              control={
                <Switch
                  {...checkedProps}
                  disabled={disabled}
                  disableRipple
                  {...register(name)}
                  {...field}
                  checked={field.value}
                />
              }
              label={
                label && <span className={classNames(styles.label, labelStyles)}>{label}</span>
              }
            />
          );
        }}
      />
    </div>
  );
};
