import { ChooseCompetitiveStocks, CustomSelect } from 'components';
import BluresSpinner from 'components/BluresSpinner';
import useOptionStocks from 'hooks/useOptionStocks';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { required } from 'services/InputFIeldsValidator/rules';
import useSWR from 'swr';
import { IStock, IStockKit } from 'types';

import AppButton, { ButtonsType } from '../../../../components/AppButton';
import InputField, { InputFieldType } from '../../../../components/InputField';
import { API_ROUTES, APP_ROUTES } from '../../../../const';
import ToastifyService from '../../../../services/ToastifyService';
import { catchRequestErrors, fetchWithConfig } from '../../../../utils';
import ContentBlock from '../../ContentBlock';
import DashboardLayout from '../../DashboardLayout';

type FormValues = {
  name: string;
  stock: string;
  competitiveStocks: string[];
};

export const EditStockKit = () => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
    watch,
    control,
    reset,
  } = useForm<FormValues>();

  const { stockKitId } = useParams();
  const { data, error, isLoading, mutate } = useSWR<IStockKit>(
    [`${API_ROUTES.STOCK_KITS}/${stockKitId}`],
    (options: [string, Record<string, any>]): Promise<IStockKit> => {
      return fetchWithConfig({
        url: options[0],
        params: options[1],
      });
    }
  );

  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { stocksOptionLoading, stocksOptionData } = useOptionStocks();
  const selectedMainStock = watch('stock');

  const onChangeCompetitiveStocks = (stocks: string[]) => {
    setValue('competitiveStocks', stocks);
  };

  const onSubmit = async (data: FormValues) => {
    try {
      setLoading(true);
      const responseData = await fetchWithConfig({
        url: `${API_ROUTES.STOCK_KITS}/${stockKitId}`,
        method: 'PATCH',
        data,
      });

      ToastifyService.setToast('Stock Kit updated succesfuly ', 'success');
      reset();
      setLoading(false);
      navigate(APP_ROUTES.STOCK_KITS);
    } catch (error: any) {
      catchRequestErrors(error, setError, () => {}, setLoading);
    }
  };

  return (
    <DashboardLayout
      title={t('pages.admin.stockKitEdit')}
      onSubmit={handleSubmit(onSubmit)}
      actionBtn={
        <AppButton
          disabled={!!Object.keys(errors).length}
          isLoading={loading}
          label={'Update'}
          btnType={ButtonsType.signIn}
        />
      }
    >
      <>
        {isLoading ? (
          <BluresSpinner />
        ) : (
          <>
            <ContentBlock>
              <InputField
                label={'Enter name'}
                control={control}
                defaultValue={data?.name || ''}
                name={'name'}
                type={InputFieldType.text}
                register={register}
                placeholder={'Stock Kit name'}
                validateText={t('errors.requiredField')}
                validate={{
                  required,
                }}
                errors={errors.name}
              />
            </ContentBlock>
            <ContentBlock>
              <CustomSelect<IStock>
                data={stocksOptionData!}
                placeholder={'Choose stock'}
                loading={stocksOptionLoading}
                name={'stock'}
                control={control}
                defaultValue={data?.stock || ''}
                register={register}
                required
                errors={errors.stock}
              />
            </ContentBlock>
            <ContentBlock>
              <ChooseCompetitiveStocks
                defaultStocks={data?.competitiveStocks || []}
                mainStock={selectedMainStock}
                onChange={onChangeCompetitiveStocks}
              />
            </ContentBlock>
          </>
        )}
      </>
    </DashboardLayout>
  );
};
