import { SectionCard } from 'components';
import { API_ROUTES } from 'const';
import { useAppSelector } from 'hooks';
import Heading from 'modules/Charts/Heading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getUserCompetitiveStocks, getUserStockSymbol } from 'store/slices/authSlice';
import useSWR from 'swr';
import { fetchWithConfig } from 'utils';

import TopicsList from './List';

export interface IWeeklyTopic {
  topics: string[];
  symbol: string;
}

const CompetitiveTopics = () => {
  const { t } = useTranslation();
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const stocks = useAppSelector(getUserCompetitiveStocks);

  const { data, error, isLoading } = useSWR<IWeeklyTopic[]>(
    [API_ROUTES.STOCKS_WEEKLY_TOPICS, { stocks: [...stocks, stockSymbol] }],
    (options: [string, Record<string, any>]) => {
      return fetchWithConfig<IWeeklyTopic[]>({
        url: options[0],
        params: options[1],
      });
    }
  );

  return (
    <SectionCard noBorder>
      <>
        <Heading title={t('pages.competitiveAnalysis.topicsTitle')} />
        <TopicsList data={data} loading={isLoading} />
      </>
    </SectionCard>
  );
};

export default CompetitiveTopics;
