import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ITeamRole } from 'types';

const useRoleOptions = () => {
  const { t } = useTranslation();

  const roleOptions = useMemo(() => {
    return [
      { _id: 'all', name: 'All' },
      ...Object.keys(ITeamRole).map(item => ({
        _id: item,
        name: item[0].toUpperCase() + item.slice(1),
      })),
    ];
  }, [t]);

  return roleOptions;
};

export default useRoleOptions;
