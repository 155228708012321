import React from 'react';
import { ISubscriptionPlan } from 'types';

import PlanItem from './PlanItem';
import styles from './styles.module.scss';

const PlansList: React.FC<{
  plans: ISubscriptionPlan[];
}> = ({ plans }) => {
  return (
    <div className={styles.wrapper}>
      {plans.map(plan => (
        <PlanItem key={plan._id} plan={plan} />
      ))}
    </div>
  );
};

export default PlansList;
