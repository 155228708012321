import classNames from 'classnames';
import { useAppSelector } from 'hooks';
import { DateTime } from 'luxon';
import { IPrPoint } from 'modules/Charts/types';
import { CompanyResponse } from 'modules/ExecutiveSummary/types';
import React from 'react';
import { getSelectedTimeZone } from 'store/slices/authSlice';

import styles from './styles.module.scss';

interface IPrPointProps {
  data: IPrPoint;
  activePoint: string;
  onClick: (id: string) => void;
  companyInfo: CompanyResponse;
}

export const PrPoint: React.FC<IPrPointProps> = ({ data, activePoint, onClick, companyInfo }) => {
  const { x, dayDate, title, _id, link, stock, additionalData } = data;
  const selectedTimeZone = useAppSelector(getSelectedTimeZone);

  const onPointClick = (e: any) => {
    onClick(_id);
  };

  return (
    <>
      <div style={{ left: `${x}px` }} className={styles.point} onClick={onPointClick}>
        PR
        {activePoint === _id && (
          <div
            className={classNames(styles.prMessage, { [styles.active]: activePoint === _id })}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <span className={styles.triangle}></span>
            <div className={styles.stockInfo}>
              <div className={styles.logo}>
                <img src={companyInfo.image} alt={stock} />
              </div>
              <div>
                <span className={styles.stockCompany} title={companyInfo.name}>
                  {companyInfo.name}
                </span>
                <div className={styles.date}>
                  {DateTime.fromJSDate(new Date(dayDate))
                    .setZone(selectedTimeZone, { keepLocalTime: true })
                    .toFormat('LLL dd, yyyy')}
                </div>
              </div>
              <div
                className={styles.closeIcon}
                role="button"
                tabIndex={-1}
                onClick={() => onClick('')}
              >
                &#10005;
              </div>
            </div>
            <div className={styles.prTitle} title={title}>
              {title}
            </div>
            <a className={styles.readMore} href={link} target="_blank" rel="noreferrer">
              Read more
            </a>
            {!!additionalData && !!additionalData.length && (
              <>
                {additionalData.map(item => {
                  return (
                    <div className={styles.additionalWrapper} key={item._id}>
                      <div className={styles.prTitle} title={item.title}>
                        {item.title}
                      </div>
                      <a
                        className={styles.readMore}
                        href={item.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Read more
                      </a>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};
