import React from 'react';

import { Title, TitleType } from '../../../components';
import styles from './styles.module.scss';

const Heading: React.FC<{ title: string; infoIcon?: React.ReactNode }> = ({ title, infoIcon }) => {
  return (
    <div className={styles.wrapper}>
      <Title type={TitleType.h3}>
        <>{title}</>
      </Title>
      {infoIcon}
    </div>
  );
};

export default Heading;
