import classNames from 'classnames';
import React from 'react';
import { IReport } from 'types';

import styles from './styles.module.scss';

interface IReportStatusProps {
  report: IReport;
}

const ReportStatus: React.FC<IReportStatusProps> = ({ report }) => {
  const { status, archived } = report;
  const statusValue = archived ? 'archived' : status;
  const statusText = statusValue === 'progress' ? 'In Progress' : statusValue;

  return <span className={classNames(styles.status, styles[statusValue])}>{statusText}</span>;
};

export default ReportStatus;
