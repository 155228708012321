import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React from 'react';
import { BaseRecord, ITableColumns } from 'types';

const CrudHeadingList = <TRow extends BaseRecord = any>(props: {
  columns: ITableColumns<TRow>[];
  direction: 1 | -1;
  dataIndex: string;
  sortFn: any;
}) => {
  const { columns, direction, dataIndex, sortFn } = props;
  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell, index) => {
          const align = headCell?.align ? { align: headCell.align } : {};
          const sortable = headCell.sortable || false;
          const orderBy = direction === 1 ? 'asc' : 'desc';
          return (
            <TableCell
              key={`headCell_${index}`}
              padding={'normal'}
              style={headCell.width ? { width: headCell.width } : {}}
              sortDirection={dataIndex === headCell.dataIndex ? orderBy : false}
              {...align}
            >
              {sortable ? (
                <TableSortLabel
                  active={headCell.dataIndex === dataIndex}
                  direction={direction === 1 ? 'asc' : 'desc'}
                  onClick={() => {
                    sortFn((state: any) => {
                      return {
                        ...state,
                        sortOption: {
                          dataIndex: headCell.dataIndex,
                          direction:
                            headCell.dataIndex === dataIndex ? (direction === 1 ? -1 : 1) : -1,
                        },
                      };
                    });
                  }}
                >
                  {headCell.title}
                </TableSortLabel>
              ) : (
                headCell.title
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default CrudHeadingList;
