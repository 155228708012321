import { styled } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

export enum LinkType {
  simple,
}

const StyledSimpleLink = styled('span')(({ theme }) => ({
  ...theme.typography.button,
  fontSize: 16,
  color: theme.palette.primary.light,
  textTransform: 'none',
}));

const StyledLink: React.FC<{
  to: string;
  label: string;
  linkType: LinkType;
  customStyles?: {};
}> = ({ to, label, linkType = LinkType.simple, customStyles }) => {
  return (
    <Link to={to} className={classNames(styles.btn, customStyles)}>
      {linkType === LinkType.simple && <StyledSimpleLink>{label}</StyledSimpleLink>}
    </Link>
  );
};

export default StyledLink;
