import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const Spinner: React.FC<{
  size?: number;
  nativeWrapperClassName?: string;
  showText?: boolean;
  staticPosition?: boolean;
}> = ({ size = 40, nativeWrapperClassName = '', showText = false, staticPosition = false }) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(styles.wrapper, !!nativeWrapperClassName && nativeWrapperClassName, {
        [styles.static]: staticPosition,
      })}
    >
      <div className={styles.spinner} style={{ width: size, height: size }}>
        {Array.from(Array(12)).map((item, index) => {
          return <div key={index} />;
        })}
      </div>
      {showText && <span className={styles.text}>{t('buttons.loading')}</span>}
    </div>
  );
};
