import { Avatar } from '@mui/material';
import { CrudTable } from 'components';
import { API_ROUTES } from 'const';
import { ExecutiveSummary } from 'modules';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ExecutiveSummaryDaysFilter, IChangeStockAgency, ITableColumns } from 'types';
import { numberWithCommas } from 'utils';

import styles from './styles.module.scss';

interface IStocksTableProps {
  filterDay: ExecutiveSummaryDaysFilter;
}

const StocksTable = (props: IStocksTableProps) => {
  const { filterDay } = props;
  const rows: ITableColumns<IChangeStockAgency>[] = useMemo(() => {
    return [
      {
        title: '',
        dataIndex: 'image',
        key: 'image',
        width: 30,
        render: (item, value) => {
          return <Avatar src={value} sx={{ width: 24, height: 24 }} alt={item.name} />;
        },
      },
      {
        title: 'Company Name',
        dataIndex: 'name',
        key: 'name',
        sortable: true,
        render: (item, value) => {
          return <Link to={`/?activeStock=${item.symbol}`}>{value}</Link>;
        },
      },
      {
        title: 'Total Mentions',
        dataIndex: 'totalMentions',
        key: 'totalMentions',
        sortable: true,
        render: (item, value) => {
          return numberWithCommas(value);
        },
      },
      {
        title: 'Positive Sentiment',
        dataIndex: 'positive',
        key: 'positive',
        sortable: true,
      },
      {
        title: 'Negative Sentiment',
        dataIndex: 'negative',
        key: 'negative',
        sortable: true,
      },
      {
        title: 'Latest PR',
        dataIndex: 'lastPrLink',
        key: 'lastPrLink',
        render: (item, value) => {
          if (!value) return '-';

          return (
            <a href={value} target="_blank" rel="noopener noreferrer">
              PR
            </a>
          );
        },
      },
    ];
  }, [filterDay]);
  return (
    <CrudTable<IChangeStockAgency>
      columns={rows}
      noPagination
      requestUrl={API_ROUTES.AGENCY_OVERVIEW}
      queryOptions={{ filterDayType: filterDay }}
      rowKeyId="symbol"
      ExpandComponent={record => {
        const { totalMentions, positive, negative } = record;
        const positiveValue = parseInt(positive);
        const negativeValue = parseInt(negative);
        const neutral = `${100 - positiveValue - negativeValue}%`;

        const hardData = {
          mentions: totalMentions,
          sentiment: { positive, negative, neutral },
        };

        return (
          <ExecutiveSummary
            hardData={hardData}
            wrapperClassName={styles.summaryWrapper}
            symbol={record.symbol}
            filterDay={filterDay}
          />
        );
      }}
    />
  );
};

export default StocksTable;
