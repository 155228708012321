import { Skeleton } from '@mui/material';
import classNames from 'classnames';

import { GAUGE_SENTIMENT_CONFIG, GaugeSentimentKey } from '../types';
import { getGaugeSentimentKey } from '../utils';
import styles from './styles.module.scss';

interface IGaugeBarProps {
  loading: boolean;
  percentage: number | null;
}

const GaugeBar = (props: IGaugeBarProps) => {
  const { loading, percentage } = props;

  if (loading) {
    return <Skeleton height={40} width="100%" />;
  }

  if (!percentage) {
    return null;
  }

  const level = getGaugeSentimentKey(percentage);

  return (
    <div className={styles.wrapper}>
      <div
        style={{ left: `${percentage}%` }}
        className={classNames(styles.percentageMark, styles[level])}
      >
        <svg
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.25004 4.33366V1.66699H5.58337M1.58337 8.33366H2.91671M13.5834 8.33366H14.9167M10.25 7.66699V9.00033M6.25004 7.66699V9.00033M4.25004 4.33366H12.25C12.9864 4.33366 13.5834 4.93061 13.5834 5.66699V11.0003C13.5834 11.7367 12.9864 12.3337 12.25 12.3337H4.25004C3.51366 12.3337 2.91671 11.7367 2.91671 11.0003V5.66699C2.91671 4.93061 3.51366 4.33366 4.25004 4.33366Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span className={styles.markTooltip}>{percentage}%</span>
      </div>
      <div className={styles.bars}>
        {(Object.keys(GAUGE_SENTIMENT_CONFIG) as GaugeSentimentKey[]).map(key => {
          const data = GAUGE_SENTIMENT_CONFIG[key];
          const { label, min, max } = data;
          const width = min === 0 ? max : max - min + 1;

          return (
            <div
              style={{ maxWidth: `${width}%` }}
              className={classNames(styles.bar, { [styles[level]]: key === level })}
              key={key}
            >
              {label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GaugeBar;
