import { CrudActions, CrudTable, Title, TitleType } from 'components';
import { API_ROUTES } from 'const';
import { DateTime } from 'luxon';
import React from 'react';
import { IResponderAccount, ITableColumns } from 'types';
import { getDateSourceIcon } from 'utils';

const connectionRows: ITableColumns<IResponderAccount>[] = [
  {
    title: 'Platform',
    dataIndex: 'source',
    key: 'source',
    render: (_, value) => {
      const Icon = getDateSourceIcon(value);
      return <Icon />;
    },
  },
  {
    title: 'Account mame',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Date created',
    dataIndex: 'dateCreated',
    key: 'dateCreated',
    sortable: true,
    render: (item, value) => {
      return <span>{DateTime.fromJSDate(new Date(value)).toFormat('LLL dd, yyyy')}</span>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '',
    dataIndex: '_id',
    key: '_id',
    render: (item, value, refresh) => {
      return (
        <>
          <CrudActions
            editReplace={true}
            toEdit={`${window.location.href}?editAccountId=${value}`}
            onRemoveUrl={`${API_ROUTES.RESPONDER_ACCOUNTS}/${value}`}
            dialogTitle={
              <div>
                <Title type={TitleType.h3}>
                  <>Confirm delete Account.</>
                </Title>
              </div>
            }
            refresh={refresh}
          />
        </>
      );
    },
  },
];

const ConnectionsList: React.FC<{ stock: string }> = ({ stock }) => {
  return (
    <CrudTable<IResponderAccount>
      columns={connectionRows}
      requestUrl={`${API_ROUTES.RESPONDER_ACCOUNTS}/list/${stock}`}
      noPagination
    />
  );
};

export default ConnectionsList;
